import React, { useEffect, useMemo, useState } from "react";
import "../Container.css";
import { useAuth } from "../../../Utils/AuthContext";
import { useSelector, useDispatch } from "react-redux";
import { getAllStores } from "../../../Redux/Stores/StoresActions";
import StoresHeader from "./StoresHeader";
import Card from "react-bootstrap/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import API from "../../../Utils/API";
import { useHistory, useRouteMatch } from "react-router-dom";
import RatingForm from "../../DialogBox/RatingForm";
import LoginForm from "../../DialogBox/LoginForm";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Moment from "react-moment";
import "moment-timezone";
import moment from "moment";
import ProductCard from "../product/ProductCard";
import { Tooltip } from "antd";
import ReactPaginate from "react-paginate";
import ShopifyStoreDiscount from "../../DialogBox/ShopifyStoreDiscount";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import TimeCard from "../../UI/TIme/TimeCard";
import { Select } from "@material-ui/core";
import CustomerSelectOption from "../../UI/Input/CustomerSelectOption";
import CustomSelect from "../../UI/Container/CustomSelect";
const leftPaginationArrow = (
  <ArrowBackIosIcon style={{ margin: 0, marginLeft: 8, color: "#1d6042" }} />
);
const rightPaginationArrow = (
  <ArrowForwardIosIcon style={{ margin: 0, color: "#1d6042" }} />
);
;
const style = {
  backButton: {
    fontSize: "25px",
    marginBottom: "10px",
    marginTop: "10px",
    marginLeft: "12px",
    color: "#1d6042",
    cursor: "pointer",
  },
};
const filterColumns = [
  { value: "Relevance", key: "relevance,true" },
  { value: "Price : Low to High", key: "offerPrice,true" },
  { value: "Price : High to Low", key: "offerPrice,false" },
  { value: "Rating", key: "avgRating,false" },
  // { value: "Rating: Descending", key: "avgRating,false" },
];
function StoresDetails() {
  const { userInfo } = useAuth();
  const { allStores, categoryWiseStores } = useSelector(
    (state) => state.stores
  );
  const { location, userId, cityId, cityName, stateId } = useSelector(
    (state) => state.location
  );
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { path, params } = useRouteMatch();
  const [storeOffer, setStoreOffer] = useState([]);
  const [storeCollection, setStoreCollection] = useState([]);
  const [collectionAllProduct, setCollectionAllProduct] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState("home");
  const [storeData, setStoreData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [ratingForm, setRatingForm] = useState(false);
  const [loginForm, setLoginForm] = useState(false);
  const [discountForm, setDiscountForm] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedStoreId, setSelectedStoreId] = useState("");
  const [followers, setFollowers] = useState(0);
  const handleOpenForm = () => setRatingForm(true);
  const handleCloseForm = () => setRatingForm(false);
  const handleLoginOpenForm = () => setLoginForm(true);
  const handleLoginCloseForm = () => setLoginForm(false);
  const handleOpenDiscountForm = () => setDiscountForm(true);
  const handleCloseDiscountForm = () => setDiscountForm(false);
  const [perPage] = useState(32);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [orderByColumnName, setOrderByColumnName] = useState("relevance,true");
  const sortColumnName = orderByColumnName.split(",")[0];
  const sortOrder = orderByColumnName.split(",")[1]=== true;
  const [collectionToggle, setCollectionMoreToggle] = useState(false);
  const [hasOffers, setHasOffers] = useState(true)
  useEffect(() => {
    API.OffersBYStore(params.storeId)
      .then((result) => {
        if (result != undefined && result.length > 0) {
          setSelectedCollection("home");
          setStoreOffer(result);
          setLoading(false);
        } else {
          setStoreOffer([]);
          collectionClick("allProduct");
          setLoading(false);
          setHasOffers(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        console.log(error);
      });
    loadItemsByPageId(0);
  }, [orderByColumnName]);
  useEffect(() => {
    if (
      params.storeId != undefined &&
      params.storeId != null &&
      params.storeId != ""
    ) {
      API.getStoreFollow(
        userInfo != null && userInfo != undefined ? userInfo.email : "gust",
        "follow"
      )
        .then((result1) => {
          if (result1 !== undefined) {
            result1.map((items) => {
              if (items.storeId === params.storeId) {
                setDisabled(!disabled);
              }
            });
            console.log(result1);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      API.getStoreById(params.storeId)
        .then((result) => {
          if (result != undefined) {
            setStoreData(result);
            API.getStoreFollowList(params.storeId)
              .then((res) => {
                if (res !== undefined) {
                  setFollowers(res.length);
                }
              })
              .catch((error) => {
                console.log(error);
              });
            API.getCompanyId(result.companyId)
              .then((result1) => {
                if (result1 != undefined) {
                  setCompanyData(result1);
                }
              })
              .catch((error) => {
                console.log(error);
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error);
        });

      setLoading(true);
      API.OffersBYStore(params.storeId)
        .then((result) => {
          if (result != undefined && result.length > 0) {
            setSelectedCollection("home");
            setStoreOffer(result);
            setLoading(false);
          } else {
            setStoreOffer([]);
            collectionClick("allProduct");
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          console.log(error);
        });

      API.CollectionBYStore(params.storeId)
        .then((result) => {
          if (result != undefined && result != null) {
            setStoreCollection(result);
            if (result.length > 0) {
              /* setSelectedCollection(result[0].id);
                  API.getOfferProducts(result[0].id).then((result1) => {
                     if (result1 != undefined) {
                        setCollectionAllProduct(result1)
                       
                     }
                     else {
                        setCollectionAllProduct([])
                        
                     }
                  })
                     .catch((error) => {
                        setLoading(false);
                        console.log(error);
                        console.log(error);
                     });*/
              // setSelectedCollection("home");
            }
          } else {
            setStoreCollection([]);
            // setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          console.log(error);
        });
      API.productByStore(
        params.storeId,
        currentPage,
        perPage,
        sortColumnName,
        sortOrder
      )
        .then((response) => {
          let {
            content,
            pageable: { pageNumber },
            totalPages: numberOfPages,
          } = response;
          console.log({ firresponsest: response });
          setCollectionAllProduct(content);
          setPageCount(numberOfPages);
          setCurrentPage(pageNumber);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          console.log(error);
        });
    }
    window.scrollTo(0, 0);
  }, [params.storeId]);
  function ListItemLink(props) {
    return;
    <ListItem button component="a" {...props} />;
  }
  const productCardClick = (newValue, productId) => {
    history.push(`/productDetail/` + newValue + "/" + productId);
  };
  const ratingClick = (newValue, productId) => {
    // history.push(`/storeDetails/` + newValue);
    if (!userInfo?.email) setLoginForm(true);
    else {
      setSelectedProduct(productId);
      setSelectedStoreId(newValue);
      setRatingForm(true);
    }
    // console.log("Under Development..............")
  };

  const collectionTabClick = (orderId, storeId) => {
    history.push(`/collectionDetail/` + orderId + "/" + storeId);
  };

  const collectionClick = (offerId) => {
    console.log(offerId);
    //history.push(`/productDetail/` + newValue + "/" + productId);
    setSelectedCollection(offerId);
    setOrderByColumnName("relevance,true");
    if ("allProduct" === offerId) {
      API.productByStore(
        params.storeId,
        currentPage,
        perPage,
        "relevance",
        "true"
      )
        .then((response) => {
          let {
            content,
            pageable: { pageNumber },
            totalPages: numberOfPages,
          } = response;
          console.log({ firresponsest: response });
          setCollectionAllProduct(content);
          setPageCount(numberOfPages);
          setCurrentPage(pageNumber);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          console.log(error);
        });
    } else {
      API.getOfferProducts(offerId, 0, perPage, "relevance", "true")
        .then((result) => {
          let {
            content,
            pageable: { pageNumber },
            totalPages: numberOfPages,
          } = result;
          console.log({
            contentcontentcontent:content,
          });
          setCollectionAllProduct(content);
          setCurrentPage(pageNumber);
          setPageCount(numberOfPages);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          console.log(error);
        });
    }
  };

  const cardClick = (newValue) => {
    history.push(`/offers/tab/` + newValue);
    // console.log("Under Development..............")
  };

  const followStore = (newValue) => {
    if (!userInfo?.email) setLoginForm(true);
    else {
      API.followStore(userInfo?.email, newValue, "follow")
        .then((result) => {
          if (result != undefined) {
            setDisabled(!disabled);
            setCollectionAllProduct(result);
          } else {
            setCollectionAllProduct([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  function handleSortChanage(e) {
    console.log("event>>>>>>>>>", e.target.value);
    if (e.target.value != undefined && e.target.value != "") {
      setLoading(true);
      if ("L" == e.target.value) {
        console.log("sort by>>>>>>>>>>");
        const sortByPrice = [...collectionAllProduct];
        sortByPrice.sort((a, b) => (a.offerPrice > b.offerPrice ? 1 : -1));
        console.log("sort by>>>>>>>>>>", sortByPrice);
        setCollectionAllProduct(sortByPrice);
      }
      if ("H" == e.target.value) {
        console.log("sort by>>>>>>>>>>");
        const sortByPrice = [...collectionAllProduct];
        sortByPrice.sort((a, b) => (a.offerPrice < b.offerPrice ? 1 : -1));
        console.log("sort by>>>>>>>>>>", sortByPrice);
        setCollectionAllProduct(sortByPrice);
      }
      setLoading(false);
    }
  }

  function checkIfImageExists(url) {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      return true;
    } else {
      img.onload = () => {
        return true;
      };

      img.onerror = () => {
        return false;
      };
    }
  }

  const setCategoryToggle = () => {
    setCollectionMoreToggle(!collectionToggle);
  };

  document.addEventListener("click", function (event) {
    var ignoreClickOnMeElement = document.getElementById("moreMenu");
    //console.log("ignoreClickOnMeElement>>>>>>>>>>>>>",ignoreClickOnMeElement);
    //console.log("event>>>>>>>>>>>>>",event);
    if (ignoreClickOnMeElement != null) {
      var isClickInsideElement = ignoreClickOnMeElement.contains(event.target);
      if (!isClickInsideElement) {
        setCollectionMoreToggle(false);
        //Do something click is outside specified element
      }
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  function scrapeNumbersComma(str) {
    console.log("weekly off>>>>>>>>>>>>", str);
    if (str != undefined) {
      // str="Sunday, ,,,,,,,,,,,,,,,,,,,,Monday,"
      str = str.replace(/,+/g, ",");
      str = str.replace(/,\s*$/, "");
      console.log("weekly off11111111111>>>>>>>>>>>>", str);
      return str;
    } else {
      return "";
    }
    //   if(str!=undefined)
    //   {
    //   var arr=str.replace(/,+/g,",").replace(/^,/,"").replace(/,$/,"").split(",");
    //   for(var i=0,len=arr.length,rtn=[];i<len;i++){
    //       if(i in arr&&arrIndex(arr[i],rtn)==-1){
    //           rtn.push(arr[i]);
    //       }
    //   }

    //   console.log("weekly off1111111111>>>>>>>>>>>>",rtn.join(","));
    //   return rtn.join(",");
    // }
    // else
    // {
    //   return "";
    // }
  }

  function arrIndex(fnd, arr) {
    for (var len = arr.length, i = 0; i < len; i++) {
      if (i in arr && arr[i] === fnd) {
        return i;
      }
    }
    return -1;
  }
  console.log(storeData.fromHrs);
  const openDiscountPage = () => {
    setDiscountForm(true);
  };
  const loadItemsByPageId = (page) => {
    if (selectedCollection === "allProduct") {
      API.productByStore(
        params.storeId,
        page,
        perPage,
        sortColumnName,
        sortOrder
      )
        .then((response) => {
          let {
            content,
            pageable: { pageNumber },
            totalPages: numberOfPages,
          } = response;
          setCollectionAllProduct(content);
          setPageCount(numberOfPages);
          setCurrentPage(pageNumber);
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          console.log(error);
        });
    } else {
      API.getOfferProducts(
        selectedCollection,
        page,
        perPage,
        sortColumnName,
        sortOrder
      )
        .then((result) => {
          let {
            content,
            pageable: { pageNumber },
            totalPages: numberOfPages,
          } = result;
          setCollectionAllProduct(content);
          setCurrentPage(pageNumber);
          setPageCount(numberOfPages);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          console.log(error);
        });
    }
  };
  console.log({ orderByColumnName });
  return (
    <>
      <div className="container-fluid">
        <i
          style={{ ...style.backButton, position: "relative" }}
          className="fa fa-arrow-circle-left"
          aria-hidden="true"
          title="Back to Login"
          onClick={() => history.goBack()}
        ></i>
        <div className="row bg-white py-4 border-bottom mr-3 ml-3">
          <div className="col-md-1 mx-auto"></div>
          <div className="col-md-11 mx-auto">
            <div className="row seller-overview">
              <div className="col-md-5">
                <div>
                  <div className="d-flex">
                    <img
                      className="shopee-avatar__img mt-2"
                      src={
                        storeData.logoMedium !== undefined &&
                        storeData.logoMedium !== null
                          ? storeData.logoMedium
                          : "https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg"
                      }
                    />
                    <div className="section-seller-portrait-info">
                      <h1 className="section-seller-portrait-name">
                        {storeData.storeName}{" "}
                        {storeData.code !== "Primary"
                          ? `Branch: ${storeData.code}`
                          : ""}
                      </h1>
                      {/*<div className="section-seller-active-time"><strong>Branch: </strong>{storeData.code}</div>
                       */}
                      <div className="section-seller-portrait-status">
                        <div className="section-seller-active-time">
                          {storeData.address2}, Unit:
                          {storeData.address1}, {storeData.cityId} -{" "}
                          {storeData.pincode}
                        </div>
                        {/*<div className="section-seller-active-time">Active 7 minutes ago</div>
                         */}
                      </div>
                    </div>
                  </div>
                  <div className="section-seller-overview-buttons">
                    <a className="section-seller-button">
                      <button
                        className="shopee-button-outline"
                        onClick={() =>
                          history.push(`/Returns/${params.storeId}`)
                        }
                      >
                        <span className="section-seller-overview-icon">
                          <svg
                            enable-background="new 0 0 15 15"
                            viewBox="0 0 15 15"
                            x="0"
                            y="0"
                            stroke-width="0"
                            class="shopee-svg-icon"
                          >
                            <path d="m13 1.9c-.2-.5-.8-1-1.4-1h-8.4c-.6.1-1.2.5-1.4 1l-1.4 4.3c0 .8.3 1.6.9 2.1v4.8c0 .6.5 1 1.1 1h10.2c.6 0 1.1-.5 1.1-1v-4.6c.6-.4.9-1.2.9-2.3zm-11.4 3.4 1-3c .1-.2.4-.4.6-.4h8.3c.3 0 .5.2.6.4l1 3zm .6 3.5h.4c.7 0 1.4-.3 1.8-.8.4.5.9.8 1.5.8.7 0 1.3-.5 1.5-.8.2.3.8.8 1.5.8.6 0 1.1-.3 1.5-.8.4.5 1.1.8 1.7.8h.4v3.9c0 .1 0 .2-.1.3s-.2.1-.3.1h-9.5c-.1 0-.2 0-.3-.1s-.1-.2-.1-.3zm8.8-1.7h-1v .1s0 .3-.2.6c-.2.1-.5.2-.9.2-.3 0-.6-.1-.8-.3-.2-.3-.2-.6-.2-.6v-.1h-1v .1s0 .3-.2.5c-.2.3-.5.4-.8.4-1 0-1-.8-1-.8h-1c0 .8-.7.8-1.3.8s-1.1-1-1.2-1.7h12.1c0 .2-.1.9-.5 1.4-.2.2-.5.3-.8.3-1.2 0-1.2-.8-1.2-.9z"></path>
                          </svg>
                        </span>
                        About Store
                      </button>
                    </a>
                    <a className="section-seller-button">
                      <button
                        className={
                          disabled
                            ? "shopee-button-outline-follow"
                            : "shopee-button-outline"
                        }
                        disabled={disabled}
                        onClick={() => followStore(storeData.id)}
                      >
                        {disabled ? (
                          ""
                        ) : (
                          <span className="section-seller-overview-icon">
                            <svg
                              enable-background="new 0 0 10 10"
                              viewBox="0 0 10 10"
                              x="0"
                              y="0"
                              className="shopee-svg-icon icon-plus-sign"
                            >
                              <polygon points="10 4.5 5.5 4.5 5.5 0 4.5 0 4.5 4.5 0 4.5 0 5.5 4.5 5.5 4.5 10 5.5 10 5.5 5.5 10 5.5"></polygon>
                            </svg>
                          </span>
                        )}
                        {disabled ? "Following" : "Follow"}
                      </button>
                    </a>
                  </div>
                  <div className="section-seller-overview-buttons">
                    <a className="section-seller-button">
                      <button
                        className="shopee-button-outline"
                        onClick={() => openDiscountPage()}
                      >
                        <span className="section-seller-overview-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-info-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                          </svg>
                        </span>
                        Additional Discounts
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="section-info-list">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-7 pr-0">
                        <div className="section-seller-details">
                          <div className="section-item-icon-wrapper">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              class="bi bi-building shopee-svg-icon"
                              stroke-width="0"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
                              />
                              <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z" />
                            </svg>
                          </div>
                          <div className="section-seller-item-text">
                            <div className="section-seller-item-text-name">
                              Incorporated As:&nbsp;
                            </div>
                            <div className="section-seller-item-text-value">
                              {companyData.regName}
                            </div>
                          </div>
                        </div>
                        <div className="section-seller-details">
                          <div className="section-item-icon-wrapper">
                            <svg
                              enable-background="new 0 0 15 15"
                              viewBox="0 0 15 15"
                              x="0"
                              y="0"
                              stroke-width="0"
                              className="shopee-svg-icon"
                            >
                              <path d="m13 1.9c-.2-.5-.8-1-1.4-1h-8.4c-.6.1-1.2.5-1.4 1l-1.4 4.3c0 .8.3 1.6.9 2.1v4.8c0 .6.5 1 1.1 1h10.2c.6 0 1.1-.5 1.1-1v-4.6c.6-.4.9-1.2.9-2.3zm-11.4 3.4 1-3c .1-.2.4-.4.6-.4h8.3c.3 0 .5.2.6.4l1 3zm .6 3.5h.4c.7 0 1.4-.3 1.8-.8.4.5.9.8 1.5.8.7 0 1.3-.5 1.5-.8.2.3.8.8 1.5.8.6 0 1.1-.3 1.5-.8.4.5 1.1.8 1.7.8h.4v3.9c0 .1 0 .2-.1.3s-.2.1-.3.1h-9.5c-.1 0-.2 0-.3-.1s-.1-.2-.1-.3zm8.8-1.7h-1v .1s0 .3-.2.6c-.2.1-.5.2-.9.2-.3 0-.6-.1-.8-.3-.2-.3-.2-.6-.2-.6v-.1h-1v .1s0 .3-.2.5c-.2.3-.5.4-.8.4-1 0-1-.8-1-.8h-1c0 .8-.7.8-1.3.8s-1.1-1-1.2-1.7h12.1c0 .2-.1.9-.5 1.4-.2.2-.5.3-.8.3-1.2 0-1.2-.8-1.2-.9z"></path>
                            </svg>
                          </div>
                          <div className="section-seller-item-text">
                            <div className="section-seller-item-text-name">
                              Presence:&nbsp;
                            </div>
                            <div className="section-seller-item-text-value">
                              {storeData.isphysical == "Y"
                                ? "Online & In-Store"
                                : "Online"}
                            </div>
                          </div>
                        </div>
                        <div className="section-seller-details">
                          <div className="section-item-icon-wrapper">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="bi bi-truck shopee-svg-icon"
                              viewBox="0 0 16 16"
                              stroke-width="0"
                            >
                              <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                            </svg>
                          </div>
                          <div className="section-seller-item-text">
                            <div className="section-seller-item-text-name">
                              Delivery:&nbsp;
                            </div>
                            <div className="section-seller-item-text-value">
                              {storeData.deliveryNotes != undefined &&
                              storeData.deliveryNotes != ""
                                ? "Free Shipping on orders over $" +
                                  storeData.deliveryNotes
                                : storeData.deliveryType != "" &&
                                  storeData.secondDeliveryType != "" &&
                                  storeData.secondDeliveryType != undefined
                                ? storeData.deliveryType +
                                  "/" +
                                  storeData.secondDeliveryType
                                : storeData.deliveryType != ""
                                ? storeData.deliveryType
                                : storeData.secondDeliveryType != "" &&
                                  storeData.secondDeliveryType != undefined
                                ? storeData.secondDeliveryType
                                : ""}
                              {/*storeData.deliveryNotes != undefined &&
                              storeData.deliveryNotes != ""
                                ? "(" + storeData.deliveryNotes + ")"
                          : ""*/}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 pr-0">
                        <div className="section-seller-details">
                          <div className="section-item-icon-wrapper">
                            <svg
                              enable-background="new 0 0 15 15"
                              viewBox="0 0 15 15"
                              x="0"
                              y="0"
                              className="shopee-svg-icon"
                            >
                              <g>
                                <circle
                                  cx="5.5"
                                  cy="5"
                                  fill="none"
                                  r="4"
                                  strokeMiterlimit="10"
                                ></circle>
                                <path
                                  d="m8.4 7.5c.7 0 1.1.7 1.1 1.6v4.9h-8v-4.9c0-.9.4-1.6 1.1-1.6"
                                  fill="none"
                                  stroke-linejoin="round"
                                  strokeMiterlimit="10"
                                ></path>
                                <path
                                  d="m12.6 6.9c.7 0 .9.6.9 1.2v5.7h-2"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  strokeMiterlimit="10"
                                ></path>
                                <path
                                  d="m9.5 1.2c1.9 0 3.5 1.6 3.5 3.5 0 1.4-.9 2.7-2.1 3.2"
                                  fill="none"
                                  stroke-linecap="round"
                                  strokeMiterlimit="10"
                                ></path>
                              </g>
                            </svg>
                          </div>
                          <div className="section-seller-item-text">
                            <div className="section-seller-item-text-name">
                              Followers:&nbsp;
                            </div>
                            <div className="section-seller-item-text-value">
                              {followers}
                            </div>
                          </div>
                        </div>
                        <div className="section-seller-details">
                          <div className="section-item-icon-wrapper">
                            <svg
                              enable-background="new 0 0 15 15"
                              viewBox="0 0 15 15"
                              x="0"
                              y="0"
                              className="shopee-svg-icon icon-rating"
                            >
                              <polygon
                                fill="none"
                                points="7.5 .8 9.7 5.4 14.5 5.9 10.7 9.1 11.8 14.2 7.5 11.6 3.2 14.2 4.3 9.1 .5 5.9 5.3 5.4"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                strokeMiterlimit="10"
                              ></polygon>
                            </svg>
                          </div>
                          <div className="section-seller-item-text">
                            <div className="section-seller-item-text-name">
                              rating:&nbsp;
                            </div>
                            <div className="section-seller-item-text-value">
                              {storeData.avgRating}
                            </div>
                          </div>
                        </div>
                        <div className="section-seller-details">
                          <TimeCard
                            hours={{
                              opening: storeData.fromHrs
                                ? storeData.fromHrs[0]
                                : null,
                              closing: storeData.toHrs
                                ? storeData.toHrs[0]
                                : null,
                            }}
                            closed={storeData?.weeklyOff?.split(",")}
                            timeNote={storeData?.timeNote}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row mt-4"> */}
        {ratingForm && (
          <RatingForm
            handleOpen={handleOpenForm}
            handleClose={handleCloseForm}
            open={ratingForm}
            storeId={selectedStoreId}
            productId={selectedProduct}
            offerId={""}
            type={"Product"}
          />
        )}
        {loginForm && (
          <LoginForm
            handleOpen={handleLoginOpenForm}
            handleClose={handleLoginCloseForm}
            open={loginForm}
          />
        )}
        <div className="row mt-4">
          <div className="col-md-12 mx-auto">
            {hasOffers && (
              <h6
                onClick={() => {
                  collectionClick("home");
                }}
                className={
                  "home" == selectedCollection
                    ? "pcmall-shopmicrofe_1ftFmB active"
                    : "pcmall-shopmicrofe_1ftFmB"
                }
                //className="pb-3 border-bottom mt-3"
              >
                <svg
                  height="15px"
                  width="15px"
                  viewBox="0 0 12 10"
                  class="mr-2"
                >
                  <g fill-rule="evenodd" stroke="none" stroke-width="1">
                    <g transform="translate(-373 -208)">
                      <g transform="translate(155 191)">
                        <g transform="translate(218 17)">
                          <path d="m0 2h2v-2h-2zm4 0h7.1519633v-2h-7.1519633z"></path>
                          <path d="m0 6h2v-2h-2zm4 0h7.1519633v-2h-7.1519633z"></path>
                          <path d="m0 10h2v-2h-2zm4 0h7.1519633v-2h-7.1519633z"></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                Home
              </h6>
            )}

            <div className="row mr-1">
              <div>
                <div className="col-auto category-scroll">
                  <div
                    onClick={() => {
                      collectionClick("allProduct");
                    }}
                    className={
                      "allProduct" == selectedCollection
                        ? "pcmall-shopmicrofe_1ftFmB active"
                        : "pcmall-shopmicrofe_1ftFmB"
                    }
                  >
                    <svg
                      viewBox="0 0 4 7"
                      className="shopee-svg-icon pcmall-shopmicrofe_OnTpJ- icon-down-arrow-right-filled"
                    >
                      <polygon points="4 3.5 0 0 0 7"></polygon>
                    </svg>
                    Products
                  </div>
                  {storeCollection != undefined &&
                  storeCollection != null &&
                  storeCollection.length > 0
                    ? storeCollection.map((item, index) => (
                        <div
                          onClick={() => {
                            collectionClick(item.id);
                          }}
                          className={
                            item.id == selectedCollection
                              ? "pcmall-shopmicrofe_1ftFmB active"
                              : "pcmall-shopmicrofe_1ftFmB"
                          }
                        >
                          <svg
                            viewBox="0 0 4 7"
                            className="shopee-svg-icon pcmall-shopmicrofe_OnTpJ- icon-down-arrow-right-filled"
                          >
                            <polygon points="4 3.5 0 0 0 7"></polygon>
                          </svg>
                          <p
                            style={{
                              maxHeight: "5rem",
                              width: "100%",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              display: "-webkit-box",
                              "-webkit-box-orient": "vertical",
                              "-webkit-line-clamp": "5",
                              "text-overflow": "ellipsis",
                              "white-space": "normal",
                            }}
                          >
                            {item.heading}
                          </p>
                        </div>
                      ))
                    : ""}
                </div>
                {storeCollection.length > 15 && (
                  <div className="border border-primary category-scroll-view ml-3">
                    <KeyboardArrowDown className="scroll-icon" />
                  </div>
                )}
              </div>
              <div className="col">
                {selectedCollection !== "home" ? (
                  <div className="shopee-sort-bar">
                    <span className="shopee-sort-bar__label"></span>
                    <div className="sort-options">
                      <span className="shopee-sort-bar__label mr-4">
                        Sort by
                      </span>
                      <CustomSelect
                        value={orderByColumnName}
                        onChange={(e) => setOrderByColumnName(e.target.value)}
                        defaultValue={filterColumns[0].key}
                        className="form-control w-75"
                      >
                        {filterColumns.map((item) => (
                          <CustomerSelectOption
                            selected={orderByColumnName === item.key}
                            value={`${item.key}`}
                          >
                            {item.value}
                          </CustomerSelectOption>
                        ))}
                      </CustomSelect>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {selectedCollection != "home" ? (
                  <div className="row mt-1">
                    {collectionAllProduct.map((item, index) => (
                      <div className="col-md-3 mb-3">
                        <ProductCard
                          branchName={item.storeName}
                          storeId={item.storeId}
                          productId={item.id}
                          img={item.images}
                          branchLogo={item.logo}
                          heading={item.heading}
                          avgRating={item.avgRating}
                          discount={item.discountPerc}
                          actualPrice={item.actualPrice}
                          offerPrice={item.offerPrice}
                          totalLikes={item.totalLikes}
                          key={item.id}
                        />
                      </div>
                    ))}
                  </div>
                ) : !loading ? (
                  <React.Fragment>
                    <div className="row" style={{ marginTop: "-50px" }}>
                      <div className="col-md-12 mx-auto">
                        <div className="row">
                          {storeOffer.map((item, index) => (
                            //checkIfImageExists(item.fullImage)?
                            <div className="col-md-6 pb-3">
                              <img
                                src={item.fullImage}
                                onClick={() => {
                                  cardClick(item.id);
                                }}
                                alt=""
                                className="banner offerViewCard"
                              />
                            </div> //:""
                          ))}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <strong>Loading ....</strong>
                )}

                {selectedCollection !== "home" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <ReactPaginate
                      forcePage={currentPage}
                      pageCount={pageCount}
                      pageRangeDisplayed={3} // Number of pages to display in the pagination
                      marginPagesDisplayed={1} // Number of pages to display on the edges
                      previousLabel={leftPaginationArrow}
                      nextLabel={rightPaginationArrow}
                      breakLabel={"..."}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      disabledClassName={"disabled"}
                      onPageChange={(e) => {
                        loadItemsByPageId(e.selected);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* {!loading && storeData != undefined && storeData != null ? (
          <div className="row mt-4" id="store">
            <div className="col-md-10 mx-auto">
              <div className="about-store">
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h4 className="m-0">About Store</h4>
                  </AccordionSummary>

                  <Accordion className="w-100">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Store Description</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{storeData.storeDescription}</Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className="w-100">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Returns</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{storeData.returnPolicy}</Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className="w-100">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Delivery</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{storeData.deliveryPolicy}</Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion className="w-100">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Warranty</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{storeData.warrantyPolicy}</Typography>
                    </AccordionDetails>
                  </Accordion>
                </Accordion>
              </div>
            </div>
          </div>
        ) : (
          ""
        )} */}
        <ShopifyStoreDiscount
          handleOpen={handleOpenDiscountForm}
          handleClose={handleCloseDiscountForm}
          storeData={storeData}
          open={discountForm}
        />
      </div>
    </>
  );
}
export default StoresDetails;
