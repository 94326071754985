import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useAuth } from "../../../Utils/AuthContext";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Moment from "react-moment";
import { useRouteMatch } from "react-router-dom";
import API from "../../../Utils/API";
import { Tooltip } from "antd";
import renderHTML from 'react-render-html';
import LoginForm from "../../DialogBox/LoginForm";
import ShopifyStoreDiscount from "../../DialogBox/ShopifyStoreDiscount";

const style = {
  backButton: {
    fontSize: "25px",
    marginBottom: "10px",
    marginTop: "10px",
    marginLeft: "12px",
    color: "#1d6042",
    cursor: "pointer",
  },
};

function AboutStore() {
  const { path, params } = useRouteMatch();
  const history = useHistory();
  const { userInfo } = useAuth();
  const [disabled, setDisabled] = useState(false);
  const [storeData, setStoreData] = useState([]);
  const [followers, setFollowers] = useState(0);
  const [companyData, setCompanyData] = useState([]);
  const [loginForm, setLoginForm] = useState(false);
  const [discountForm, setDiscountForm] = useState(false);
  const handleLoginOpenForm = () => setLoginForm(true);
  const handleLoginCloseForm = () => setLoginForm(false);

  const handleOpenDiscountForm = () => setDiscountForm(true);
  const handleCloseDiscountForm = () => setDiscountForm(false);

  useEffect(() => {
    if (
      params.storeId !== undefined &&
      params.storeId != null &&
      params.storeId !== ""
    ) {
      API.getStoreFollow(
        userInfo != null && userInfo != undefined ? userInfo.email : "gust",
        "follow"
      )
        .then((result1) => {
          if (result1 !== undefined) {
            result1.map((items) => {
              if (items.storeId === params.storeId) {
                setDisabled(!disabled);
              }
            });
            console.log(result1);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      API.getStoreById(params.storeId)
        .then((result) => {
          if (result !== undefined) {
            setStoreData(result);
            API.getStoreFollowList(params.storeId)
              .then((res) => {
                if (res !== undefined) {
                  setFollowers(res.length);
                }
              })
              .catch((error) => {
                console.log(error);
              });
            API.getCompanyId(result.companyId)
              .then((result1) => {
                if (result1 != undefined) {
                  setCompanyData(result1);
                }
              })
              .catch((error) => {
                console.log(error);
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error);
        });
    }
  }, [params.storeId]);

  const followStore = (newValue) => {
    if (!userInfo?.email) setLoginForm(true);
    else
    {
    API.followStore(userInfo?.email, newValue, "follow")
      .then((result) => {
        if (result != undefined) {
          setDisabled(!disabled);
        }
      })
      .catch((error) => {
        // setLoading(false);
        console.log(error);
      });
    }
  };

  const openDiscountPage = () => {
    setDiscountForm(true)
  };

  return (
    <div className="container-fluid">
      <i
       style={{...style.backButton, position:'relative'}}
        className="fa fa-arrow-circle-left"
        aria-hidden="true"
        title="Back to Login"
        onClick={() => history.goBack()}
      ></i>
      <div className="row bg-white py-4 border-bottom">
        <div className="col-md-10 mx-auto">
          <div className="row seller-overview">
            <div className="col-md-5">
              <div>
                <div className="d-flex">
                  <img
                    className="shopee-avatar__img mt-2"
                    src={
                      storeData.logoMedium !== undefined &&
                      storeData.logoMedium !== null
                        ? storeData.logoMedium
                        : "https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg"
                    }
                  />
                  <div className="section-seller-portrait-info">
                    <h1 className="section-seller-portrait-name">
                      {storeData.storeName}{" "}
                      {storeData.code !== "Primary"
                        ? `Branch: ${storeData.code}`
                        : ""}
                    </h1>
                    {/*<div className="section-seller-active-time"><strong>Branch: </strong>{storeData.code}</div>
                     */}
                    <div className="section-seller-portrait-status">
                      <div className="section-seller-active-time">
                        {storeData.address2}, Unit:
                        {storeData.address1}, {" "}{storeData.cityId} -{" "}
                        {storeData.pincode}
                      </div>
                      {/*<div className="section-seller-active-time">Active 7 minutes ago</div>
                       */}
                    </div>
                  </div>
                </div>
                <div className="section-seller-overview-buttons">
                  <a className="section-seller-button" href="#store">
                    <button className="shopee-button-outline">
                      <span className="section-seller-overview-icon">
                        <svg
                          enable-background="new 0 0 15 15"
                          viewBox="0 0 15 15"
                          x="0"
                          y="0"
                          stroke-width="0"
                          class="shopee-svg-icon"
                        >
                          <path d="m13 1.9c-.2-.5-.8-1-1.4-1h-8.4c-.6.1-1.2.5-1.4 1l-1.4 4.3c0 .8.3 1.6.9 2.1v4.8c0 .6.5 1 1.1 1h10.2c.6 0 1.1-.5 1.1-1v-4.6c.6-.4.9-1.2.9-2.3zm-11.4 3.4 1-3c .1-.2.4-.4.6-.4h8.3c.3 0 .5.2.6.4l1 3zm .6 3.5h.4c.7 0 1.4-.3 1.8-.8.4.5.9.8 1.5.8.7 0 1.3-.5 1.5-.8.2.3.8.8 1.5.8.6 0 1.1-.3 1.5-.8.4.5 1.1.8 1.7.8h.4v3.9c0 .1 0 .2-.1.3s-.2.1-.3.1h-9.5c-.1 0-.2 0-.3-.1s-.1-.2-.1-.3zm8.8-1.7h-1v .1s0 .3-.2.6c-.2.1-.5.2-.9.2-.3 0-.6-.1-.8-.3-.2-.3-.2-.6-.2-.6v-.1h-1v .1s0 .3-.2.5c-.2.3-.5.4-.8.4-1 0-1-.8-1-.8h-1c0 .8-.7.8-1.3.8s-1.1-1-1.2-1.7h12.1c0 .2-.1.9-.5 1.4-.2.2-.5.3-.8.3-1.2 0-1.2-.8-1.2-.9z"></path>
                        </svg>
                      </span>
                      About Store
                    </button>
                  </a>
                  <a className="section-seller-button">
                    <button
                      className={
                        disabled
                          ? "shopee-button-outline-follow"
                          : "shopee-button-outline"
                      }
                      disabled={disabled}
                      onClick={() => followStore(storeData.id)}
                    >
                      {disabled ? (
                        ""
                      ) : (
                        <span className="section-seller-overview-icon">
                          <svg
                            enable-background="new 0 0 10 10"
                            viewBox="0 0 10 10"
                            x="0"
                            y="0"
                            className="shopee-svg-icon icon-plus-sign"
                          >
                            <polygon points="10 4.5 5.5 4.5 5.5 0 4.5 0 4.5 4.5 0 4.5 0 5.5 4.5 5.5 4.5 10 5.5 10 5.5 5.5 10 5.5"></polygon>
                          </svg>
                        </span>
                      )}
                      {disabled ? "Following" : "Follow"}
                    </button>
                  </a>
                </div>
                <div className="section-seller-overview-buttons">
                    <a className="section-seller-button">
                      <button
                        className="shopee-button-outline"
                        onClick={() =>
                          openDiscountPage()
                        }
                      >
                        <span className="section-seller-overview-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-square" viewBox="0 0 16 16">
  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
</svg>
                        </span>
                        Additional Discounts
                      </button>
                    </a>
                    </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="section-info-list">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-7 pr-0">
                      <div className="section-seller-details">
                        <div className="section-item-icon-wrapper">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            class="bi bi-building shopee-svg-icon"
                            stroke-width="0"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
                            />
                            <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z" />
                          </svg>
                        </div>
                        <div className="section-seller-item-text">
                          <div className="section-seller-item-text-name">
                          Incorporated As:&nbsp;
                          </div>
                          <div className="section-seller-item-text-value">
                            {companyData.regName}
                          </div>
                        </div>
                      </div>
                      <div className="section-seller-details">
                        <div className="section-item-icon-wrapper">
                          <svg
                            enable-background="new 0 0 15 15"
                            viewBox="0 0 15 15"
                            x="0"
                            y="0"
                            stroke-width="0"
                            className="shopee-svg-icon"
                          >
                            <path d="m13 1.9c-.2-.5-.8-1-1.4-1h-8.4c-.6.1-1.2.5-1.4 1l-1.4 4.3c0 .8.3 1.6.9 2.1v4.8c0 .6.5 1 1.1 1h10.2c.6 0 1.1-.5 1.1-1v-4.6c.6-.4.9-1.2.9-2.3zm-11.4 3.4 1-3c .1-.2.4-.4.6-.4h8.3c.3 0 .5.2.6.4l1 3zm .6 3.5h.4c.7 0 1.4-.3 1.8-.8.4.5.9.8 1.5.8.7 0 1.3-.5 1.5-.8.2.3.8.8 1.5.8.6 0 1.1-.3 1.5-.8.4.5 1.1.8 1.7.8h.4v3.9c0 .1 0 .2-.1.3s-.2.1-.3.1h-9.5c-.1 0-.2 0-.3-.1s-.1-.2-.1-.3zm8.8-1.7h-1v .1s0 .3-.2.6c-.2.1-.5.2-.9.2-.3 0-.6-.1-.8-.3-.2-.3-.2-.6-.2-.6v-.1h-1v .1s0 .3-.2.5c-.2.3-.5.4-.8.4-1 0-1-.8-1-.8h-1c0 .8-.7.8-1.3.8s-1.1-1-1.2-1.7h12.1c0 .2-.1.9-.5 1.4-.2.2-.5.3-.8.3-1.2 0-1.2-.8-1.2-.9z"></path>
                          </svg>
                        </div>
                        <div className="section-seller-item-text">
                          <div className="section-seller-item-text-name">
                            Presence:&nbsp;
                          </div>
                          <div className="section-seller-item-text-value">
                            {storeData.isphysical == "Y"
                              ? "Online & In-Store"
                              : "Online"}
                          </div>
                        </div>
                      </div>
                      <div className="section-seller-details">
                        <div className="section-item-icon-wrapper">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="bi bi-truck shopee-svg-icon"
                            viewBox="0 0 16 16"
                            stroke-width="0"
                          >
                            <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                          </svg>
                        </div>
                        <div className="section-seller-item-text">
                          <div className="section-seller-item-text-name">
                            Delivery:&nbsp;
                          </div>
                          <div className="section-seller-item-text-value">
                          {storeData.deliveryNotes!=undefined&&storeData.deliveryNotes!=""?"Free Shipping on orders over $"+storeData.deliveryNotes :storeData.deliveryType!=""&& storeData.secondDeliveryType!="" &&storeData.secondDeliveryType!=undefined ? storeData.deliveryType+"/"+storeData.secondDeliveryType
                              :storeData.deliveryType!=""?storeData.deliveryType:storeData.secondDeliveryType!="" &&storeData.secondDeliveryType!=undefined?storeData.secondDeliveryType:""
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 pr-0">
                      <div className="section-seller-details">
                        <div className="section-item-icon-wrapper">
                          <svg
                            enable-background="new 0 0 15 15"
                            viewBox="0 0 15 15"
                            x="0"
                            y="0"
                            className="shopee-svg-icon"
                          >
                            <g>
                              <circle
                                cx="5.5"
                                cy="5"
                                fill="none"
                                r="4"
                                strokeMiterlimit="10"
                              ></circle>
                              <path
                                d="m8.4 7.5c.7 0 1.1.7 1.1 1.6v4.9h-8v-4.9c0-.9.4-1.6 1.1-1.6"
                                fill="none"
                                stroke-linejoin="round"
                                strokeMiterlimit="10"
                              ></path>
                              <path
                                d="m12.6 6.9c.7 0 .9.6.9 1.2v5.7h-2"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                strokeMiterlimit="10"
                              ></path>
                              <path
                                d="m9.5 1.2c1.9 0 3.5 1.6 3.5 3.5 0 1.4-.9 2.7-2.1 3.2"
                                fill="none"
                                stroke-linecap="round"
                                strokeMiterlimit="10"
                              ></path>
                            </g>
                          </svg>
                        </div>
                        <div className="section-seller-item-text">
                          <div className="section-seller-item-text-name">
                            Followers:&nbsp;
                          </div>
                          <div className="section-seller-item-text-value">
                            {followers}
                          </div>
                        </div>
                      </div>
                      <div className="section-seller-details">
                        <div className="section-item-icon-wrapper">
                          <svg
                            enable-background="new 0 0 15 15"
                            viewBox="0 0 15 15"
                            x="0"
                            y="0"
                            className="shopee-svg-icon icon-rating"
                          >
                            <polygon
                              fill="none"
                              points="7.5 .8 9.7 5.4 14.5 5.9 10.7 9.1 11.8 14.2 7.5 11.6 3.2 14.2 4.3 9.1 .5 5.9 5.3 5.4"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              strokeMiterlimit="10"
                            ></polygon>
                          </svg>
                        </div>
                        <div className="section-seller-item-text">
                          <div className="section-seller-item-text-name">
                            rating:&nbsp;
                          </div>
                          <div className="section-seller-item-text-value">
                            {storeData.avgRating}
                          </div>
                        </div>
                      </div>
                      <div className="section-seller-details">
                        <div className="section-item-icon-wrapper">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="bi bi-stopwatch shopee-svg-icon"
                            viewBox="0 0 16 16"
                            stroke-width="0"
                          >
                            <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z" />
                            <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z" />
                          </svg>
                        </div>
                        <div className="section-seller-item-text">
                          <div className="section-seller-item-text-name">
                            Timing:&nbsp;
                          </div>
                          <div className="section-seller-item-text-value">
                            <Moment tz="CST" format="hh:mm A">
                              {storeData.fromHrs != undefined &&
                              storeData.fromHrs != null &&
                              storeData.fromHrs.length > 0
                                ? "1970-01-01T" +
                                  (
                                    "0" + storeData.fromHrs[0].split(":")[0]
                                  ).slice(-2) +
                                  ":" +
                                  (
                                    "0" + storeData.fromHrs[0].split(":")[1]
                                  ).slice(-2) +
                                  ":" +
                                  (
                                    "0" + storeData.fromHrs[0].split(":")[2]
                                  ).slice(-2)
                                : ""}
                            </Moment>{" "}
                            -{" "}
                            <Moment tz="CST" format="hh:mm A">
                              {storeData.toHrs != undefined &&
                              storeData.toHrs != null &&
                              storeData.toHrs.length > 0
                                ? "1970-01-01T" +
                                  (
                                    "0" + storeData.toHrs[0].split(":")[0]
                                  ).slice(-2) +
                                  ":" +
                                  (
                                    "0" + storeData.toHrs[0].split(":")[1]
                                  ).slice(-2) +
                                  ":" +
                                  (
                                    "0" + storeData.toHrs[0].split(":")[2]
                                  ).slice(-2)
                                : ""}
                            </Moment>
                            <Tooltip
                              placement="bottom"
                              color={"white"}
                              title={
                                <React.Fragment>
                                  <li style={{ color: "gray" }}>
                                    ● {storeData.timeNote}
                                  </li>
                                  <li style={{ color: "Red" }}>
                                    ● {`Closed : ${storeData.weeklyOff}`}
                                  </li>
                                </React.Fragment>
                              }
                            >
                              <i
                                className="fa fa-info-circle ml-2"
                                style={style.tooltipIcon}
                              ></i>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {storeData != undefined && storeData != null ? (
        <div className="row mt-4" id="store">
          <div className="col-md-10 mx-auto">
            <div className="about-store">
              <h1>About Store</h1>
              <div className="mt-5">
                <b>Store Description:</b>
                <Typography>{storeData.storeDescription!=undefined&&storeData.storeDescription!=""?renderHTML(storeData.storeDescription):""}</Typography>
              </div>
              <div className="mt-5">
                <b className="mt-3">Returns:</b>
                <Typography>{storeData.returnPolicy!=undefined&&storeData.returnPolicy!=""?renderHTML(storeData.returnPolicy):""}</Typography>
              </div>
              <div className="mt-5">
                <b className="mt-3">Delivery:</b>
                <Typography>{storeData.deliveryPolicy!=undefined&&storeData.deliveryPolicy!=""?renderHTML(storeData.deliveryPolicy):""}</Typography>
              </div>
              <div className="mt-5">
                <b className="mt-3">Miscellaneous:</b>
                <Typography>{storeData.warrantyPolicy!=undefined&&storeData.warrantyPolicy!=""?renderHTML(storeData.warrantyPolicy):""}</Typography>
              </div>
            </div>
          </div>
        </div>
      ) : (
        "Loading"
      )}

{loginForm && (
          <LoginForm
            handleOpen={handleLoginOpenForm}
            handleClose={handleLoginCloseForm}
            open={loginForm}
          />
        )}

<ShopifyStoreDiscount
        handleOpen={handleOpenDiscountForm}
        handleClose={handleCloseDiscountForm}
        storeData={storeData}
        open={discountForm}
      />
    </div>
  );
}

export default AboutStore;
