import { Select, styled } from "@material-ui/core";
import React from "react";
import { COLORS } from "../../../Utils/context/ThemeContext";

const CustomSelect = styled("select")(({ theme }) => ({}));

export default CustomSelect;
// import { Select, styled } from "@material-ui/core";
// import React from "react";

// const StyledSelect = styled(Select)(({ theme }) => ({
//   "&": {
//     backgroundColor: "#cdec85", // Change to your desired color
    // color: COLORS.primary.main,
//     textAlign: "center",
//   },
// }));
// const CustomSelect = (props) => (
//   <StyledSelect
//     {...props}
//     MenuProps={{
//       PaperProps: {
//         style: {
//           maxHeight: '50vh',
//         },
//       },
//       anchorOrigin: {
//         vertical: 'bottom',
//         horizontal: 'left',
//       },
//       transformOrigin: {
//         vertical: 'top',
//         horizontal: 'left',
//       },
//       getContentAnchorEl: null,
//     }}
//   />
// );
// export default CustomSelect;
