import React, { useState, useEffect } from "react";
import renderHTML from "react-render-html";
import { useAuth } from "../../../Utils/AuthContext";
import { useSelector, useDispatch } from "react-redux";
import { getAllOffers } from "../../../Redux/Offers/OffersActions";
import { getUserSnips, pushSnipsData } from "../../../Redux/Snips/SnipsAction";
import Button from "@material-ui/core/Button";
import Data from "../../../data.json";
import LoginForm from "../../DialogBox/LoginForm";
import API from "../../../Utils/API";
import { uuid } from "../../../Utils/uuid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useRouteMatch } from "react-router-dom";
import RatingForm from "../../DialogBox/RatingForm";
import ProductCard from "../product/ProductCard";
import Moment from "react-moment";
import { Dialog } from "@material-ui/core";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ReactPlayer from "react-player";
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  PinterestShareButton,
  TwitterShareButton,
  InstapaperShareButton,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  PinterestIcon,
  TwitterIcon,
} from "react-share";
import { Helmet } from "react-helmet";
import { Tooltip } from "antd";
import ShopifyStoreDiscount from "../../DialogBox/ShopifyStoreDiscount";
import environment from "../../../Utils/env";
import { ListAlt } from "@material-ui/icons";
import { COLORS } from "../../../Utils/context/ThemeContext";

const style = {
  backButton: {
    fontSize: "25px",
    marginBottom: "10px",
    color: COLORS.primary.main,
    cursor: "pointer",
  },
};

function OffersDetail({ match }) {
  const [loginForm, setLoginForm] = useState(false);
  const { userInfo } = useAuth();
  const { allSnips } = useSelector((state) => state.snips);
  const { location, userId, cityId, areaId } = useSelector(
    (state) => state.location
  );
  const { allStores } = useSelector((state) => state.stores);
  const { loading, allLikes } = useSelector((state) => state.snips);

  const [offerProduct, setOfferProduct] = useState([]);
  const [offer, setOffer] = useState();
  const [selectedOfferId, setSelectedOffeId] = useState("");
  const [selectedStoreId, setSelectedStoreId] = useState("");
  const [ratingForm, setRatingForm] = useState(false);
  const [discountForm, setDiscountForm] = useState(false);
  const [open, setOpen] = useState(false);
  const [liked, setLiked] = useState(false);
  const [likesLoading, setLikesLoading] = useState(false);
  const [likes, setLikes] = useState(0);
  const [key, setKey] = useState(0);
  const [storeData, setStoreData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [offerRating, setOfferRating] = useState([]);
  const [offerRatingData, setOfferRatingData] = useState([]);
  const [mediaItem, setMediaItem] = useState([]);
  const [activeFilter, setActiveFilter] = useState("all");
  const [ratingFilterCount1, setRatingFilterCount1] = useState(0);
  const [ratingFilterCount2, setRatingFilterCount2] = useState(0);
  const [ratingFilterCount3, setRatingFilterCount3] = useState(0);
  const [ratingFilterCount4, setRatingFilterCount4] = useState(0);
  const [ratingFilterCount5, setRatingFilterCount5] = useState(0);
  const [ratingFilterCountC, setRatingFilterCountC] = useState(0);
  const [ratingFilterCountM, setRatingFilterCountM] = useState(0);
  const [followers, setFollowers] = useState(0);
  const [snipped, setSnipped] = useState(false);
  const [rated, setRated] = useState(false);
  const [version, setVersion] = useState(1);
  const [disabled, setDisabled] = useState(false);

  console.log("all store offer>>>>>>>>>>", allStores);
  const obj = allStores.filter((item) => item.id === match.params.offerId);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location)
      // dispatch(getAllOffers(userInfo?.email || userId, cityId, areaId));
      API.getOffersById(userInfo?.email, match.params.offerId)
        .then((result1) => {
          if (result1 != undefined) {
            setOffer(result1);
            setLikes(result1.totalLikes);
            allLikes.filter((e) => e.offerId === result1.id).length > 0
              ? setLiked(true)
              : setLiked(false);
            API.getUserSnips(userInfo?.email, "snip")
              .then((result) => {
                if (result !== undefined) {
                  result.filter((e) => e.offer.id === result1.id).length > 0
                    ? setSnipped(true)
                    : setSnipped(false);
                  console.log(result1);
                }
              })
              .catch((err) => {
                console.log(err);
              });
            API.getUserOfferRating(userInfo?.email)
              .then((response) => {
                if (response !== undefined) {
                  response.filter((e) => e.id === result1.id).length > 0
                    ? setRated(true)
                    : setRated(false);
                  console.log(result1);
                }
              })
              .catch((err) => {
                console.log(err);
              });
            API.getStoreById(result1.storeId)
              .then((result) => {
                if (result != undefined) {
                  setStoreData(result);
                  API.getStoreFollowList(result1.storeId)
                    .then((res) => {
                      if (res !== undefined) {
                        setFollowers(res.length);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  API.getCompanyId(result.companyId)
                    .then((result1) => {
                      if (result1 != undefined) {
                        setCompanyData(result1);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                      console.log(error);
                    });
                }
              })
              .catch((error) => {
                console.log(error);
                console.log(error);
              });
            // setLoading(false);
          } else {
            setOffer("");
            // setLoading(false);
          }
        })
        .catch((error) => {
          // setLoading(false);
          console.log(error);
          // console.log(error);
        });
    API.getOfferProducts(match.params.offerId,0,16)
      .then((result1) => {
        let {
          content,
        } = result1;
          setOfferProduct(content);
      })
      .catch((error) => {
        // setLoading(false);
        console.log(error);
        // console.log(error);
      });

    API.getRatingByOffer(match.params.offerId)
      .then((result) => {
        if (result != undefined) {
          setOfferRating(result);
          setOfferRatingData(result);
          //********************************************************* */

          setRatingMedia(result);

          /*************************************************************** */
          const obj1 = result.filter((item) => item.rating == "1");
          setRatingFilterCount1(obj1.length);
          const obj2 = result.filter((item) => item.rating == "2");
          setRatingFilterCount2(obj2.length);
          const obj3 = result.filter((item) => item.rating == "3");
          setRatingFilterCount3(obj3.length);
          const obj4 = result.filter((item) => item.rating == "4");
          setRatingFilterCount4(obj4.length);
          const obj5 = result.filter((item) => item.rating == "5");
          setRatingFilterCount5(obj5.length);
          const objC = result.filter(
            (item) =>
              item.comment != "" &&
              item.comment != null &&
              item.comment != undefined
          );
          setRatingFilterCountC(objC.length);
          const objM = result.filter(
            (item) =>
              item.ratingMedia != "" &&
              item.ratingMedia != null &&
              item.ratingMedia != undefined &&
              item.ratingMedia.length > 0
          );
          setRatingFilterCountM(objM.length);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    const randomVersion = Math.floor(Math.random() * (100000000 - 1 + 1) + 1);
    setVersion(randomVersion);
    window.scrollTo(0, 0)
  }, [location]);

  const handleSnip = (dataObj) => {
    if (!userInfo?.email) setLoginForm(true);
    else {
      console.log("all snip>>>>>>>>>>", allSnips);
      const obj =
        allSnips != undefined &&
        allSnips.filter((item) => item.offer.id === dataObj.id);
      console.log("all obj>>>>>>>>>>", obj);
      if (obj != null && obj != undefined && obj != "") {
        toast.error("Offer already in snip..");
      } else {
        API.getStoreById(dataObj.storeId)
          .then((result) => {
            if (result != undefined) {
              var snapList = [];
              let snipBody = {
                id: uuid(),
                userId: userInfo?.email,
                offer: dataObj,
                type: "Offer",
                activityType: "snip",
                storeId: dataObj.storeId,
                storeName: result.storeName,
                storeCode: result.code,
                offerId: dataObj.id,
              };
              snapList.push(snipBody);
              API.addSnip(userInfo?.email, snipBody);
              dispatch(pushSnipsData(snipBody));
              setSnipped(true);
            }
          })
          .catch((error) => {
            console.log(error);
            console.log(error);
          });
      }
    }
  };

  const isVideo = (reviewUrl) => {
    const videos = ["mp4", "3gp", "ogg", "mov", "wmv", "flv", "avi", "mkv"];
    const url = new URL(reviewUrl);
    const extension = url.pathname.split(/\.(?=[^\.]+$)/)[1];
    console.log("extension>>>>>>>>>>>>>>>>>>>>>>>>>>>", extension);
    let flag = false;
    if (videos.includes(extension)) {
      flag = true;
    }
    console.log("isVideo>>>>>>>>>>>>>>>>>>>>>>>>>>>", flag);
    return flag;
  };

  const setRatingMedia = (data) => {
    const mediaItem = [];
    console.log("this.state.offerRating>>>>>>>>>>>>>>>>>>>>>", data);
    data.map((item, index) => {
      if (
        item.ratingMedia != null &&
        item.ratingMedia != undefined &&
        item.ratingMedia.length > 0
      ) {
        const images = [
          // {
          //   original: "",
          //   thumbnail: "",
          // },
          {
            original: isVideo(item.ratingMedia[0])
              ? "https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/RatingVideoImage/icons-play.png"
              : item.ratingMedia[0],
            thumbnail: isVideo(item.ratingMedia[0])
              ? "https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/RatingVideoImage/icons-play.png"
              : item.ratingMedia[0],
            ...(isVideo(item.ratingMedia[0]) && {
              embedUrl: item.ratingMedia[0],
            }),
            // ...(isVideo(item.ratingMedia[0]) && {
            //   renderItem: this._renderVideo.bind(this),
            // }),
            //embedUrl: 'https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/RatingVideoImage/Mississauga%2084/2e2e23cf31b6a31b006cf35ac32bd18f.mp4',
            //description:"viedeo",
            //renderItem: this._renderVideo.bind(this)
          },
          ...(item.ratingMedia.length > 1
            ? [
                {
                  original: isVideo(item.ratingMedia[1])
                    ? "https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/RatingVideoImage/icons-play.png"
                    : item.ratingMedia[1],
                  thumbnail: isVideo(item.ratingMedia[1])
                    ? "https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/RatingVideoImage/icons-play.png"
                    : item.ratingMedia[1],
                  ...(isVideo(item.ratingMedia[1]) && {
                    embedUrl: item.ratingMedia[1],
                  }),
                  // ...(isVideo(item.ratingMedia[1]) && {
                  //   renderItem: this._renderVideo.bind(this),
                  // }),
                },
              ]
            : []),
          ...(item.ratingMedia.length > 2
            ? [
                {
                  original: isVideo(item.ratingMedia[2])
                    ? "https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/RatingVideoImage/icons-play.png"
                    : item.ratingMedia[2],
                  thumbnail: isVideo(item.ratingMedia[2])
                    ? "https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/RatingVideoImage/icons-play.png"
                    : item.ratingMedia[2],
                  ...(isVideo(item.ratingMedia[2]) && {
                    embedUrl: item.ratingMedia[2],
                  }),
                  // ...(isVideo(item.ratingMedia[2]) && {
                  //   renderItem: this._renderVideo.bind(this),
                  // }),
                },
              ]
            : []),
          ...(item.ratingMedia.length > 3
            ? [
                {
                  original: isVideo(item.ratingMedia[3])
                    ? "https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/RatingVideoImage/icons-play.png"
                    : item.ratingMedia[3],
                  thumbnail: isVideo(item.ratingMedia[3])
                    ? "https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/RatingVideoImage/icons-play.png"
                    : item.ratingMedia[3],
                  ...(isVideo(item.ratingMedia[3]) && {
                    embedUrl: item.ratingMedia[3],
                  }),
                  // ...(isVideo(item.ratingMedia[3]) && {
                  //   renderItem: this._renderVideo.bind(this),
                  // }),
                },
              ]
            : []),
        ];
        mediaItem[index] = images;
      }
    });
    setMediaItem(mediaItem);
  };

  const filterClick = (value) => {
    let obj = [];
    setActiveFilter(value);
    if (value == "all") {
      setOfferRating(offerRatingData);
      setRatingMedia(offerRatingData);
    } else if (value == "c") {
      console.log("filter cond>>>>>>>>>>>>>>", value);
      obj = offerRatingData.filter(
        (item) =>
          item.comment != "" &&
          item.comment != null &&
          item.comment != undefined
      );
      console.log("filter Value>>>>>>>>>>>>>>", obj);
      setOfferRating(obj);
      setRatingMedia(obj);
    } else if (value == "m") {
      console.log("filter cond>>>>>>>>>>>>>>", value);
      obj = offerRatingData.filter(
        (item) =>
          item.ratingMedia != "" &&
          item.ratingMedia != null &&
          item.ratingMedia != undefined &&
          item.ratingMedia.length > 0
      );
      console.log("filter Value>>>>>>>>>>>>>>", obj);
      setOfferRating(obj);
      setRatingMedia(obj);
    } else {
      console.log("filter cond>>>>>>>>>>>>>>", value);
      obj = offerRatingData.filter((item) => item.rating == value);
      console.log("filter Value>>>>>>>>>>>>>>", obj);
      setOfferRating(obj);
      setRatingMedia(obj);
    }
  };

  //const handleOpenForm = () => setLoginForm(true);

  //const handleCloseForm = () => setLoginForm(false);
  const handleOpenForm = () => setRatingForm(true);
  const handleCloseForm = () => setRatingForm(false);
  const handleLoginOpenForm = () => setLoginForm(true);
  const handleLoginCloseForm = () => setLoginForm(false);
  const handleOpenDiscountForm = () => setDiscountForm(true);
  const handleCloseDiscountForm = () => setDiscountForm(false);

  const productCardClick = (newValue, productId) => {
    history.push(`/productDetail/` + newValue + "/" + productId);
  };
  const ratingClick = (newValue, storeId) => {
    // history.push(`/storeDetails/` + newValue);
    if (!userInfo?.email) setLoginForm(true);
    else {
      setSelectedOffeId(newValue);
      setSelectedStoreId(storeId);
      setRatingForm(true);
    }
    // console.log("Under Development..............")
  };

  const viewStoreClick = (storeId) => {
    history.push(`/storeDetails/` + storeId);
  };
  const makeLikes = (newValue) => {
    // history.push(`/storeDetails/` + newValue);
    if (!userInfo?.email) setLoginForm(true);
    else {
      setLikesLoading(true);
      if (
        allLikes !== undefined &&
        allLikes.filter((e) => e.type === "Offer") &&
        allLikes.filter((e) => e.offerId === newValue).length === 0 &&
        !loading &&
        !likesLoading
      ) {
        API.makeLikes(userInfo?.email, newValue, "offer")
          .then((result1) => {
            if (result1 != undefined) {
              // setOffer(result1)
              // totalLikes = totalLikes + 1;
              // setLikes(totalLikes);
              dispatch(getUserSnips(userInfo?.email, "like"));
              setLikesLoading(false);
              // setLoading(false);
              API.getOffersById(userInfo?.email, newValue)
                .then((result1) => {
                  if (result1 != undefined) {
                    setOffer(result1);
                    setLikes(result1.totalLikes);
                    setLiked(true);
                    // setLoading(false);
                  } else {
                    setOffer("");
                    // setLoading(false);
                  }
                })
                .catch((error) => {
                  // setLoading(false);
                  console.log(error);
                  // console.log(error);
                });
            } else {
              // setOffer("")
              setLikesLoading(false);
              // setLoading(false);
            }
          })
          .catch((error) => {
            // setLoading(false);
            console.log(error);
            setLikesLoading(false);
            // console.log(error);
          });
      } else if(!loading && !likesLoading && allLikes != undefined && allLikes.filter((e) => e.offerId == newValue &&e.activityType == "like").length>0) {
        const offerId =
          allLikes !== undefined &&
          allLikes.filter((e) => e.offerId === newValue)[0].id;
        API.makeUnLikes(userInfo?.email, newValue, "offer", offerId)
          .then((result) => {
            if (result !== undefined) {
              // setOffer(result1)
              // totalLikes = totalLikes - 1;
              // setLikes(totalLikes);
              dispatch(getUserSnips(userInfo?.email, "like"));
              setLikesLoading(false);
              // setLoading(false);
              API.getOffersById(userInfo?.email, newValue)
                .then((result1) => {
                  if (result1 != undefined) {
                    setOffer(result1);
                    setLikes(result1.totalLikes);
                    setLiked(false);
                    // setLoading(false);
                  } else {
                    setOffer("");
                    // setLoading(false);
                  }
                })
                .catch((error) => {
                  // setLoading(false);
                  console.log(error);
                  // console.log(error);
                });
            } else {
              // setOffer("")
              setLikesLoading(false);
              // setLoading(false);
            }
          })
          .catch((error) => {
            // setLoading(false);
            console.log(error);
            setLikesLoading(false);
          });
        // alert("already liked this offer thanks.")
        // setLikesLoading(false);
      }
    }
    // console.log("Under Development..............")
  };

  const onImageCilck = (index) => {
    setOpen(!open);
    setKey(index);
  };

  const imageGallery = (items, index) => {
    return items.map((item) => (
      <div className="ml-4 gallery-items" onClick={() => onImageCilck(index)}>
        {item.embedUrl ? (
          <ReactPlayer
            playIcon
            width="100px"
            height="50px"
            url={item.embedUrl}
          />
        ) : (
          <img style={{ width: "50px", height: "50px" }} src={item.thumbnail} />
        )}
      </div>
    ));
  };

  const toUpperCaseFilter = (d) => {
    return d.toUpperCase();
  };

  const handleBuy = (vendorUrl, id) => {
    API.offerBuyNow(userInfo?.email, id);
    window.location.replace(vendorUrl);
  };
  SwiperCore.use([Navigation, Pagination]);
  // const shareBlockProps = {
  //   url: `https://shopiads.ca/offers/tab/${offer?.id}`,
  //   button: ShareButtonCircle,
  //   buttons: [
  //     { network: "Twitter", icon: FaTwitter },
  //     { network: "Facebook", icon: FaFacebook },
  //     // { network: "GooglePlus", icon: FaGooglePlus },
  //     // { network: "Email", icon: FaEnvelope },
  //     {
  //       network: "Pinterest",
  //       icon: FaPinterest,
  //       media:
  //         "https://raw.githubusercontent.com/greglobinski/react-custom-share/master/static/react-custom-share.gif"
  //     },
  //     { network: "Linkedin", icon: FaLinkedin }
  //   ],
  //   text: "Give it a try - react-custom-share component",
  //   longtext:
  //     "Social sharing buttons for React. Use one of the build-in themes or create a custom one from the scratch."
  // };

  const followStore = (newValue) => {
    if (!userInfo?.email) setLoginForm(true);
    else
    {
    API.followStore(userInfo?.email, newValue, "follow")
      .then((result) => {
        if (result != undefined) {
          setDisabled(!disabled);
        }
      })
      .catch((error) => {
        // setLoading(false);
        console.log(error);
      });
    }
  };

  
const openDiscountPage = () => {
  setDiscountForm(true)
};

  return (
    <>
      <div className="container-fluid mt-5">
        <meta property="og:image" content={offer?.fullImage} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="200" />
        <meta property="og:image:height" content="200"></meta>
        <div className="row">
          <div className="col-md-10 mx-auto">
            {/*  <div className="row offer-image-container">*/}
            <div className="row">
              <div className="col-md-6">
                <i
                  style={{ ...style.backButton, position: "relative" }}
                  className="fa fa-arrow-circle-left"
                  aria-hidden="true"
                  title="Back to Login"
                  onClick={() => history.goBack()}
                ></i>
                <img
                  src={offer?.fullImage}
                  className="w-100 offerImage offerViewCard"
                />
                {/* <p className="mt-3">{offer?.description}</p> */}
                <div className="d-flex align-items-center mt-4">
                  <div>Share</div>
                  <div className="socail-icons ml-3">
                    {/* <ShareBlockStandard {...shareBlockProps} /> */}
                    <FacebookShareButton
                      url={
                        localStorage.getItem("connectSite") === "CA"
                          ? `https://${environment.CANADA_BACKEND_URL}/Shopiads/shareOffer/${offer?.id}`
                          : `https://${environment.USA_BACKEND_URL}/Shopiads/shareOffer/${offer?.id}`
                      }
                      quote={offer?.subHeading}
                      // hashtag="#offer"
                      windowWidth={500}
                      windowHeight={300}
                    >
                      <FacebookIcon
                        size={28}
                        logoFillColor="white"
                        round={true}
                      ></FacebookIcon>
                    </FacebookShareButton>
                    <FacebookMessengerShareButton
                      appId="6833289606700416"
                      url={
                        localStorage.getItem("connectSite") == "CA"
                          ? `${environment.CANADA_BASE_URL}/Shopiads/shareOffer/${offer?.id}`
                          : `${environment.USA_BASE_URL}/Shopiads/shareOffer/${offer?.id}`
                      }
                    >
                      <FacebookMessengerIcon
                        size={28}
                        logoFillColor="white"
                        round={true}
                      ></FacebookMessengerIcon>
                    </FacebookMessengerShareButton>
                    <TwitterShareButton
                      title={offer?.heading}
                      url={
                        localStorage.getItem("connectSite") == "CA"
                          ? `${environment.CANADA_BASE_URL}/Shopiads/shareOffer/${offer?.id}`
                          : `${environment.USA_BASE_URL}/Shopiads/shareOffer/${offer?.id}`
                      }
                    >
                      <TwitterIcon size={28} round={true} />
                    </TwitterShareButton>
                    <WhatsappShareButton
                      title={offer?.heading}
                      url={
                        localStorage.getItem("connectSite") == "CA"
                          ? `${environment.CANADA_BASE_URL}/Shopiads/shareOffer/${offer?.id}`
                          : `${environment.USA_BASE_URL}/Shopiads/shareOffer/${offer?.id}`
                      }
                    >
                      <WhatsappIcon size={28} round={true} />
                    </WhatsappShareButton>

                    {/* <PinterestShareButton
                      url={`https://shopiads.ca/offers/tab/${offer?.id}`}
                      description={offer?.subHeading}
                      media={`${offer?.fullImage}`}
                    >
                      <PinterestIcon
                        size={28}
                        logoFillColor="white"
                        round={true}
                      ></PinterestIcon>
                    </PinterestShareButton> */}
                    {/* <button className="bg-white border-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        width="25px"
                        height="25px"
                      >
                        <path
                          fill="#448AFF"
                          d="M24,4C13.5,4,5,12.1,5,22c0,5.2,2.3,9.8,6,13.1V44l7.8-4.7c1.6,0.4,3.4,0.7,5.2,0.7c10.5,0,19-8.1,19-18C43,12.1,34.5,4,24,4z"
                        />
                        <path
                          fill="#FFF"
                          d="M12 28L22 17 27 22 36 17 26 28 21 23z"
                        />
                      </svg>
                    </button>
                    <button className="bg-white border-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        width="25px"
                        height="25px"
                      >
                        <path
                          fill="#039be5"
                          d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"
                        />
                        <path
                          fill="#fff"
                          d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"
                        />
                      </svg>
                    </button>
                    <button className="bg-white border-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        width="25px"
                        height="25px"
                      >
                        <circle cx="24" cy="24" r="20" fill="#E60023" />
                        <path
                          fill="#FFF"
                          d="M24.4439087,11.4161377c-8.6323242,0-13.2153931,5.7946167-13.2153931,12.1030884	c0,2.9338379,1.5615234,6.5853882,4.0599976,7.7484131c0.378418,0.1762085,0.581543,0.1000366,0.668457-0.2669067	c0.0668945-0.2784424,0.4038086-1.6369019,0.5553589-2.2684326c0.0484619-0.2015381,0.0246582-0.3746338-0.1384277-0.5731201	c-0.8269653-1.0030518-1.4884644-2.8461304-1.4884644-4.5645752c0-4.4115601,3.3399658-8.6799927,9.0299683-8.6799927	c4.9130859,0,8.3530884,3.3484497,8.3530884,8.1369019c0,5.4099731-2.7322998,9.1584473-6.2869263,9.1584473	c-1.9630737,0-3.4330444-1.6238403-2.9615479-3.6153564c0.5654297-2.3769531,1.6569214-4.9415283,1.6569214-6.6584473	c0-1.5354004-0.8230591-2.8169556-2.5299683-2.8169556c-2.006958,0-3.6184692,2.0753784-3.6184692,4.8569336	c0,1.7700195,0.5984497,2.9684448,0.5984497,2.9684448s-1.9822998,8.3815308-2.3453979,9.9415283	c-0.4019775,1.72229-0.2453003,4.1416016-0.0713501,5.7233887l0,0c0.4511108,0.1768799,0.9024048,0.3537598,1.3687744,0.4981079l0,0	c0.8168945-1.3278198,2.0349731-3.5056763,2.4864502-5.2422485c0.2438354-0.9361572,1.2468872-4.7546387,1.2468872-4.7546387	c0.6515503,1.2438965,2.5561523,2.296936,4.5831299,2.296936c6.0314941,0,10.378418-5.546936,10.378418-12.4400024	C36.7738647,16.3591919,31.3823242,11.4161377,24.4439087,11.4161377z"
                        />
                      </svg>
                    </button>
                    <button className="bg-white border-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        width="25px"
                        height="25px"
                      >
                        <path
                          fill="#03a9f4"
                          d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z"
                        />
                        <path
                          fill="#fff"
                          d="M36,17.12c-0.882,0.391-1.999,0.758-3,0.88c1.018-0.604,2.633-1.862,3-3 c-0.951,0.559-2.671,1.156-3.793,1.372C31.311,15.422,30.033,15,28.617,15C25.897,15,24,17.305,24,20v2c-4,0-7.9-3.047-10.327-6 c-0.427,0.721-0.667,1.565-0.667,2.457c0,1.819,1.671,3.665,2.994,4.543c-0.807-0.025-2.335-0.641-3-1c0,0.016,0,0.036,0,0.057 c0,2.367,1.661,3.974,3.912,4.422C16.501,26.592,16,27,14.072,27c0.626,1.935,3.773,2.958,5.928,3c-1.686,1.307-4.692,2-7,2 c-0.399,0-0.615,0.022-1-0.023C14.178,33.357,17.22,34,20,34c9.057,0,14-6.918,14-13.37c0-0.212-0.007-0.922-0.018-1.13 C34.95,18.818,35.342,18.104,36,17.12"
                        />
                      </svg>
                    </button> */}
                  </div>
                  {liked ? (
                    <div
                      onClick={() => {
                        makeLikes(offer?.id);
                      }}
                      style={{ cursor: "pointer" }}
                      class="fav ml-5 d-flex align-items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16.69"
                        viewBox="0 0 28 25.69"
                      >
                        <path
                          id="ic_favorite_border_24px"
                          d="M22.3,3A8.384,8.384,0,0,0,16,5.926,8.384,8.384,0,0,0,9.7,3,7.625,7.625,0,0,0,2,10.7c0,5.292,4.76,9.6,11.97,16.156L16,28.69l2.03-1.848C25.24,20.3,30,15.992,30,10.7A7.625,7.625,0,0,0,22.3,3ZM16.14,24.77l-.14.14-.14-.14c-6.664-6.034.14,4.046.14,0,0-2.8-2.94,0-.14,0,2.156,0-1.9-17.584-1.162-15.666L16.14,25.577c.728-1.918,5.72-6.446,7.876-6.446,2.8,0,2.743-10.627,2.743-7.827,0,3.395-3.578,6.217-8.729,10.927C17.422,22.788,17.213,23.8,16.14,24.77Z"
                          transform="translate(-2 -3)"
                          fill={COLORS.primary.main}
                        />
                      </svg>
                      <div className="rating">
                        Likes (
                        {Intl.NumberFormat("en-US", {
                          notation: "compact",
                          maximumFractionDigits: 1,
                        }).format(likes)}
                        )
                      </div>
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        makeLikes(offer?.id);
                      }}
                      style={{ cursor: "pointer" }}
                      class="fav ml-5 d-flex align-items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill={COLORS.primary.main}
                        className="bi bi-heart"
                        viewBox="0 0 16 16"
                      >
                        <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"></path>
                      </svg>
                      <div className="rating">
                        {" "}
                        Likes (
                        {Intl.NumberFormat("en-US", {
                          notation: "compact",
                          maximumFractionDigits: 1,
                        }).format(likes)}
                        )
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="offerInfoContent mt-4">
                  <div className="offerInfoDetails">
                    <h1>{offer?.heading}</h1>
                    <p>{offer?.subHeading}</p>
                    {/* <div className="d-flex align-items-center mt-2">
                      <div className="rat">
                        {offer?.avgRating != undefined &&
                        Number(offer?.avgRating) > 0
                          ? offer?.avgRating
                          : 0}{" "}
                        Rating
                      </div>
                      <div className="rating-star ml-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#ffc902"
                          class={
                            offer?.avgRating != undefined &&
                            Number(offer?.avgRating) > 0
                              ? "bi bi-star-fill"
                              : "bi bi-star"
                          }
                          viewBox="0 0 16 16"
                        >
                          {offer?.avgRating != undefined &&
                          Number(offer?.avgRating) > 0 ? (
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          ) : (
                            <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                          )}
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#ffc902"
                          class={
                            offer?.avgRating != undefined &&
                            Number(offer?.avgRating) > 1
                              ? "bi bi-star-fill"
                              : "bi bi-star"
                          }
                          viewBox="0 0 16 16"
                        >
                          {offer?.avgRating != undefined &&
                          Number(offer?.avgRating) > 1 ? (
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          ) : (
                            <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                          )}
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#ffc902"
                          class={
                            offer?.avgRating != undefined &&
                            Number(offer?.avgRating) > 2
                              ? "bi bi-star-fill"
                              : "bi bi-star"
                          }
                          viewBox="0 0 16 16"
                        >
                          {offer?.avgRating != undefined &&
                          Number(offer?.avgRating) > 2 ? (
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          ) : (
                            <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                          )}
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#ffc902"
                          class={
                            offer?.avgRating != undefined &&
                            Number(offer?.avgRating) > 3
                              ? "bi bi-star-fill"
                              : "bi bi-star"
                          }
                          viewBox="0 0 16 16"
                        >
                          {offer?.avgRating != undefined &&
                          Number(offer?.avgRating) > 3 ? (
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          ) : (
                            <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                          )}
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#ffc902"
                          class={
                            offer?.avgRating != undefined &&
                            Number(offer?.avgRating) > 4
                              ? "bi bi-star-fill"
                              : "bi bi-star"
                          }
                          viewBox="0 0 16 16"
                        >
                          {offer?.avgRating != undefined &&
                          Number(offer?.avgRating) > 4 ? (
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          ) : (
                            <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                          )}
                        </svg>
                      </div>
                      {rated ? (
                        <Button
                          variant="contained"
                          disabled
                          // color="primary"
                          className="ml-4"
                          onClick={() => ratingClick(offer?.id, offer?.storeId)}
                          // style={{ width: "250px" }}
                        >
                          Rate Offer
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          className="ml-4"
                          onClick={() => ratingClick(offer?.id, offer?.storeId)}
                          // style={{ width: "250px" }}
                        >
                          Rate Offer
                        </Button>
                      )}
                      </div>*/}
                  </div>
                  <div className="grid">
                    <div className="row pl-3">
                      {/* <a
                        href={offer?.vendorUrl}
                        className="col-md2 ml-2"
                        target="_new"
                      > */}
                      {offer?.isShopifyCollection != "Y" ? (
                        <Button
                          variant="contained"
                          color="primary"
                          className="col-md2"
                          startIcon={<ListAlt />}
                          // style={{ width: "250px" }}

                          onClick={() => handleBuy(offer?.vendorUrl, offer?.id)}
                        >
                          Shop Now
                        </Button>
                      ) : (
                        ""
                      )}
                      {/* </a> */}
                      {snipped ? (
                        <Button
                          variant="contained"
                          className="ml-4"
                          disabled
                          startIcon={<ListAlt />}
                          // style={{ width: "250px" }}
                          onClick={() => handleSnip(offer)}
                        >
                          Add to List
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          className="ml-4"
                          startIcon={<ListAlt />}
                          // style={{ width: "250px" }}
                          onClick={() => handleSnip(offer)}
                        >
                          Add to List
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className=" col-md-10 mx-auto product-detail bg-white">
            <div class="section-title ">Offer Description</div>
            <div className="product-description my-4 px-2">
              {offer?.description && (
                <span
                  className="image-gallery-description"
                  style={{ right: "0", left: "initial" }}
                >
                  {renderHTML(offer?.description)}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className=" col-md-10 mx-auto product-detail bg-white">
            <div class="section-title ">Related Products</div>
          </div>
          <div className="col-md-10 mx-auto mt-4">
            <div className="row">
              {offerProduct.map((item, index) => (
                <div className="col-md-3 mb-3">
                  <div style={{ cursor: "pointer" }}>
                    <ProductCard
                      branchName={item.storeName}
                      storeId={item.storeId}
                      productId={item.id}
                      img={item.images}
                      branchLogo={item.logo}
                      heading={item.heading}
                      avgRating={item.avgRating}
                      discount={item.discountPerc}
                      actualPrice={item.actualPrice}
                      offerPrice={item.offerPrice}
                      totalLikes={item.totalLikes}
                      key={item.id}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row bg-white py-4 border-bottom">
          <div className="col-md-10 mx-auto">
            <div className="row seller-overview">
              <div className="col-md-5">
                <div>
                  <div className="d-flex">
                    <img
                      className="shopee-avatar__img mt-2"
                      src={
                        storeData.logoMedium != undefined &&
                        storeData.logoMedium != null
                          ? storeData.logoMedium
                          : "https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg"
                      }
                    />
                    <div className="section-seller-portrait-info">
                      <h1 className="section-seller-portrait-name">
                        {storeData.storeName}{" "}
                        {storeData.code !== "Primary"
                          ? `Branch: ${storeData.code}`
                          : ""}
                      </h1>
                      {/*<div className="section-seller-active-time"><strong>Branch: </strong>{storeData.code}</div>
                       */}
                      <div className="section-seller-portrait-status">
                        <div className="section-seller-active-time">
                          {storeData.address2}, Unit:
                          {storeData.address1}, {storeData.cityId} -{" "}
                          {storeData.pincode}
                          {/* {storeData.address1} {storeData.address2},
                          {storeData.cityId} - {storeData.pincode} */}
                        </div>
                        {/*<div className="section-seller-active-time">Active 7 minutes ago</div>
                         */}
                      </div>
                    </div>
                  </div>
                  <div className="section-seller-overview-buttons">
                    <a className="section-seller-button">
                      <button
                        className={
                          disabled
                            ? "shopee-button-outline-follow"
                            : "shopee-button-outline"
                        }
                        disabled={disabled}
                        onClick={() => followStore(storeData.id)}
                      >
                        {disabled ? (
                          ""
                        ) : (
                          <span className="section-seller-overview-icon">
                            <svg
                              enable-background="new 0 0 10 10"
                              viewBox="0 0 10 10"
                              x="0"
                              y="0"
                              className="shopee-svg-icon icon-plus-sign"
                            >
                              <polygon points="10 4.5 5.5 4.5 5.5 0 4.5 0 4.5 4.5 0 4.5 0 5.5 4.5 5.5 4.5 10 5.5 10 5.5 5.5 10 5.5"></polygon>
                            </svg>
                          </span>
                        )}
                        {disabled ? "Following" : "Follow"}
                      </button>
                    </a>
                    <a className="section-seller-button">
                      <button
                        onClick={() => viewStoreClick(storeData.id)}
                        className="shopee-button-outline"
                      >
                        <span className="section-seller-overview-icon">
                          <svg
                            enable-background="new 0 0 15 15"
                            viewBox="0 0 15 15"
                            x="0"
                            y="0"
                            stroke-width="1"
                            class="shopee-svg-icon"
                          >
                            <path d="m13 1.9c-.2-.5-.8-1-1.4-1h-8.4c-.6.1-1.2.5-1.4 1l-1.4 4.3c0 .8.3 1.6.9 2.1v4.8c0 .6.5 1 1.1 1h10.2c.6 0 1.1-.5 1.1-1v-4.6c.6-.4.9-1.2.9-2.3zm-11.4 3.4 1-3c .1-.2.4-.4.6-.4h8.3c.3 0 .5.2.6.4l1 3zm .6 3.5h.4c.7 0 1.4-.3 1.8-.8.4.5.9.8 1.5.8.7 0 1.3-.5 1.5-.8.2.3.8.8 1.5.8.6 0 1.1-.3 1.5-.8.4.5 1.1.8 1.7.8h.4v3.9c0 .1 0 .2-.1.3s-.2.1-.3.1h-9.5c-.1 0-.2 0-.3-.1s-.1-.2-.1-.3zm8.8-1.7h-1v .1s0 .3-.2.6c-.2.1-.5.2-.9.2-.3 0-.6-.1-.8-.3-.2-.3-.2-.6-.2-.6v-.1h-1v .1s0 .3-.2.5c-.2.3-.5.4-.8.4-1 0-1-.8-1-.8h-1c0 .8-.7.8-1.3.8s-1.1-1-1.2-1.7h12.1c0 .2-.1.9-.5 1.4-.2.2-.5.3-.8.3-1.2 0-1.2-.8-1.2-.9z"></path>
                          </svg>
                        </span>
                        View Store
                      </button>
                    </a>
                  </div>
                  <div className="section-seller-overview-buttons">
                    <a className="section-seller-button">
                      <button
                        className="shopee-button-outline"
                        onClick={() => openDiscountPage()}
                      >
                        <span className="section-seller-overview-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-info-square"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                          </svg>
                        </span>
                        Additional Discounts
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="section-info-list">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-7 pr-0">
                        <div className="section-seller-details">
                          <div className="section-item-icon-wrapper">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              class="bi bi-building shopee-svg-icon"
                              stroke-width="0"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694 1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
                              />
                              <path d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z" />
                            </svg>
                          </div>
                          <div className="section-seller-item-text">
                            <div className="section-seller-item-text-name">
                              Incorporated As:&nbsp;
                            </div>
                            <div className="section-seller-item-text-value">
                              {companyData.regName}
                            </div>
                          </div>
                        </div>
                        <div className="section-seller-details">
                          <div className="section-item-icon-wrapper">
                            <svg
                              enable-background="new 0 0 15 15"
                              viewBox="0 0 15 15"
                              x="0"
                              y="0"
                              stroke-width="0"
                              className="shopee-svg-icon"
                            >
                              <path d="m13 1.9c-.2-.5-.8-1-1.4-1h-8.4c-.6.1-1.2.5-1.4 1l-1.4 4.3c0 .8.3 1.6.9 2.1v4.8c0 .6.5 1 1.1 1h10.2c.6 0 1.1-.5 1.1-1v-4.6c.6-.4.9-1.2.9-2.3zm-11.4 3.4 1-3c .1-.2.4-.4.6-.4h8.3c.3 0 .5.2.6.4l1 3zm .6 3.5h.4c.7 0 1.4-.3 1.8-.8.4.5.9.8 1.5.8.7 0 1.3-.5 1.5-.8.2.3.8.8 1.5.8.6 0 1.1-.3 1.5-.8.4.5 1.1.8 1.7.8h.4v3.9c0 .1 0 .2-.1.3s-.2.1-.3.1h-9.5c-.1 0-.2 0-.3-.1s-.1-.2-.1-.3zm8.8-1.7h-1v .1s0 .3-.2.6c-.2.1-.5.2-.9.2-.3 0-.6-.1-.8-.3-.2-.3-.2-.6-.2-.6v-.1h-1v .1s0 .3-.2.5c-.2.3-.5.4-.8.4-1 0-1-.8-1-.8h-1c0 .8-.7.8-1.3.8s-1.1-1-1.2-1.7h12.1c0 .2-.1.9-.5 1.4-.2.2-.5.3-.8.3-1.2 0-1.2-.8-1.2-.9z"></path>
                            </svg>
                          </div>
                          <div className="section-seller-item-text">
                            <div className="section-seller-item-text-name">
                              Presence:&nbsp;
                            </div>
                            <div className="section-seller-item-text-value">
                              {storeData.isphysical == "Y"
                                ? "Online & Offline"
                                : "Offline"}
                            </div>
                          </div>
                        </div>
                        <div className="section-seller-details">
                          <div className="section-item-icon-wrapper">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="bi bi-truck shopee-svg-icon"
                              viewBox="0 0 16 16"
                              stroke-width="0"
                            >
                              <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                            </svg>
                          </div>
                          <div className="section-seller-item-text">
                            <div className="section-seller-item-text-name">
                              Delivery:&nbsp;
                            </div>
                            <div className="section-seller-item-text-value">
                              {storeData.deliveryNotes != undefined &&
                              storeData.deliveryNotes != ""
                                ? "Free Shipping on orders over $" +
                                  storeData.deliveryNotes
                                : storeData.deliveryType != "" &&
                                  storeData.secondDeliveryType != "" &&
                                  storeData.secondDeliveryType != undefined
                                ? storeData.deliveryType +
                                  "/" +
                                  storeData.secondDeliveryType
                                : storeData.deliveryType != ""
                                ? storeData.deliveryType
                                : storeData.secondDeliveryType != "" &&
                                  storeData.secondDeliveryType != undefined
                                ? storeData.secondDeliveryType
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 pr-0">
                        <div className="section-seller-details">
                          <div className="section-item-icon-wrapper">
                            <svg
                              enable-background="new 0 0 15 15"
                              viewBox="0 0 15 15"
                              x="0"
                              y="0"
                              className="shopee-svg-icon"
                            >
                              <g>
                                <circle
                                  cx="5.5"
                                  cy="5"
                                  fill="none"
                                  r="4"
                                  strokeMiterlimit="10"
                                ></circle>
                                <path
                                  d="m8.4 7.5c.7 0 1.1.7 1.1 1.6v4.9h-8v-4.9c0-.9.4-1.6 1.1-1.6"
                                  fill="none"
                                  stroke-linejoin="round"
                                  strokeMiterlimit="10"
                                ></path>
                                <path
                                  d="m12.6 6.9c.7 0 .9.6.9 1.2v5.7h-2"
                                  fill="none"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  strokeMiterlimit="10"
                                ></path>
                                <path
                                  d="m9.5 1.2c1.9 0 3.5 1.6 3.5 3.5 0 1.4-.9 2.7-2.1 3.2"
                                  fill="none"
                                  stroke-linecap="round"
                                  strokeMiterlimit="10"
                                ></path>
                              </g>
                            </svg>
                          </div>
                          <div className="section-seller-item-text">
                            <div className="section-seller-item-text-name">
                              Followers:&nbsp;
                            </div>
                            <div className="section-seller-item-text-value">
                              {followers}
                            </div>
                          </div>
                        </div>
                        <div className="section-seller-details">
                          <div className="section-item-icon-wrapper">
                            <svg
                              enable-background="new 0 0 15 15"
                              viewBox="0 0 15 15"
                              x="0"
                              y="0"
                              className="shopee-svg-icon icon-rating"
                            >
                              <polygon
                                fill="none"
                                points="7.5 .8 9.7 5.4 14.5 5.9 10.7 9.1 11.8 14.2 7.5 11.6 3.2 14.2 4.3 9.1 .5 5.9 5.3 5.4"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                strokeMiterlimit="10"
                              ></polygon>
                            </svg>
                          </div>
                          <div className="section-seller-item-text">
                            <div className="section-seller-item-text-name">
                              rating:&nbsp;
                            </div>
                            <div className="section-seller-item-text-value">
                              {storeData.avgRating}
                            </div>
                          </div>
                        </div>
                        <div className="section-seller-details">
                          <div className="section-item-icon-wrapper">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="bi bi-stopwatch shopee-svg-icon"
                              viewBox="0 0 16 16"
                              stroke-width="0"
                            >
                              <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z" />
                              <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z" />
                            </svg>
                          </div>
                          <div className="section-seller-item-text">
                            <div className="section-seller-item-text-name">
                              Timing:&nbsp;
                            </div>
                            <div className="section-seller-item-text-value">
                              <Moment format="hh:mm A">
                                {storeData.fromHrs != undefined &&
                                storeData.fromHrs != null &&
                                storeData.fromHrs.length > 0
                                  ? "1970-01-01T" +
                                    (
                                      "0" + storeData.fromHrs[0].split(":")[0]
                                    ).slice(-2) +
                                    ":" +
                                    (
                                      "0" + storeData.fromHrs[0].split(":")[1]
                                    ).slice(-2) +
                                    ":" +
                                    (
                                      "0" + storeData.fromHrs[0].split(":")[2]
                                    ).slice(-2)
                                  : ""}
                              </Moment>{" "}
                              -{" "}
                              <Moment format="hh:mm A">
                                {storeData.toHrs != undefined &&
                                storeData.toHrs != null &&
                                storeData.toHrs.length > 0
                                  ? "1970-01-01T" +
                                    (
                                      "0" + storeData.toHrs[0].split(":")[0]
                                    ).slice(-2) +
                                    ":" +
                                    (
                                      "0" + storeData.toHrs[0].split(":")[1]
                                    ).slice(-2) +
                                    ":" +
                                    (
                                      "0" + storeData.toHrs[0].split(":")[2]
                                    ).slice(-2)
                                  : ""}
                              </Moment>
                              <Tooltip
                                placement="bottom"
                                color={"white"}
                                title={
                                  <React.Fragment>
                                    <li style={{ color: "gray" }}>
                                      ● {storeData.timeNote}
                                    </li>
                                    <li style={{ color: "Red" }}>
                                      ● {`Closed : ${storeData.weeklyOff}`}
                                    </li>
                                  </React.Fragment>
                                }
                              >
                                <i
                                  className="fa fa-info-circle ml-2"
                                  style={style.tooltipIcon}
                                ></i>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="row">
          <div>
            <div className=" col-md-10 mx-auto mb-3 bg-white">
              <div class="title-m">Offer Ratings</div>
              <div className="bg-light row mx-0 p-4 border">
                <div className="col-auto">
                  <div className="product-raitng-score">
                    <div>
                      <span className="rating-score">
                        {offer?.avgRating !== undefined &&
                        Number(offer?.avgRating) > 0
                          ? offer?.avgRating
                          : 0}
                      </span>{" "}
                      <span className="rating-score-out-of">out of 5</span>
                    </div>
                    <div class="rating-star mt-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#ffc902"
                        class={
                          offer?.avgRating != undefined &&
                          Number(offer?.avgRating) > 0
                            ? "bi bi-star-fill"
                            : "bi bi-star"
                        }
                        viewBox="0 0 16 16"
                      >
                        {offer?.avgRating != undefined &&
                        Number(offer?.avgRating) > 0 ? (
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        ) : (
                          <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                        )}
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#ffc902"
                        class={
                          offer?.avgRating != undefined &&
                          Number(offer?.avgRating) > 1
                            ? "bi bi-star-fill"
                            : "bi bi-star"
                        }
                        viewBox="0 0 16 16"
                      >
                        {offer?.avgRating != undefined &&
                        Number(offer?.avgRating) > 1 ? (
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        ) : (
                          <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                        )}
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#ffc902"
                        class={
                          offer?.avgRating != undefined &&
                          Number(offer?.avgRating) > 2
                            ? "bi bi-star-fill"
                            : "bi bi-star"
                        }
                        viewBox="0 0 16 16"
                      >
                        {offer?.avgRating != undefined &&
                        Number(offer?.avgRating) > 2 ? (
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        ) : (
                          <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                        )}
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#ffc902"
                        class={
                          offer?.avgRating != undefined &&
                          Number(offer?.avgRating) > 3
                            ? "bi bi-star-fill"
                            : "bi bi-star"
                        }
                        viewBox="0 0 16 16"
                      >
                        {offer?.avgRating != undefined &&
                        Number(offer?.avgRating) > 3 ? (
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        ) : (
                          <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                        )}
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#ffc902"
                        class={
                          offer?.avgRating != undefined &&
                          Number(offer?.avgRating) > 4
                            ? "bi bi-star-fill"
                            : "bi bi-star"
                        }
                        viewBox="0 0 16 16"
                      >
                        {offer?.avgRating != undefined &&
                        Number(offer?.avgRating) > 4 ? (
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        ) : (
                          <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                        )}
                      </svg>
                    </div>
                  </div>
                </div>

               
                <div className="col-md-10">
                  <Button
                    onClick={() => filterClick("all")}
                    variant={activeFilter == "all" ? "contained" : "outlined"}
                    color="primary"
                    className="mr-2 mb-2"
                  >
                    all
                  </Button>
                  <Button
                    onClick={() => filterClick("5")}
                    variant={activeFilter == "5" ? "contained" : "outlined"}
                    color="primary"
                    className="mr-2 mb-2"
                  >
                    5 star ({ratingFilterCount5})
                  </Button>
                  <Button
                    onClick={() => filterClick("4")}
                    variant={activeFilter == "4" ? "contained" : "outlined"}
                    color="primary"
                    className="mr-2 mb-2"
                  >
                    4 star ({ratingFilterCount4})
                  </Button>
                  <Button
                    onClick={() => filterClick("3")}
                    variant={activeFilter == "3" ? "contained" : "outlined"}
                    color="primary"
                    className="mr-2 mb-2"
                  >
                    3 star ({ratingFilterCount3})
                  </Button>
                  <Button
                    onClick={() => filterClick("2")}
                    variant={activeFilter == "2" ? "contained" : "outlined"}
                    color="primary"
                    className="mr-2 mb-2"
                  >
                    2 star ({ratingFilterCount2})
                  </Button>
                  <Button
                    onClick={() => filterClick("1")}
                    variant={activeFilter == "1" ? "contained" : "outlined"}
                    color="primary"
                    className="mr-2 mb-2"
                  >
                    1 star ({ratingFilterCount1})
                  </Button>
                  <Button
                    onClick={() => filterClick("c")}
                    variant={activeFilter == "c" ? "contained" : "outlined"}
                    color="primary"
                    className="mr-2 mb-2"
                  >
                    with comments ({ratingFilterCountC})
                  </Button>
                  <Button
                    onClick={() => filterClick("m")}
                    variant={activeFilter == "m" ? "contained" : "outlined"}
                    color="primary"
                    className="mr-2 mb-2"
                  >
                    With Media ({ratingFilterCountM})
                  </Button>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="row align-items-center">
                    <div className="col-auto pr-0">
                     
                      <img
                        src="https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/Verified_Image.png"
                        width="60"
                      ></img>
                    </div>
                    <div className="col-auto">
                     
                      <p className="mb-0">
                        Reviewer needs to upload the bill where required for Authenticity!
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {offerRating.map((item, index) => (
                <div className="rating-list mt-4">
                  <ul class="list-unstyled">
                    <li class="media">
                      <div class="shopee-avatar__placeholder">
                        <svg
                          enable-background="new 0 0 15 15"
                          viewBox="0 0 15 15"
                          x="0"
                          y="0"
                          class="shopee-svg-icon icon-headshot"
                        >
                          <g>
                            <circle
                              cx="7.5"
                              cy="4.5"
                              fill="none"
                              r="3.8"
                              stroke-miterlimit="10"
                            ></circle>
                            <path
                              d="m1.5 14.2c0-3.3 2.7-6 6-6s6 2.7 6 6"
                              fill="none"
                              stroke-linecap="round"
                              stroke-miterlimit="10"
                            ></path>
                          </g>
                        </svg>
                      </div>
                      <div class="media-body">
                        <small class="mt-0 mb-1">{item.userName}</small>
                        <div class="rating-star mt-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#ffc902"
                            class={
                              item?.rating !== undefined &&
                              parseInt(item?.rating) >= 1
                                ? "bi bi-star-fill"
                                : "bi bi-star"
                            }
                            viewBox="0 0 16 16"
                          >
                            {item?.rating != undefined &&
                            parseInt(item?.rating) >= 1 ? (
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            ) : (
                              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                            )}
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#ffc902"
                            class={
                              item?.rating !== undefined &&
                              parseInt(item?.rating) >= 2
                                ? "bi bi-star-fill ml-1"
                                : "bi bi-star"
                            }
                            viewBox="0 0 16 16"
                          >
                            {item?.rating != undefined &&
                            parseInt(item?.rating) >= 2 ? (
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            ) : (
                              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                            )}
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#ffc902"
                            class={
                              item?.rating !== undefined &&
                              parseInt(item?.rating) >= 3
                                ? "bi bi-star-fill ml-1"
                                : "bi bi-star"
                            }
                            viewBox="0 0 16 16"
                          >
                            {item?.rating != undefined &&
                            parseInt(item?.rating) >= 3 ? (
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            ) : (
                              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                            )}
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#ffc902"
                            class={
                              item?.rating !== undefined &&
                              parseInt(item?.rating) >= 4
                                ? "bi bi-star-fill ml-1"
                                : "bi bi-star"
                            }
                            viewBox="0 0 16 16"
                          >
                            {item?.rating != undefined &&
                            parseInt(item?.rating) >= 4 ? (
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            ) : (
                              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                            )}
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#ffc902"
                            class={
                              item?.rating !== undefined &&
                              parseInt(item?.rating) >= 5
                                ? "bi bi-star-fill ml-1"
                                : "bi bi-star"
                            }
                            viewBox="0 0 16 16"
                          >
                            {item?.rating != undefined &&
                            parseInt(item?.rating) >= 5 ? (
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            ) : (
                              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                            )}
                          </svg>
                        </div>
                        <p className="text-muted mb-1 small mt-3">
                          Reviewed on{" "}
                          <Moment
                            // tz="CST"
                            format="DD MMM YYYY"
                            // filter={toUpperCaseFilter(item.createdOn)}
                          >
                            {item.createdOn}
                          </Moment>
                        </p>
                        <b className="text-purchased mb-1">Verified Purchase</b>
                        <p className="small">{item.comment}</p>

                        {item.ratingMedia != null &&
                        item.ratingMedia != undefined &&
                        item.ratingMedia.length > 0 &&
                        mediaItem != undefined &&
                        mediaItem != null &&
                        mediaItem.length > 0 ? (
                          <div className="gallery">
                            {imageGallery(mediaItem[index], index)}
                            {
                              <Dialog
                                // onClick={this.handleClose}
                                aria-labelledby="customized-dialog-title"
                                open={open}
                              >
                                <MuiDialogTitle>
                                  <IconButton
                                    aria-label="close"
                                    className="close-icon"
                                    onClick={() => onImageCilck()}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </MuiDialogTitle>
                                <Swiper
                                  spaceBetween={20}
                                  slidesPerView={1}
                                  navigation
                                  loop={true}
                                  onSlideChange={(swiper) => {
                                    console.log("swiper");
                                    // handlePause(swiper.previousIndex);
                                  }}
                                  onSwiper={(swiper) => console.log(swiper)}
                                  className="customized-images"
                                  style={{
                                    position: "none !imporatnt",
                                  }}
                                >
                                  {mediaItem[key] !== undefined
                                    ? mediaItem[key].map((item, index) => (
                                        <SwiperSlide
                                          style={{
                                            width: "200px !important",
                                          }}
                                        >
                                          <div>
                                            
                                            <img
                                              alt="images"
                                              style={{
                                                // paddingLeft: "2%",
                                                width: "600px",
                                                height: "500px",
                                              }}
                                              src={item.original}
                                            />
                                            
                                          </div>
                                        </SwiperSlide>
                                      ))
                                    : ""}
                                </Swiper>
                              </Dialog>
                            }
                          </div>
                        ) : (
                          ""
                        )}
                        
                      </div>
                    </li>
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>*/}
      </div>
      {ratingForm && (
        <RatingForm
          handleOpen={handleOpenForm}
          handleClose={handleCloseForm}
          open={ratingForm}
          storeId={selectedStoreId}
          productId={""}
          offerId={selectedOfferId}
          type={"Offer"}
        />
      )}
      {loginForm && (
        <LoginForm
          handleOpen={handleLoginOpenForm}
          handleClose={handleLoginCloseForm}
          open={loginForm}
        />
      )}
      <ShopifyStoreDiscount
        handleOpen={handleOpenDiscountForm}
        handleClose={handleCloseDiscountForm}
        storeData={storeData}
        open={discountForm}
      />
      <ToastContainer />
    </>
  );
}

export default OffersDetail;
