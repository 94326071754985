import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch, Link,useHistory } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";

import Checkbox from '@material-ui/core/Checkbox';

import { makeStyles } from '@material-ui/core/styles';

import googlePlay from "../../Assets/google-play.png";
import appleStore from "../../Assets/app-store.png";

import MetaTags from 'react-meta-tags';
import { Helmet } from 'react-helmet';
import API from "../../Utils/API";
import environment from "../../Utils/env";
import { getDomainCountryCode } from "../../service/location";
import { CircularProgress } from "@material-ui/core";


const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

function ProductShare({ match }) {
  const [product, setProduct] = useState();
  const [version, setVersion] = useState(1);
  const [loading, setLoading] = useState(true);
  useEffect(() => {

    API.productById("gust", match.params.productId)
      .then((result1) => {
        console.log({ result1 });
        if (result1 !== undefined) {
          if (window.innerWidth > 768) {
            window.location.replace(
              `${
                getDomainCountryCode() === "CA"
                  ? environment.CANADA_BASE_URL
                  : environment.USA_BASE_URL
              }/productDetail/${result1.storeId}/${match.params.productId}`
            );
          }
          const randomVersion = Math.floor(
            Math.random() * (100000000 - 1 + 1) + 1
          );
          setLoading(false);
          setProduct(result1);
          setVersion(randomVersion);
        }
      })
      .catch((error) => {});
    const randomVersion = Math.floor(Math.random() * (100000000 - 1 + 1) + 1);
    console.log("share product randomVersion>>>>>>>>>>>>",randomVersion)
    setVersion(randomVersion);

  }, [match.params.productId]);

  if (loading) {
    return (
      <div
        style={{
          flex: 1,
          minHeight: window.innerHeight * 0.9,
          display: "flex",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <div className="container-fluid my-5 pt-5">
      <Helmet>
      
      <title>{product?.heading}</title>
      <link id="favicon" rel="icon" type="image/x-icon" href={
            product?.images != undefined &&
            product?.images != "" &&
            product?.images != null &&
            product?.images.length > 0 
              ? product?.images[0]+"?v="+version+"&amp;width=640"
              : "https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg"
          +"?v="+version+"&amp;width=640"} />
      <meta
      name="description"
      content={"$"+product?.offerPrice}
    />
            <meta name="og-title" property="og:title" content={product?.heading}/>
              <meta name="og:description" property="og:description" content={product?.offerPrice}/>
              <meta  name="og:image" property="og:image" content={
            product?.images != undefined &&
            product?.images != "" &&
            product?.images != null &&
            product?.images.length > 0 
              ? product?.images[0]
              : "https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg"
          +"?v="+version+"&amp;width=640"} />
              <meta name="og:url" property="og:url" content={environment.CANADA_BASE_URL+"/productShare/"+product?.id} />
              <meta name="twitter:card" property="twitter:card" content="summary_large_image" />
              <meta id="twitter:site" property="twitter:site" content="@ShopiAds" />
              <meta id="twitter:title" property="twitter:title" content={product?.heading} />
              <meta id="twitter:description"  property="twitter:description" content={product?.offerPrice} />
              <meta id="twitter:image" property="twitter:image" content={
            product?.images != undefined &&
            product?.images != "" &&
            product?.images != null &&
            product?.images.length > 0 
              ? product?.images[0]
              : "https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg"
          +"?v="+version+"&amp;width=640"}/>
              </Helmet>

       <div className="row">
          <div className="col-md-12">
          <div class="vertical-stack product" data-page-type="universal-link-product">
    
<div class="product-card">
   {/* <div class="product-card__merchant-logo">
      <img class="product-card__merchant-logo__img" alt="Logo of product" src={
            product?.images != undefined &&
            product?.images != "" &&
            product?.images != null &&
            product?.images.length > 0 
              ? product?.images[0]
              : "https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg"
          +"?v="+version+"&amp;width=640"}/>
      </div>*/}

    <div class="product-card__image-wrapper">
      <img class="product-card__image" src={
            product?.images != undefined &&
            product?.images != "" &&
            product?.images != null &&
            product?.images.length > 0 
              ? product?.images[0]
              : "https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg"
          +"?v="+version+"&amp;width=640"}/>
    </div>

  <p class="product-card__title">{product?.heading}</p>
  <p class="product-card__description">${product?.offerPrice}</p>
</div>
    
<div class="universal-link-product-app-details-desktop horizontal-stack">
  <div class="horizontal-stack universal-link-product-store-logos">
    <a class="universal-link-product-app-store-link js-interaction-button" data-interaction-type="ios-download" href="https://apps.apple.com/us/app/shopiads/id1601835714">
      <img class="image" sizes="100vw" src={appleStore} alt=""/>
</a>
    <a class="universal-link-product-play-store-link js-interaction-button" data-interaction-type="android-download" href="https://play.google.com/store/apps/details?id=com.apcod.shopiads">
      <img class="image" sizes="100vw" src={googlePlay} alt=""/>
</a>  </div>
</div>

</div>
          </div>
       </div>

      
      </div>
    </>
  );
}

export default ProductShare;