import React, { useEffect, useState } from "react";
import "../Container.css";
import { useAuth } from "../../../Utils/AuthContext";
import { useSelector, useDispatch } from "react-redux";
import { getAllStores } from "../../../Redux/Stores/StoresActions";
import StoresHeader from "./StoresHeader";
import Card from "react-bootstrap/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import API from "../../../Utils/API";
import { useHistory, useRouteMatch } from "react-router-dom";
import RatingForm from "../../DialogBox/RatingForm";
import LoginForm from "../../DialogBox/LoginForm";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import ProductCard from "../product/ProductCard";
import { Select } from "@material-ui/core";
import CustomerSelectOption from "../../UI/Input/CustomerSelectOption";
import CustomSelect from "../../UI/Container/CustomSelect";

function CollectionDetail() {
  const { userInfo } = useAuth();
  const { allStores, categoryWiseStores } = useSelector(
    (state) => state.stores
  );
  const { location, userId, cityId, cityName, stateId } = useSelector(
    (state) => state.location
  );
  const [loading, setLoading] = useState(false);
  const { path, params } = useRouteMatch();
  const [storeOffer, setStoreOffer] = useState([]);
  const [storeCollection, setStoreCollection] = useState([]);
  const [collectionProduct, setCollectionProduct] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState("");
  const [storeData, setStoreData] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [ratingForm, setRatingForm] = useState(false);
  const [loginForm, setLoginForm] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedStoreId, setSelectedStoreId] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState();
  const [recordPerPage, setRecordPerPage] = useState(32);
  const handleOpenForm = () => setRatingForm(true);
  const handleCloseForm = () => setRatingForm(false);
  const handleLoginOpenForm = () => setLoginForm(true);
  const handleLoginCloseForm = () => setLoginForm(false);

  useEffect(() => {
    if (
      params.storeId != undefined &&
      params.storeId != null &&
      params.storeId != ""
    ) {
      API.getStoreById(params.storeId)
        .then((result) => {
          if (result != undefined) {
            setStoreData(result);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error);
        });
      setLoading(true);
      API.OffersBYStore(params.storeId)
        .then((result) => {
          if (result != undefined) {
            setStoreOffer(result);
            setLoading(false);
          } else {
            setStoreOffer([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          console.log(error);
        });

      API.CollectionBYStore(params.storeId)
        .then((result) => {
          if (result != undefined && result != null) {
            setStoreCollection(result);
            if (result.length > 0) {
              /* setSelectedCollection(result[0].id);
                  API.getOfferProducts(result[0].id).then((result1) => {
                     if (result != undefined) {
                        setCollectionProduct(result1)
                        // setLoading(false);
                     }
                     else {
                        setCollectionProduct([])
                        // setLoading(false);
                     }
                  })
                     .catch((error) => {
                        setLoading(false);
                        console.log(error);
                        console.log(error);
                     });*/
                     if(params.offerId=="allProduct")
              setSelectedCollection("allProduct");
              else
              setSelectedCollection(params.offerId);
             
            }

            // setLoading(false);
          } else {
            setStoreCollection([]);
            // setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          console.log(error);
        });

        if(params.offerId=="allProduct")
        {
          setSelectedCollection("allProduct");
        API.productByStore(params.storeId, currentPage, recordPerPage)
          .then((response) => {
            let {
              content,
              pageable: { pageNumber },
              totalPages: numberOfPages,
            } = response;
            setCollectionProduct(content);
            setTotalPages(numberOfPages);
            setCurrentPage(pageNumber);
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
            console.log(error);
          });
      }
      else
      {
        setSelectedCollection(params.offerId);
        API.getOfferProducts(params.offerId, 0, recordPerPage)
          .then((result1) => {
            let { content } = result1;
            setCollectionProduct(content);
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
            console.log(error);
          });
      }
      
        

    }
  }, [params.storeId]);
  function ListItemLink(props) {
    return;
    <ListItem button component="a" {...props} />;
  }
  const productCardClick = (newValue, productId) => {
    history.push(`/productDetail/` + newValue + "/" + productId);
  };
  const ratingClick = (newValue, productId) => {
    // history.push(`/storeDetails/` + newValue);
    if (!userInfo?.email) setLoginForm(true);
    else {
      setSelectedProduct(productId);
      setSelectedStoreId(newValue);
      setRatingForm(true);
    }
    // console.log("Under Development..............")
  };

  const collectionTabClick = (offerId, storeId) => {
    history.push(`/collectionDetail/` + offerId + "/" + storeId);
  };

  const collectionClick = (offerId) => {
    //history.push(`/productDetail/` + newValue + "/" + productId);
    setSelectedCollection(offerId);
    if ("allProduct" == offerId) {
      API.productByStore(params.storeId, currentPage, recordPerPage)
        .then((response) => {
          let {
            content,
            pageable: { pageNumber },
            totalPages: numberOfPages,
          } = response;
          setCollectionProduct(content);
          setTotalPages(numberOfPages);
          setCurrentPage(pageNumber);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          console.log(error);
        });
    } else {
      API.getOfferProducts(offerId,0,16)
        .then((result) => {
          let { content } = result;
          setCollectionProduct(content);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          console.log(error);
        });
    }
  };


  function handleSortChanage(e) {
    console.log("event>>>>>>>>>",e.target.value);
    if(e.target.value!=undefined && e.target.value!='')
    {
      setLoading(true);
      if("L"==e.target.value)
      {
        console.log("sort by>>>>>>>>>>");
        const sortByPrice = [...collectionProduct];
        sortByPrice.sort((a, b) => (a.offerPrice > b.offerPrice ? 1 : -1));
        console.log("sort by>>>>>>>>>>",sortByPrice);
        setCollectionProduct(sortByPrice);
      }
      if("H"==e.target.value)
      {
        console.log("sort by>>>>>>>>>>");
        const sortByPrice = [...collectionProduct];
        sortByPrice.sort((a, b) => (a.offerPrice < b.offerPrice ? 1 : -1));
        console.log("sort by>>>>>>>>>>",sortByPrice);
        setCollectionProduct(sortByPrice);
      }
      setLoading(false);
    }
  }

  return (
    <>
      <div className="container-fluid">
        {ratingForm && (
          <RatingForm
            handleOpen={handleOpenForm}
            handleClose={handleCloseForm}
            open={ratingForm}
            storeId={selectedStoreId}
            productId={selectedProduct}
            offerId={""}
            type={"Product"}
          />
        )}
        {loginForm && (
          <LoginForm
            handleOpen={handleLoginOpenForm}
            handleClose={handleLoginCloseForm}
            open={loginForm}
          />
        )}

        {!loading ? (
          <div className="row mt-4">
            <div className="col-md-10 mx-auto">
              <a
                href="javascript:void(0)"
                onClick={(e) => history.goBack()}
                class="shop-search-page__breadcrumb-link"
              >
                <svg
                  enable-background="new 0 0 11 11"
                  viewBox="0 0 11 11"
                  x="0"
                  y="0"
                  class="icon-arrow-left"
                >
                  <g>
                    <path d="m8.5 11c-.1 0-.2 0-.3-.1l-6-5c-.1-.1-.2-.3-.2-.4s.1-.3.2-.4l6-5c .2-.2.5-.1.7.1s.1.5-.1.7l-5.5 4.6 5.5 4.6c.2.2.2.5.1.7-.1.1-.3.2-.4.2z"></path>
                  </g>
                </svg>
                back to store
              </a>
            </div>
            <div className="col-md-10 mx-auto">
              <div className="row">
                <div className="col-md-2">
                  <div className="seller-overview store-pro rounded-0">
                    <div className="content">
                      <img
                        class="shopee-avatar__img mt-2"
                        src={
                          storeData.logoMedium != undefined &&
                          storeData.logoMedium != null
                            ? storeData.logoMedium
                            : "https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg"
                        }
                      />
                      <div className="storename">{storeData.code}</div>
                      <div class="section-seller-overview__active-time">
                        Active 4 hours ago
                      </div>
                    </div>
                  </div>
                  <div className="bg-white px-3 pb-3">
                    <h6 className="pb-2 pt-3 mb-0">Collection</h6>
                    <div
                      onClick={() => {
                        collectionClick("allProduct");
                      }}
                      className={
                        "allProduct" == selectedCollection
                          ? "pcmall-shopmicrofe_1ftFmB active border-bottom px-0"
                          : "pcmall-shopmicrofe_1ftFmB border-bottom px-0"
                      }
                    >
                      Products
                    </div>
                    {storeCollection != undefined &&
                    storeCollection != null &&
                    storeCollection.length > 0
                      ? storeCollection.map((item, index) => (
                          <div
                            onClick={() => {
                              collectionClick(item.id);
                            }}
                            className={
                              item.id == selectedCollection
                                ? "pcmall-shopmicrofe_1ftFmB active border-bottom px-0"
                                : "pcmall-shopmicrofe_1ftFmB border-bottom px-0"
                            }
                          >
                            {item.heading}
                          </div>
                        ))
                      : ""}
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="shopee-sort-bar">
                    {/*<span className="shopee-sort-bar__label">Sort by</span>*/}
                    <div className="sort-options">
                      <span className="shopee-sort-bar__label mr-4">
                        Sort by
                      </span>
                      {/* <Button
                        variant="contained"
                        color="primary"
                        className="mr-3 ml-3"
                      >
                        Popular
                      </Button>
                      <Button variant="outline-primary" className="mr-3">
                        Latest
                      </Button>*/}
                      {/*<Button variant="outline-primary" className="mr-3">Top Sales</Button>
                       */}
                      <CustomSelect
                        onChange={handleSortChanage}
                        className="form-control w-25"
                      >
                        <CustomerSelectOption value="grapefruit">
                          Select Sort by
                        </CustomerSelectOption>
                        <CustomerSelectOption value="L">
                          Price: Low to High
                        </CustomerSelectOption>
                        <CustomerSelectOption value="H">
                          Price: High to Low
                        </CustomerSelectOption>
                      </CustomSelect>
                    </div>
                    {/* <div className="shopee-mini-page-controller">
                        <div className="shopee-mini-page-controller__state">
                           <span className="shopee-mini-page-controller__current">1</span>/<span className="shopee-mini-page-controller__total">94</span>
                        </div>
                        <button className="btn btn-light rounded-0 mr-1 ml-3" disabled="">
                           <svg viewBox="0 0 7 11" className="shopee-svg-icon icon-arrow-left-small">
                              <path d="M4.694078 9.8185598L.2870824 5.4331785c-.1957415-.1947815-.1965198-.511363-.0017382-.7071046a.50867033.50867033 0 0 1 .000868-.0008702L4.7381375.2732784 4.73885.273991c.1411545-.127878.3284279-.205779.5338961-.205779.4393237 0 .7954659.3561422.7954659.7954659 0 .2054682-.077901.3927416-.205779.5338961l.0006632.0006632-.0226101.0226101a.80174653.80174653 0 0 1-.0105706.0105706L2.4680138 4.7933195c-.1562097.1562097-.1562097.4094757 0 .5656855a.45579485.45579485 0 0 0 .0006962.0006944l3.3930018 3.3763607-.0009482.0009529c.128869.1413647.2074484.3293723.2074484.5357331 0 .4393237-.3561422.7954659-.7954659.7954659-.2049545 0-.391805-.077512-.5328365-.2048207l-.0003877.0003896-.0097205-.0096728a.80042023.80042023 0 0 1-.0357234-.0355483z" fill-rule="nonzero"></path>
                           </svg>
                        </button>
                        <button className="btn btn-light rounded-0">
                           <svg viewBox="0 0 7 11" className="shopee-svg-icon icon-arrow-right-small">
                              <path d="M2.305922 9.81856l4.4069956-4.385381c.1957415-.194782.1965198-.511364.0017382-.707105a.26384055.26384055 0 0 0-.000868-.00087L2.2618625.273278 2.26115.273991C2.1199955.146113 1.9327221.068212 1.7272539.068212c-.4393237 0-.7954659.356142-.7954659.795466 0 .205468.077901.392741.205779.533896l-.0006632.000663.0226101.02261c.0034906.003557.0070143.00708.0105706.010571L4.5319862 4.79332c.1562097.156209.1562097.409475 0 .565685-.0002318.000232-.0004639.000463-.0006962.000694L1.1382882 8.73606l.0009482.000953c-.128869.141365-.2074484.329372-.2074484.535733 0 .439324.3561422.795466.7954659.795466.2049545 0 .391805-.077512.5328365-.204821l.0003877.00039.0097205-.009673c.012278-.011471.0241922-.023327.0357234-.035548z" fill-rule="nonzero"></path>
                           </svg>
                        </button>
                  </div>*/}
                  </div>

                  <div className="row mt-4">
                    {collectionProduct.map((item, index) => (
                      <div className="col-md-3 mb-3">
                        <ProductCard
                          branchName={item.storeName}
                          storeId={item.storeId}
                          productId={item.id}
                          img={item.images}
                          branchLogo={item.logo}
                          heading={item.heading}
                          avgRating={item.avgRating}
                          discount={item.discountPerc}
                          actualPrice={item.actualPrice}
                          offerPrice={item.offerPrice}
                          totalLikes={item.totalLikes}
                          key={item.id}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <strong>Loading ....</strong>
        )}
      </div>
    </>
  );
}
export default CollectionDetail;
