import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Carousel } from "react-bootstrap";
import Rating from "@material-ui/lab/Rating";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
import Dummy1 from "../../../Assets/Dummy 1.jpg";
import Dummy2 from "../../../Assets/Dummy 2.jpg";
import Dummy3 from "../../../Assets/Dummy 3.jpg";
import SampleBanner from "../../../Assets/Sample Banner.png";
import Image1 from "../../../Assets/Img1.jpg";
import Table from "../../../Assets/Table.png";
import Children from "../../../Assets/Children.png";
import Casualshirts from "../../../Assets/Casualshirts.png";
import Footwear from "../../../Assets/Footwear.png";
import Sweatshirts from "../../../Assets/Sweatshirts.png";
import Shorts from "../../../Assets/Shorts.png";
import { Grid, ListItemText, makeStyles, Tab, Tabs } from "@material-ui/core";
import Card from "react-bootstrap/Card";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getUserSnips } from "../../../Redux/Snips/SnipsAction";
import { useAuth } from "../../../Utils/AuthContext";
import API from "../../../Utils/API";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import ReactPaginate from "react-paginate";
import {
  getAllStores,
  getStoresAllProduct,
  getStoresByCategory,
  getStoresProductByCategory,
  getSubCategory,
} from "../../../Redux/Stores/StoresActions";
import ProductCard from "../product/ProductCard";
import item from "react-bootstrap-typeahead/lib/behaviors/item";
import { getDomainCountryCode } from "../../../service/location";
import { COLORS } from "../../../Utils/context/ThemeContext";
export const leftPaginationArrow = (
  <ArrowBackIosIcon style={{ margin: 0, marginLeft: 8, color: COLORS.primary.main }} />
);
export const rightPaginationArrow = (
  <ArrowForwardIosIcon style={{ margin: 0, color: COLORS.primary.main }} />
);

const style = {
  backButton: {
    fontSize: "25px",
    marginBottom: "10px",
    marginLeft: "12px",
    color: COLORS.primary.main,
    cursor: "pointer",
  },
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    "& button": {
      "& .MuiTab-wrapper": {
        flexDirection: "row !important",
        justifyContent: "left !important",
      },
    },
  },
}));

function AllProducts() {
  const classes = useStyles();
  const { userInfo } = useAuth();
  const { path, params } = useRouteMatch();

  const dispatch = useDispatch();
  const history = useHistory();
  let allCategories = [];
  const {
    userId,
    cityId,
    stateId,
    marketPlace: selectedRefMarketPlace,
  } = useSelector((state) => state.location);
  const [value, setValue] = useState(0);
  const [mainCategoryList, setMainCategoryList] = useState([]);
  const [allCategoriesArray, setAllCategoriesArray] = useState([]);
  const [items, setItems] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [recordPerPage] = useState(32)
  const [category, setCategory] = useState("Trending")
  const [section, setSection] = useState({})
  const [categoryName, setCategoryName] = useState("Trending")
  const refreshData = async () => { 
    let categoryId = "Trending";
    let categoryNameString = "Trending";
    setItems([])
    const sectionInfo = await API.getSectionById(params.sectionName);
    setSection(sectionInfo);
    dispatch(getUserSnips(userInfo?.email, "like"));
    await API.getMainCategories(
      userInfo != null && userInfo != undefined ? userInfo.email : "gust"
    )
      .then((result) => {
        console.log("result.data>>>>>>>>>>>>>>>>>>", result);
        if (result != undefined && result.length > 0) {
          //console.log('data=='+result.data);
          setMainCategoryList(result);
          allCategories = ["Trending"].concat(
            result.map((category) => category.name)
          );
          result.forEach(cat => {
            if (cat.name === params.tabName || cat.id === params.tabName ) {
              categoryId = cat.id;
              categoryNameString = cat.name;
              setCategoryName(categoryNameString)
            }
          })
          setCategory(categoryId);
          setAllCategoriesArray(allCategories);
          setValue(allCategories.indexOf(params.tabName));
          console.log("allCategoriesArray>>>>>>>>>>>>>>>>>>", allCategories);
        }
      });
      API.getProductsByOfferSection(
        categoryId,
        cityId,
        sectionInfo.id,
        stateId,
        "Offer",
        selectedRefMarketPlace,
        1,
        32
      ).then((response) => {
        let {
          content,
          pageable: { pageNumber },
          totalPages: numberOfPages,
        } = response;
        setItems(content);
        setCurrentPage(pageNumber + 1);
        setTotalPages(numberOfPages);
      });
      window.scrollTo(0, 0);
  }
  useEffect(() => {
    refreshData();
  }, [params]);
  const handleChange = (event, newValue) => {
    const tabName = event.target.innerText;
    setValue(newValue);

    if (tabName !== "NEW") {
      dispatch(
        getStoresProductByCategory({
          user: API.encodedUserId(userInfo != null && userInfo !== undefined ? userInfo.email : "gust"),
          categoryId: tabName === "TRENDING" ? "trending" : mainCategoryList[newValue - 1].id,
          cityId,
          stateId,
          viewType: "offer",
          marketPlace: selectedRefMarketPlace,
          pageNo: 0,
          recordPerPage,
          orderBy: "createdOn",
          asc:false
        })
      );
    } else {
      dispatch(
        getAllStores(userInfo?.email || userId, cityId, stateId, "offer",selectedRefMarketPlace)
      );
      const searchQuery = API.generateQueryString({
        user: API.encodedUserId(userInfo?.email || userId),
        cityId,
        stateId,
        viewType: "offer",
        marketPlace: selectedRefMarketPlace,
        pageNo: 0,
        recordPerPage,
        orderBy: "createdOn",
        asc: getDomainCountryCode() === "CA" ? true : false
      });
      dispatch(getStoresAllProduct(searchQuery));
    }
  };

  return (
    <>
      <Grid container className="mt-3">
        <Grid item xs={2}>
          <div className="menu-tab">
            <i
              style={{ ...style.backButton, position: "relative" }}
              className="fa fa-arrow-circle-left"
              aria-hidden="true"
              title="Back to Login"
              onClick={() =>
                history.push(`/offers/${window.location.href.split("/")[5]}`)
              }
            ></i>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="off"
              className={classes.tabs}
              orientation="vertical"
            >
              {allCategoriesArray.map((item, index) => (
                <Tab
                  {...a11yProps(index)}
                  label={item}
                  key={item}
                  onClick={() => {
                    history.push(`/${params.sectionName}/allProducts/${item}`)
                    setCurrentPage(1);
                  }
                  }
                  style={{ minWidth: "120px", textAlign: "left" }}
                  wrapped
                />
              ))}
            </Tabs>
          </div>
        </Grid>
        <Grid item xs={10}>
          <h4 className="ml-5">
            <strong>
              {section.name} Products
            </strong>
          </h4>
          <Grid container className="ml-5 mr-3">
            {items.length === 0 && (
              <h5>Coming up soon in future in {categoryName}</h5>
            )}
            {items
              .map((item, index) => (
                <div style={{ width: "250px" }}>
                  <ProductCard
                    branchName={item.storeName}
                    storeId={item.storeId}
                    productId={item.id}
                    img={item.images}
                    branchLogo={item.logo}
                    heading={item.heading}
                    avgRating={item.avgRating}
                    discount={item.discountPerc}
                    actualPrice={item.actualPrice}
                    offerPrice={item.offerPrice}
                    totalLikes={item.totalLikes}
                    key={item.id}
                  />
                </div>
              ))}
          </Grid>
        </Grid>
      </Grid>
      {totalPages > 1 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <ReactPaginate
            forcePage={currentPage-1}
            pageCount={totalPages}
            pageRangeDisplayed={3} // Number of pages to display in the pagination
            marginPagesDisplayed={1} // Number of pages to display on the edges
            previousLabel={leftPaginationArrow}
            nextLabel={rightPaginationArrow}
            breakLabel={"..."}
            containerClassName={"pagination"}
            activeClassName={"active"}
            disabledClassName={"disabled"}
            onPageChange={(e) => {
              const selectedPage = e.selected;
              console.log("currentPage::",selectedPage)
              API.getProductsByOfferSection(
                category,
                cityId,
                section.id, stateId, "Offer",
                selectedRefMarketPlace,selectedPage + 1, 32
              ).then(response => { 
                let {
                  content,
                  pageable: { pageNumber },
                  totalPages: numberOfPages,
                } = response;
                setItems(content);
                setCurrentPage(pageNumber + 1);
                setTotalPages(numberOfPages);
              })
              window.scrollTo(0, 0);
            }}
          />
        </div>
      )}
    </>
  );
}

export default AllProducts;
