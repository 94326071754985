import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch, Link,useHistory } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";

import Checkbox from '@material-ui/core/Checkbox';

import { makeStyles } from '@material-ui/core/styles';

import googlePlay from "../../Assets/google-play.png";
import appleStore from "../../Assets/app-store.png";

import MetaTags from 'react-meta-tags';
import { Helmet } from 'react-helmet';
import API from "../../Utils/API";
import environment from "../../Utils/env";


const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

function OfferShare({ match }) {
  const classes = useStyles();
  const [offer, setOffer] = useState();
  const [version, setVersion] = useState(1);

  useEffect(() => {

    API.getOffersById("gust", match.params.offerId)
    .then((result1) => {
      if (result1 != undefined) {
        console.log("share product data>>>>>>>>>>>>",result1)
        setOffer(result1);
      }
    })
    .catch((error) => {
      // setLoading(false);
      console.log(error);
      // console.log(error);
    });

    const randomVersion = Math.floor(Math.random() * (100000000 - 1 + 1) + 1);
  console.log("share product randomVersion>>>>>>>>>>>>",randomVersion)
  setVersion(randomVersion);
  }, [match.params.offerId]);

  
  

  return (
    <>
      <div className="container-fluid my-5 pt-5">
      <Helmet>
      
      <title>{offer?.heading}</title>
      <link rel="icon" href={
            offer?.fullImage != undefined &&
            offer?.fullImage != "" &&
            offer?.fullImage != null
              ? offer?.fullImage
              : "https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg"
          +"?v="+version+"&amp;width=640"} />
      <meta
      name="description"
      content={offer?.offerPrice}
    />
            <meta id="og-title" property="og:title" content={offer?.heading}/>
            <meta name="description" content="App Description" data-react-helmet="true"></meta>
              <meta id="og-description" property="og:description" content={offer?.offerPrice}></meta>
              <meta  id="og-image" property="og:image" content={
            offer?.fullImage != undefined &&
            offer?.fullImage != "" &&
            offer?.fullImage != null 
              ? offer?.fullImage
              : "https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg"
          +"?v="+version+"&amp;width=640"} data-react-helmet="true"></meta>
              <meta id="og-url" property="og:url" content={environment.CANADA_BASE_URL +"/productShare/"+offer?.id} data-react-helmet="true"></meta>
              <meta id="meta-description2" property="twitter:card" content="summary_large_image" data-react-helmet="true"></meta>
              <meta id="meta-description3" property="twitter:site" content="@ShopiAds" data-react-helmet="true"></meta>
              <meta id="meta-description4" property="twitter:title" content={offer?.heading} data-react-helmet="true"></meta>
              <meta id="meta-description5"  property="twitter:description" content={offer?.offerPrice} data-react-helmet="true"></meta>
              <meta id="meta-description6" property="twitter:image" content={
            offer?.fullImage != undefined &&
            offer?.fullImage != "" &&
            offer?.fullImage != null
              ? offer?.fullImage
              : "https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg"
          +"?v="+version+"&amp;width=640"}></meta>
              </Helmet>

       <div className="row">
          <div className="col-md-12">
          <div class="vertical-stack product" data-page-type="universal-link-product">
    
<div class="product-card">
   {/* <div class="product-card__merchant-logo">
      <img class="product-card__merchant-logo__img" alt="Logo of product" src={
            product?.images != undefined &&
            product?.images != "" &&
            product?.images != null &&
            product?.images.length > 0 
              ? product?.images[0]
              : "https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg"
          +"?v="+version+"&amp;width=640"}/>
      </div>*/}

    <div class="product-card__image-wrapper">
      <img class="product-card__image" src={
            offer?.fullImage != undefined &&
            offer?.fullImage != "" &&
            offer?.fullImage != null
              ? offer?.fullImage
              : "https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg"
          +"?v="+version+"&amp;width=640"}/>
    </div>

  <p class="product-card__title">{offer?.heading}</p>
  {/*<p class="product-card__description">${offer?.offerPrice}</p>*/}
</div>
    
<div class="universal-link-product-app-details-desktop horizontal-stack">
  <div class="horizontal-stack universal-link-product-store-logos">
    <a class="universal-link-product-app-store-link js-interaction-button" data-interaction-type="ios-download" href="https://apps.apple.com/us/app/shopiads/id1601835714">
      <img class="image" sizes="100vw" src={appleStore} alt=""/>
</a>
    <a class="universal-link-product-play-store-link js-interaction-button" data-interaction-type="android-download" href="https://play.google.com/store/apps/details?id=com.apcod.shopiads">
      <img class="image" sizes="100vw" src={googlePlay} alt=""/>
</a>  </div>
</div>

</div>
          </div>
       </div>

      
      </div>
    </>
  );
}

export default OfferShare;