import React, { useState, useEffect, useMemo, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Nav } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ReactFlagsSelect from "react-flags-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStore } from "@fortawesome/free-solid-svg-icons";
import mixpanel from "mixpanel-browser";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../../../Utils/AuthContext";
import LoginForm from "../../../DialogBox/LoginForm";
import RegisterForm from "../../../DialogBox/RegisterForm";
import ForgotPasswordForm from "../../../DialogBox/ForgotPasswordForm";
import UnRegisterUser from "../../../DialogBox/UnRegisterUser";
import LocationForm, { locationRequiredURLs } from "../../../DialogBox/LocationForm";
import {
  getAllStoresList,
  getSubCategory,
} from "../../../../Redux/Stores/StoresActions";
import API from "../../../../Utils/API";
import Profile from "../../../../Assets/profile.jpeg";
import TwitterIcon from "../../../../Assets/twitterx.png";
import FacebookIcon from "../../../../Assets/facebook.svg";
import InstagramIcon from "../../../../Assets/instagram.svg";
import downloadQr from "../../../../Assets/downloadAppQr.png";
import downloadQrUS from "../../../../Assets/downloadAppQrUS.png";
import ShopiAdsLogo from "../../../../Assets/original_with_white-cutout.png";
import HyperLinkText from "../../Text/HyperLinkText";
import HyperImageLink from "../../Text/HyperImageLink";
import { Close, ListAlt } from "@material-ui/icons";
import environment from "../../../../Utils/env";
import { setDefaultCountryToLocalStorage, setMatketPlaceToLocalStorage } from "../../../../Redux/Location/LocationActions";
import { getDomainCountryCode, getUserDefaultCountry, MARKET_PLACES } from "../../../../service/location";
import { getToken, onMessageListener } from "../../../../Utils/FirebaseConfig";
import {uuid} from "../../../../Utils/uuid";
import NotificationPopUp from "../../../Container/Notifications/ReactNotification";
import '../../../../index.css'
import { Select } from "@material-ui/core";
import CustomerSelectOption from "../../Input/CustomerSelectOption";
import CustomSelect from "../CustomSelect";
import { COLORS } from "../../../../Utils/context/ThemeContext";
function Header(props) {
  const { location, defaultCountry, marketPlace } = useSelector(
    (state) => state.location
  );
  const { allSnips } = useSelector((state) => state.snips);
  const [downloadToggle, setDownloadToggle] = useState(false);
  const [marketPlaceToggle, setMarketPlaceToggle] = useState(false);
  const [isMarketPlaceConfirmed, setIsMarketPlaceConfirmed] = useState(false);
  const classes = useStyles();
  const { userInfo } = useAuth();
  const [openForm, setOpenForm] = useState({
    login: false,
    register: false,
    forgotPassword: false,
    location: false,
    unRegisterUser: false,
  });
  const [mainCategoryList, setMainCategoryList] = useState([]);
  const [activeLink, setActiveLink] = useState("");
  const history = useHistory();
  const link = history.location.pathname.split("/")[1];
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(window.location.host.includes(environment.CADANA_DOMAIN) ? "CA" : "US");
  const [searchText, setSearchText] = useState("");
  const [selectedCat, setSelectedCat] = useState("all");
  const [productionSuggest, setProductionSuggest] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0)
  const [isMarketPlaceSelectionVisible, setIsMarketPlaceSelectionVisible] = useState(false)
  const [isAutoCompleteOpen, setIsAutoCompleteOpen] = useState(false)
  let pathName = "";
  if (window !== undefined && window.location !== undefined)
    pathName = window.location.pathname;
  const queryParams = new URLSearchParams(window.location.search);
  const fromWhere = queryParams.get("fromWhere");
  const defaultCountryParam = queryParams.get("defaultCountry");
  const marketPlaceParam = queryParams.get("marketPlace");
  console.log({
    defaultCountryParam,
    marketPlaceParam,
  });
  useEffect(() => {
    
    mixpanel.init("463f38f27f62bb3204c94e50a0128f23", { debug: true });
    if (defaultCountryParam) {
      dispatch(setDefaultCountryToLocalStorage(defaultCountryParam));
    }
    if (marketPlaceParam) {
      dispatch(setMatketPlaceToLocalStorage(marketPlaceParam));
    }
    document.addEventListener("click", downloadPopUp);
    getToken().then((response) => {
      console.log({
        response,
      });
      if (response && userInfo?.email) {
        API.updateUserWebToken(userInfo.email, response);
      }
    });
    onMessageListener()
      .then((payload) => {
        console.log({
          payload,
        });
        toast(
          <div
            style={{
              backgroundColor: "white",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div style={{ marginRight: "10px" }}>
              <img
                src={
                  payload.notification.image ||
                  "https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg"
                }
                height={28}
                width={28}
              />
            </div>
            <div>
              <p style={{ fontWeight: "bold" }}>{payload.notification.title}</p>
              <p>{payload.notification.body}</p>
            </div>
          </div>,
          {
            autoClose: 50000,
            hideProgressBar: true,
            newestOnTop: false,
            closeOnClick: true,
            rtl: false,
            pauseOnFocusLoss: false,
            draggable: true,
            pauseOnHover: false,
            position: "top-right",
            progress: undefined,
            theme: "light",
          }
        );
        setNotificationCount((count) => count + 1);
      })
      .catch((err) => console.log("failed: ", err));

    return () => {
      document.removeEventListener("click", downloadPopUp);
    };
  }, []);

  useEffect(() => { 
    if (!isMarketPlaceConfirmed) {
      verifySelectedMarketPlace();      
    }
  },[marketPlace])
  const downloadPopUp = (event) => {
    var ignoreClickOnMeElement = document.getElementById("downloadId");
    if (ignoreClickOnMeElement != null) {
      var isClickInsideElement = ignoreClickOnMeElement.contains(event.target);
      if (!isClickInsideElement) {
        setDownloadToggle(false);
      }
    }
  };

  const verifySelectedMarketPlace = async () => {
    const ipCountryCode = defaultCountry;
    const siteCountryCode = window.location.hostname.includes(environment.CADANA_DOMAIN) ? "CA" : "US"
    if (
      !isMarketPlaceConfirmed && ipCountryCode !== siteCountryCode
    ) {
      location && setMarketPlaceToggle(true);
    } else {
      setMarketPlaceToggle(false);
      setIsMarketPlaceConfirmed(true)
    }
    setCountryConfiguration();
  };
  const setCountryConfiguration = async () => {
    const hostDomain = window.location.host;
    const defaultRefMarket = marketPlace || "null"
    const ipCountryCode = defaultCountry;
    let marketPlaceName = MARKET_PLACES.find(
      (market) => market.id === ipCountryCode
    )?.name;
    console.log({
      marketPlaceName
    })
    if (defaultRefMarket === "null") { 
      if (marketPlaceName) {
        dispatch(setMatketPlaceToLocalStorage(marketPlaceName));
      } else {
        if (hostDomain && hostDomain.includes(environment.CADANA_DOMAIN)) {
          marketPlaceName = "Canada"
          dispatch(setMatketPlaceToLocalStorage("Canada"))
        } else {
          marketPlaceName = "United States"
        }
      }
      dispatch(setMatketPlaceToLocalStorage(marketPlaceName))
    }
    if (hostDomain && hostDomain.includes(environment.CADANA_DOMAIN)) {
      localStorage.setItem("connectSite", "CA");
      sessionStorage.setItem("defaultCountry", "Canada");
      setSelected("CA");
      if (ipCountryCode !== "CA" && !marketPlace?.includes(",")) {
        setMarketPlaceToggle(true);
      }
    } else {
      localStorage.setItem("connectSite", "US");
      sessionStorage.setItem("defaultCountry", "United States")
      setSelected("US");
      if (ipCountryCode !== "US" && !marketPlace?.includes(",")) {
        setMarketPlaceToggle(true);
      }
    }
  };

  const onSelect = async (code) => {
    window.location.href = 
      `${code === "CA" ? environment.CANADA_BASE_URL : environment.USA_BASE_URL}?${API.generateQueryString({
        defaultCountry,
        marketPlace,
      })}`
  };

  const toggleMobileAppQRCode = () => {
    setDownloadToggle((value) => !value);
  };

  const showSelectedLabel = Boolean("Show Selected Label", true);

  const showSecondarySelectedLabel = Boolean(
    "Show Secondary Selected Label",
    true
  );
  const showOptionLabel = Boolean("Show Option Label", true);
  const showSecondaryOptionLabel = Boolean("Show Secondary Option Label", true);

  const customLabels = Object("Custom Labels", {
    CA: { primary: "CA", secondary: "1" },
    US: { primary: "US", secondary: "+1" },
  });
  const userEmaiId = useMemo(() => {
    return userInfo != null && userInfo !== undefined ? userInfo.email : "gust";
  }, [userInfo]);

  useEffect(() => {
    setActiveLink(link);
    if (marketPlace) {
      dispatch(getAllStoresList(marketPlace));
    }
    verifySelectedMarketPlace();
    API.getMainCategories(userEmaiId)
      .then((result) => {
        if (result !== undefined && result.length > 0) {
          setMainCategoryList(result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    dispatch(getSubCategory(userEmaiId, "All"));
    const marketPlaceNeeded = !locationRequiredURLs.find(blockPage => pathName.startsWith(`/${blockPage}`));
    setIsMarketPlaceSelectionVisible(marketPlaceNeeded);
    if (
      !(locationRequiredURLs.find((endpoint) => endpoint === link) || isMobile) &&
      location.length <= 0
    ) {
      setOpenForm((prevState) => ({ ...prevState, location: true }));
    }
    if (fromWhere) {
      if (fromWhere !== "myVideo") {
        mixpanel.track("Site Visited from " + fromWhere);
      } else {
        mixpanel.track("ShopiAds video play from " + fromWhere);
      }
    }
  }, [location.length, marketPlace,defaultCountry]);

  useEffect(() => {
    const userRefMarket = marketPlace;
    if (searchText) {
      let marketPlace = null;
      if (userRefMarket) {
        marketPlace = userRefMarket;
      } else {
        marketPlace = sessionStorage.getItem("defaultCountry");
      }
      API.productAutoSearch(searchText, selectedCat, marketPlace)
        .then((result) => {
          if (result !== undefined && result.length > 0) {
            setProductionSuggest(result);
          } else {
            setProductionSuggest([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [searchText]);

  const handleOpenForm = (form) => {
    setOpenForm((prevState) => ({ ...prevState, [form]: true }));
  };

  const handleCloseForm = (form) => {
    setOpenForm((prevState) => ({ ...prevState, [form]: false }));
  };

  const handleNavClick = (name, url) => {
    if (window.location.pathname.startsWith(`/${name}`)) {
      return;
    }
    if (
      (locationRequiredURLs.find((endpoint) => endpoint === link) ||
        isMobile) &&
      location.length <= 0
    ) {
      setActiveLink(name);
      localStorage.setItem("mode", name);
      history.replace(url);
      window.location.reload();
    } else {
      setActiveLink(name);
      history.replace(url);
      localStorage.setItem("mode", name);
    }
  };
  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleAutoSearch("/search", e.target.value);
      setProductionSuggest([])
      setIsAutoCompleteOpen(false)
    }
  };

  const handleAutoSearch = (url, text) => {
    if (window.location.pathname.startsWith("/search")) {
      history.replace(url + "/" + Buffer.from(text).toString('hex') + "/" + selectedCat);      
    } else {
      history.push(url + "/" + Buffer.from(text).toString('hex') + "/" + selectedCat);
    }
  };

  const handleSearchClick = (url) => {
    if (searchText !== undefined && searchText !== "")
      if (window.location.pathname.startsWith("/search")) {
        history.replace(url + "/" + Buffer.from(searchText).toString('hex') + "/" + selectedCat);
      } else {
        history.push(url + "/" + Buffer.from(searchText).toString('hex') + "/" + selectedCat);
      }
  };

  const getCatChange = async (e) => {
    if (e.target.value !== undefined && e.target.value !== "") {
      setSelectedCat(e.target.value);
      // getStoreByCompany(e.target.value);
    }
  };
  const handleMarketViewAll = () => {
    sessionStorage.setItem("refMarket", "Canada,United States");
    dispatch(setMatketPlaceToLocalStorage("Canada,United States"))
    setMarketPlaceToggle(false);
    setIsMarketPlaceConfirmed(true)
    props.forceUpdate();
  };

  const handleMarketContinue = async () => {
    setMarketPlaceToggle(false);
    setIsMarketPlaceConfirmed(true)
    const hostDomain = window.location.host;
    const ipCountryCode = defaultCountry;
    let marketPlaceName = MARKET_PLACES.find(
      (market) => market.id === ipCountryCode
    )?.name;
    if (marketPlaceName) {
      dispatch(setMatketPlaceToLocalStorage(marketPlaceName));
    } else {
      if (hostDomain && hostDomain.includes(environment.CADANA_DOMAIN)) {
        marketPlaceName = "Canada"
        dispatch(setMatketPlaceToLocalStorage("Canada"))
      } else {
        marketPlaceName = "United States"
      }
    }
    dispatch(setMatketPlaceToLocalStorage(marketPlaceName))
  };
  const renderMobileAppQRCode = () => {
    return (
      <div
        className={
          downloadToggle
            ? "shopee-drawer__contents d-block"
            : "d-none shopee-drawer__contents"
        }
        aria-describedby="temporaryId"
        aria-hidden="false"
        style={{ transitionDelay: "0.2s", left: "0px" }}
      >
        <div className="WCwZOx">
          <img
            src={localStorage.getItem("connectSite") === "CA" ? downloadQr : downloadQrUS}
            alt="download_qr_code"
            className="hkcZtW"
          />
          <div className="_1Hb-5J">
            <div className="cLfmRJ">
              <img
                className="WFu8TV"
                src="https://deo.shopeemobile.com/shopee/shopee-pcmall-live-sg//assets/39f189e19764dab688d3850742f13718.png"
                alt="App Store"
              />
            </div>
            <div className="cLfmRJ">
              <img
                className="WFu8TV"
                src="https://deo.shopeemobile.com/shopee/shopee-pcmall-live-sg//assets/f4f5426ce757aea491dce94201560583.png"
                alt="Play Store"
              />
            </div>
            {/*<div className="cLfmRJ"><img className="WFu8TV" src="https://deo.shopeemobile.com/shopee/shopee-pcmall-live-sg//assets/1ae215920a31f2fc75b00d4ee9ae8551.png" alt="App Gallery"/>
                        </div>*/}
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <AppBar
        position={props.position ? props.position : "fixed"}
        className={classes.appBar}
        style={
          props.position ? { paddingTop: "10px", paddingBottom: "10px" } : {}
        }
      >
        <Toolbar className={classes.miniToolbar}>
          <div className={classes.miniToolbar_LeftNav}>
            <HyperLinkText
              href={
                "https://apps.shopify.com/shopiads?utm_source=shopiads_web&utm_medium=web&utm_campaign=sellers&utm_id=5"
              }
              target="_new"
              label="Onboard Your Store"
              style={{ paddingLeft: 0 }}
            />
            <div>|</div>
            <HyperLinkText
              href={"#"}
              onClick={toggleMobileAppQRCode}
              id="downloadId"
              label="Mobile App"
            />
            {renderMobileAppQRCode()}
            <div>|</div>
            <div className={classes.miniToolbar_socialContainer}>
              <Typography style={{ fontSize: "12px", paddingLeft: "10px" }}>
                Follow us on
              </Typography>
              <HyperImageLink
                url="https://www.facebook.com/profile.php?id=100084814547567"
                source={FacebookIcon}
              />
              <HyperImageLink
                url="https://www.instagram.com/shopiads/"
                source={InstagramIcon}
              />
              <HyperImageLink
                url="https://twitter.com/ShopiAds"
                source={TwitterIcon}
                imageProps={{
                  style: {
                    backgroundColor: "white",
                    width: 20,
                    height: 20,
                    borderRadius: "50%",
                    padding:"3px"
                  },
                }}
              />
            </div>
          </div>
          <div className={classes.miniToolbar_RightNav}>
            <div className="demo-wrapper">
              <ReactFlagsSelect
                selected={selected}
                onSelect={onSelect}
                showSelectedLabel={showSelectedLabel}
                showSecondarySelectedLabel={showSecondarySelectedLabel}
                showOptionLabel={showOptionLabel}
                showSecondaryOptionLabel={showSecondaryOptionLabel}
                customLabels={customLabels}
                countries={["CA", "US"]}
              />
            </div>
            {defaultCountry && defaultCountry !== getDomainCountryCode() && (
              <div
                className={
                  marketPlaceToggle &&
                  isMarketPlaceSelectionVisible &&
                  !marketPlace.includes(",")
                    ? "shopee-drawer__contents d-block"
                    : "d-none shopee-drawer__contents"
                }
                aria-describedby="temporaryId"
                aria-hidden="false"
                style={{
                  transitionDelay: "0.2s;",
                  left: "0px;",
                  right: "-4.3%",
                }}
              >
                <div className="WCwZOxPlace">
                  <div className="ml-2">
                    {" "}
                    We're showing you stores that ship to{" "}
                    <strong> {marketPlace}</strong>. To access all stores select
                    view all
                  </div>
                  <div className="_1Hb-5J">
                    <div className="cLfmRJMarket">
                      <Button
                        style={{ color: "white", background: COLORS.primary.main }}
                        className="mt-2 ml-2"
                        onClick={() => handleMarketContinue()}
                        size="small"
                        variant="outlined"
                      >
                        Continue
                      </Button>
                      <Button
                        style={{color: "black" ,fontWeight:"bold"}}
                        className="ml-4 mt-2"
                        onClick={() => handleMarketViewAll()}
                        size="small"
                        variant="outlined"
                      >
                        View All
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <a
              href="#!"
              className={classes.miniToolbar_Content}
              onClick={() => handleOpenForm("location")}
            >
              <i className="fa fa-map-marker" aria-hidden="true"></i>{" "}
              {location || "Choose your location"}
            </a>
            {userInfo && (
              <>
                <div>|</div>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setNotificationCount(0);
                    handleNavClick("notification", "/notification");
                  }}
                  className={classes.miniToolbar_Content}
                >
                  <Badge
                    color="error"
                    badgeContent={notificationCount ? notificationCount : null}
                  >
                    <i aria-hidden="true"></i>
                    <i className="fa fa-bell-o" aria-hidden="true"></i> &nbsp;
                    Notification &nbsp;
                  </Badge>{" "}
                </span>
              </>
            )}

            {/* <a href="/faq">
                  <i className="fa fa-question-circle" aria-hidden="true"></i>{" "}
                  Help
      </a>*/}
          </div>
        </Toolbar>
        <Toolbar className={classes.mainToolbar}>
          <div className={classes.mainToolbar_LeftNav} >
            <div style={{
              marginBottom:"15px"
            }}>
            <HyperImageLink
              redirect
              source={ShopiAdsLogo}
              className="py-2 py-md-0 mb-3"
              alt="LOGO"
              url={
                localStorage.getItem("connectSite") === "CA"
                  ? environment.CANADA_BASE_URL
                  : environment.USA_BASE_URL
              }
            
            />
            </div>
            <div>
            <h4>ShopiAds</h4>
            </div>
            <Typography className="hideinmobile">
              <Nav.Link
                className={
                  (localStorage.getItem("mode") === "offers" ||
                    pathName.includes("offers")) &&
                  "activatedLink"
                }
                onClick={() => handleNavClick("offers", "/offers/Trending")}
              >
                Offers
              </Nav.Link>
              <Nav.Link
                className={
                  localStorage.getItem("mode") === "stores" &&
                  !pathName.includes("offers") &&
                  "activatedLink"
                }
                onClick={() => handleNavClick("stores", "/stores/Trending")}
              >
                Stores
              </Nav.Link>
            </Typography>
          </div>
          <div className={classes.mainToolbar_MiddleNav}>
            <div className={classes.search}>
              <CustomSelect
                className="cat-search"
                value={selectedCat}
                defaultValue={"all"}
                onChange={getCatChange}
              >
                <CustomerSelectOption value="all">All</CustomerSelectOption>
                {mainCategoryList.map((item, index) => (
                  <CustomerSelectOption
                    key={index}
                    value={item.id}
                    selected={selectedCat === item.id}
                  >
                    {item.name}
                  </CustomerSelectOption>
                ))}
              </CustomSelect>
              <div
                onClick={(e) => {
                  handleSearchClick("/search");
                }}
                className={classes.searchIcon}
              >
                <SearchIcon />
              </div>
              <Autocomplete
                // open={isAutoCompleteOpen}
                // onOpen={setIsAutoCompleteOpen}
                // onClose={setIsAutoCompleteOpen}
                value={searchText}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "100%",
                }}
                id="free-solo-demo"
                freeSolo
                filterOptions={(x) => x}
                onChange={(event, newValue, reason) => {
                  if (reason === "select-option") {
                    // setIsAutoCompleteOpen(false)
                    handleAutoSearch("/search", newValue);
                  }
                }}
                ListboxProps={{
                  style: {
                    zIndex: 20,
                    width: "100%",
                  },
                }}
                options={productionSuggest.map((option) => option?.name)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      classes: { input: classes.inputInputPlaceholder },
                      disableUnderline: true,
                      style: { width: "100%", padding: 0 },
                      endAdornment: searchText && (
                        <div
                          className={classes.searchIcon}
                          onClick={() => {
                            setProductionSuggest([]);
                            setSearchText("");
                          }}
                        >
                          <Close {...params.InputProps.endAdornment} />
                        </div>
                      ),
                    }}
                    placeholder={`Green goods for a greener future`}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setIsAutoCompleteOpen(false);
                      }
                      setSearchText(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      _handleKeyDown(e, params);
                    }}
                    classes={{
                      input: classes.inputInput,
                    }}
                    style={{ width: "100%" }}
                    label=""
                    value={searchText}
                  />
                )}
              />
            </div>
          </div>
          <div className={classes.mainToolbar_RightNav}>
            {userInfo ? (
              <>
                <div className="d-flex align-items-center">
                  <div
                    className={activeLink === "follow" && "activatedLink"}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleNavClick("follow", "/follow")}
                  >
                    <FontAwesomeIcon icon={faStore} />
                    <b>Follow</b>
                  </div>
                  <div style={{ paddingLeft: "10px" }}>|</div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => handleNavClick("snips", "/snips")}
                  >
                    {allSnips !== undefined ? (
                      <Badge
                        className={activeLink === "snips" && "activatedLink"}
                        badgeContent={
                          allSnips !== undefined
                            ? parseInt(allSnips.length)
                            : ""
                        }
                        color="error"
                      >
                        <div style={{ paddingLeft: "5px" }}>
                          <ListAlt />
                          <b style={{ paddingLeft: "5px" }}>My List</b>
                        </div>
                      </Badge>
                    ) : (
                      <Badge color="error">
                        <ListAlt />
                        <b style={{ paddingLeft: "5px" }}>My List</b>
                      </Badge>
                    )}
                  </div>
                  <div style={{ padding: "0px 12px" }}>|</div>
                  <UserMenu />
                </div>
              </>
            ) : (
              <Button
                style={{ color: "white" }}
                onClick={() => handleOpenForm("login")}
              >
                Login / Sign Up
              </Button>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <LoginForm
        handleOpen={handleOpenForm}
        handleClose={handleCloseForm}
        open={openForm.login}
      />
      <RegisterForm
        handleOpen={handleOpenForm}
        handleClose={handleCloseForm}
        open={openForm.register}
      />
      <ForgotPasswordForm
        handleOpen={handleOpenForm}
        handleClose={handleCloseForm}
        open={openForm.forgotPassword}
      />
      <LocationForm
        handleOpen={handleOpenForm}
        handleClose={handleCloseForm}
        open={openForm.location}
      />
      <UnRegisterUser
        handleOpen={handleOpenForm}
        handleClose={handleCloseForm}
        open={openForm.unRegisterUser}
      />
      <ToastContainer />
    </>
  );
}

const UserMenu = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { userInfo, logout } = useAuth();
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const history = useHistory();
  const doLogout = async () => {
    try {
      await logout();
      setAnchorEl(null);
      history.replace("/offers/New");
    } catch {
      console.log("Error in Logging Out");
    }
  };
  const profile = async () => {
    try {
      setAnchorEl(null);
      history.replace("/profile");
    } catch {
      console.log("Error in Logging Out");
    }
  };
  return (
    <>
      {/* <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        style={{ color: "white" }}
      >
        <i className="fa fa-user" aria-hidden="true"></i>
      </IconButton> */}
      <img
        src={Profile}
        className={classes.userIcon}
        onClick={handleMenu}
        alt="PROFILE"
      />
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        onClose={handleCloseMenu}
      >
        <MenuItem title={userInfo.email}>
          {userInfo.displayName ? userInfo.displayName : userInfo.email}
        </MenuItem>
        <MenuItem onClick={profile} title={userInfo.email}>
          My Profile
        </MenuItem>
        {/*<MenuItem>Account Settings</MenuItem>
         */}
        <MenuItem onClick={doLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  "@global": {
    '.MuiAutocomplete-option[data-focus="true"]': {
      background: "#ccc",
    },
  },
  appBar: {
    backgroundColor: COLORS.primary.main,
    padding: "0 4%",
  },
  miniToolbar: {
    minHeight: "35px",
    display: "flex",
    justifyContent: "space-between",
    letterSpacing: "0.24px",
    "@media (max-width: 576px)": {
      display: "none",
    },
  },
  miniToolbar_LeftNav: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& a": {
      cursor: "pointer",
      fontSize: "12px",
      paddingRight: "10px",
      color: "white",
      textDecoration: "none",
    },
  },
  miniToolbar_socialContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      cursor: "pointer",
      marginLeft: "10px",
      width: "20px",
    },
  },
  miniToolbar_RightNav: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& a": {
      cursor: "pointer",
      fontSize: "12px",
      color: "white",
      textDecoration: "none",
      paddingLeft: "10px",
    },
  },
  miniToolbar_Content: {
    cursor: "pointer",
    fontSize: "12px",
    padding: "0 10px",
    color: "white",
    textDecoration: "none",
  },
  mainToolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  mainToolbar_LeftNav: {
    zIndex: 10,
    display: "flex",
    alignItems: "center",
    "& img": {
      height: "56px",
      width: "60px",
      marginLeft: "-9px",
      marginRight: "5px",
    },
    "& h4": {
      padding: "10px 30px 0px 0px",
      letterSpacing: "1.5px",
      color: "#fff",
    },
    "& p": {
      display: "flex",
      alignItems: "center",
      paddingLeft: "30px",
      "& a": {
        fontWeight: "bold",
        cursor: "pointer",
        padding: "5px 15px",
        color: "white",
        textDecoration: "none",
        "&:hover": {
          color: "whitesmoke",
        },
      },
    },
  },
  mainToolbar_MiddleNav: {
    zIndex: 4,
    width: "100%",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 576px)": {
      display: "none",
    },
  },
  mainToolbar_RightNav: {
    zIndex: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 576px)": {
      display: "none",
    },
    "& div": {
      "& div": {
        "& b": {},
      },
    },
    "& button": {},
  },
  search: {
    zIndex: 6,
    backgroundColor: "white",
    height: "42px",
    // padding: "5px",
    width:
      window.innerWidth > 1300 ? window.innerWidth / 3 : window.innerWidth / 4,
    marginLeft: window.innerWidth > 1300 ? 0 : 150,
    borderRadius: "5px",
    position: "relative",
    display: "flex",
  },
  searchIcon: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    padding: "5px 5px 5px 12px",
    "& svg": {
      fill: "#9A9A9A",
    },
  },
  inputInput: {
    padding: "5px 10px",
    width: "350px",
  },
  userIcon: {
    width: "30px",
    borderRadius: "50%",
  },
  inputInputPlaceholder: {
    "&::placeholder": {
      color: "green",
      opacity: "1 !important",
    },
  },
}));

export default Header;
