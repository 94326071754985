import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, makeStyles, Tab, Tabs } from "@material-ui/core";
import { useHistory, useRouteMatch } from "react-router-dom";
import { getUserSnips } from "../../../Redux/Snips/SnipsAction";
import { useAuth } from "../../../Utils/AuthContext";
import API from "../../../Utils/API";
import {
  getStoresAllProduct,
  getStoresByCategory,
  getStoresProductByCategory,
} from "../../../Redux/Stores/StoresActions";
import ProductCard from "./ProductCard";
import ReactPaginate from "react-paginate";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { getDomainCountryCode } from "../../../service/location";
import { COLORS } from "../../../Utils/context/ThemeContext";
const leftPaginationArrow = (
  <ArrowBackIosIcon style={{ margin: 0, marginLeft: 8, color: COLORS.primary.main }} />
);

const rightPaginationArrow = (
  <ArrowForwardIosIcon style={{ margin: 0, color: COLORS.primary.main }} />
);
;
const style = {
  backButton: {
    fontSize: "25px",
    marginBottom: "10px",
    marginLeft: "12px",
    color: COLORS.primary.main,
    cursor: "pointer",
  },
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    "& button": {
      "& .MuiTab-wrapper": {
        flexDirection: "row !important",
        justifyContent: "left !important",
      },
    },
  },
}));

function AllProducts() {
  const classes = useStyles();
  const { userInfo } = useAuth();
  const { path, params } = useRouteMatch();
  const currPath = path.split(":")[0];
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    userId,
    cityId,
    stateId,
    marketPlace: selectedRefMarketPlace,
  } = useSelector((state) => state.location);
  const { loading, productData } = useSelector((state) => state.stores);
  const [categories, setCategories] = useState([]);
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(productData?.totalPages || 0);
  const [recordPerPage, setRecordPerPage] = useState(32);
  const { allStoreList } = useSelector((state) => state.stores);
  const { allLikes } = useSelector((state) => state.snips);
  const [index, setIndex] = useState(0);
  const [value, setValue] = useState(0);
  const [mainCategoryList, setMainCategoryList] = useState([]);
  const [allCategoriesArray, setAllCategoriesArray] = useState([]);
  const [loginForm, setLoginForm] = useState(false);
  const [likesLoading, setLikesLoading] = useState(false);
  const [likes, setLikes] = useState(0);
  const [selectedTabName, setSetselectedTabName] = useState(params.tabName);
  console.log({params})
  const loggedInUser =
    userInfo !== null && userInfo !== undefined ? userInfo.email : "gust";
  const getMainCategoryList = async () => {
    const categoryResponse = await API.getMainCategories(loggedInUser);
    if (categoryResponse && categoryResponse.length > 0) {
      setCategories(categoryResponse);
      const categoryNames = [
        "Trending",
        ...categoryResponse.map((category) => category.name),
      ];
      const selectedCategoryInfo = categoryResponse.find(item => item.id === params.tabName || item.name === params.tabName);
      setSetselectedTabName(selectedCategoryInfo?.name|| "Trending")
      setAllCategoriesArray(categoryNames);
      getCategoryProducts(params.tabName,categoryResponse)
    }
  };
  const getCategoryProducts = async (name, categoryList) => {
    if (name === "Trending") {
        const searchQuery = API.generateQueryString({
          user: API.encodedUserId(userInfo != null && userInfo !== undefined ? userInfo.email : "gust"),
          cityId,
          stateId,
          viewType: "offer",
          marketPlace: selectedRefMarketPlace,
          pageNo: 0,
          recordPerPage,
          orderBy: "createdOn",
          asc:true
        });
        dispatch(getStoresAllProduct(searchQuery));
      } else {
      let selectedCategory = categoryList.find(category => category.name === name || category.id === name);
        dispatch(
          getStoresProductByCategory({
            user: API.encodedUserId(userInfo != null && userInfo !== undefined ? userInfo.email : "gust"),
            categoryId: selectedCategory.id,
            cityId,
            stateId,
            viewType: "offer",
            marketPlace: selectedRefMarketPlace,
            pageNo: 0,
            recordPerPage,
            orderBy: "createdOn",
            asc:false
          })
        );
    }
    setCurrentPage(0);
  }
  useEffect(() => {
    getMainCategoryList();
  }, []);
  useEffect(() => {
    // dispatch(getUserSnips(userInfo?.email, "like"));
    if (categories.length > 0) {
      getCategoryProducts(selectedTabName,categories)
    } else {
      getMainCategoryList()
    }
  }, [selectedTabName]);


  useEffect(() => { 
    console.log({productData})
    if (productData?.items) {
      setItems(productData.items)
      setTotalPages(productData.totalPages) 
    } else {
      setItems([])
      setTotalPages(0) 
    }
  }, [productData])
  const fetchProductsyPageId = async (pageId) => {
    if (selectedTabName === "Trending") {
        const searchQuery = API.generateQueryString({
          user: API.encodedUserId(userInfo != null && userInfo !== undefined ? userInfo.email : "gust"),
          cityId,
          stateId,
          viewType: "offer",
          marketPlace: selectedRefMarketPlace,
          pageNo: pageId,
          recordPerPage,
          orderBy: "createdOn",
          asc: getDomainCountryCode() === "CA" ? true : false
        });
        dispatch(getStoresAllProduct(searchQuery));
      } else {
      let selectedCategory = categories.find(category => category.name === selectedTabName);
        dispatch(
          getStoresProductByCategory({
            user: API.encodedUserId(userInfo != null && userInfo !== undefined ? userInfo.email : "gust"),
            categoryId: selectedCategory.id,
            cityId,
            stateId,
            viewType: "offer",
            marketPlace: selectedRefMarketPlace,
            pageNo: pageId,
            recordPerPage,
            orderBy: "createdOn",
            asc:false
          })
        );
    }
    setCurrentPage(pageId);
    window.scrollTo(0, 0);
  };
  const cardClick = (newValue, productId) => {
    history.push(`/productDetail/` + newValue + "/" + productId);
    // console.log("Under Development..............")
  };

  const handleChange = (event, newValue) => {
    // const tabName = event.target.innerText;
    // setValue(newValue);

    // if (tabName !== "TRENDING" && tabName !== "NEW") {
    //   dispatch(
    //     getStoresProductByCategory({
    //       user: userInfo?.email || userId,
    //       categoryId: mainCategoryList[newValue - 1].id,
    //       cityId,
    //       stateId,
    //       viewType: "offer",
    //       marketPlace: selectedRefMarketPlace,
    //       pageNo: 0,
    //       recordPerPage,
    //     })
    //   );
    // } else {
    //   dispatch(
    //     getAllStores(
    //       userInfo?.email || userId,
    //       cityId,
    //       stateId,
    //       "offer",
    //       selectedRefMarketPlace
    //     )
    //   );
    //   const searchQuery = API.generateQueryString({
    //     user: userInfo?.email || userId,
    //     cityId,
    //     stateId,
    //     viewType: "offer",
    //     marketPlace: selectedRefMarketPlace,
    //     pageNo: 0,
    //     recordPerPage,
    //     // TODO: ORDERBY, ASC
    //   });
    //   dispatch(getStoresAllProduct(searchQuery));
    //   dispatch(
    //     getActiveSectionItem(
    //       userInfo?.email || userId,
    //       "Trending",
    //       cityId,
    //       stateId,
    //       "offer",
    //       selectedRefMarketPlace
    //     )
    //   );
    // }
  };

  const handleMenuChange = (newValue, index) => {
    //const tabName = event.target.innerText;
    history.push(`${currPath}${newValue}`);
    setValue(newValue);
    // setSelectedIndex(index);
    var CatId = "";
    mainCategoryList.map((item, index) => {
      if (item.name == newValue) CatId = item.id;
    });

    dispatch(
      getStoresByCategory(
        userInfo?.email || userId,
        CatId,
        cityId,
        stateId,
        "offer"
      )
    );
    dispatch(
      getStoresProductByCategory({
        user: API.encodedUserId(userInfo != null && userInfo !== undefined ? userInfo.email : "gust"),
        categoryId: CatId,
        cityId,
        stateId,
        viewType: "offer",
        marketPlace: selectedRefMarketPlace,
        pageNo: 0,
        recordPerPage,
        orderBy: "createdOn",
        asc:false
      })
    );
  };

  const renderStoreName = (newValue) => {
    let storeName = "";
    if (allStoreList !== undefined && allStoreList.length > 0) {
      const obj = allStoreList.filter((item) => item.id === newValue);
      if (obj !== undefined && obj.length > 0) storeName = obj[0].storeName;
    }
    return <div>{storeName}</div>;
  };

  const makeLikes = (item) => {
    // history.push(`/storeDetails/` + newValue);
    if (!userInfo?.email) setLoginForm(true);
    else {
      setLikesLoading(true);
      if (
        allLikes !== undefined &&
        allLikes.filter((e) => e.type === "Product") &&
        allLikes.filter((e) => e.productId === item.id).length === 0 &&
        !loading &&
        !likesLoading
      ) {
        API.makeLikes(userInfo?.email, item.id, "product")
          .then((result1) => {
            console.log("makeLikes>>>>>>>>>>>>>>>", result1);
            if (result1 != undefined) {
              // setOffer(result1)
              let totalLikes = item.totalLikes + 1;
              setLikes(totalLikes);
              dispatch(getUserSnips(userInfo?.email, "like"));
              setLikesLoading(false);
              // setLoading(false);
            } else {
              // setOffer("")
              setLikesLoading(false);
              // setLoading(false);
            }
          })
          .catch((error) => {
            // setLoading(false);
            console.log(error);
            setLikesLoading(false);
            // console.log(error);
          });
      } else {
        const offerId =
          allLikes !== undefined &&
          allLikes.filter((e) => e.productId === item.id)[0].id;
        API.makeUnLikes(userInfo?.email, item.id, "product", offerId)
          .then((result) => {
            console.log("makeUnLikes>>>>>>>>>>>>>>>", result);
            if (result !== undefined) {
              // setOffer(result1)
              let totalLikes = item.totalLikes;
              setLikes(totalLikes);
              dispatch(getUserSnips(userInfo?.email, "like"));
              setLikesLoading(false);
              // setLoading(false);
            } else {
              // setOffer("")
              setLikesLoading(false);
              // setLoading(false);
            }
          })
          .catch((error) => {
            // setLoading(false);
            console.log(error);
            setLikesLoading(false);
          });
        // alert("already liked this offer thanks.")
        // setLikesLoading(false);
      }
    }
    // console.log("Under Development..............")
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    fetchProductsyPageId(selectedPage);
  };
  return (
    <>
      <Grid container className="mt-3">
        <Grid item xs={2}>
          <div className="menu-tab">
            <i
              style={{ ...style.backButton, position: "relative" }}
              className="fa fa-arrow-circle-left"
              aria-hidden="true"
              title="Back to Login"
              onClick={() =>
                history.push(`/offers/${window.location.href.split("/")[5]}`)
              }
            ></i>
            <Tabs
              value={allCategoriesArray.indexOf(selectedTabName)}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="off"
              className={classes.tabs}
              orientation="vertical"
            >
              {allCategoriesArray.map((item, index) => (
                <Tab
                  {...a11yProps(index)}
                  label={item}
                  key={item}
                  style={{ minWidth: "120px", textAlign: "left" }}
                  onClick={() => {
                    const selectedCategory = categories.find((e) => e.name === item)
                    if (selectedCategory) {
                      setSetselectedTabName(selectedCategory.name)
                    } else {
                      setSetselectedTabName("Trending")
                    }
                    history.replace(`/AllProducts/FeaturedDeals/${selectedCategory?.name || "Trending"}`)
                  }}
                  wrapped
                />
              ))}
            </Tabs>
          </div>
        </Grid>
        <Grid item xs={10}>
          <h4 className="ml-5">
            <strong>
              {params.offerName === "FeaturedDeals" && "Featured Deals"}
            </strong>
          </h4>
          <Grid container className="ml-5">
            {items.length > 0 ? (
              items.map((item, index) => (
                <div style={{ width: "250px" }}>
                  <ProductCard
                    branchName={item.storeName}
                    storeId={item.storeId}
                    productId={item.id}
                    img={item.images}
                    branchLogo={item.logo}
                    heading={item.heading}
                    avgRating={item.avgRating}
                    discount={item.discountPerc}
                    actualPrice={item.actualPrice}
                    offerPrice={item.offerPrice}
                    totalLikes={item.totalLikes}
                    key={item.id}
                  />
                </div>
              ))
            ) : (
              <h4>Coming up soon in future in {selectedTabName}</h4>
            )}
          </Grid>
        </Grid>
        {totalPages > 1 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ReactPaginate 
              forcePage={currentPage}
              pageCount={totalPages}
              pageRangeDisplayed={3} // Number of pages to display in the pagination
              marginPagesDisplayed={1} // Number of pages to display on the edges
              previousLabel={leftPaginationArrow}
              nextLabel={rightPaginationArrow}
              breakLabel={"..."}
              containerClassName={"pagination"}
              activeClassName={"active"}
              disabledClassName={"disabled"}
              onPageChange={handlePageClick}
            />
          </div>
        )}
      </Grid>
    </>
  );
}

export default AllProducts;
