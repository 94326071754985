import React, { useEffect, useState } from "react";
import "../Container.css";
import { useAuth } from "../../../Utils/AuthContext";
import { useSelector, useDispatch } from "react-redux";
import { getAllStores } from "../../../Redux/Stores/StoresActions";
import StoresHeader from "../Stores/StoresHeader";
import Card from "react-bootstrap/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import API from "../../../Utils/API";
import { useHistory, useRouteMatch } from "react-router-dom";
import RatingForm from "../../DialogBox/RatingForm";
import LoginForm from "../../DialogBox/LoginForm";
import ProductCard from "./ProductCard";
import { Breadcrumbs, Select } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ReactPaginate from 'react-paginate';
import {
  Breadcrumb,
} from "antd";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CustomerSelectOption from "../../UI/Input/CustomerSelectOption";
import CustomSelect from "../../UI/Container/CustomSelect";
import { COLORS } from "../../../Utils/context/ThemeContext";

const style = {
  backButton: {
    fontSize: "25px",
    marginBottom: "10px",
    color: COLORS.primary.main,
    cursor: "pointer",
    marginTop: "5px",
    marginRight: "20px",
  },
  tooltipIcon: {
    color: COLORS.primary.main,
  },
};
const leftPaginationArrow = (
  <ArrowBackIosIcon style={{ margin: 0, marginLeft: 8, color: COLORS.primary.main }} />
);

const rightPaginationArrow = (
  <ArrowForwardIosIcon style={{ margin: 0, color: COLORS.primary.main }} />
);

const filterColumns = [
  { value: "Relevance", key: "relevance,true" },
  { value: "Price : Low to High", key: "offerPrice,true" },
  { value: "Price : High to Low", key: "offerPrice,false" },
  { value: "Rating", key: "avgRating,false" },
  // { value: "Rating: Descending", key: "avgRating,false" },
];
function Product() {
  const { userInfo } = useAuth();
  const { loading, allStores, categoryWiseStores } = useSelector(
    (state) => state.stores
  );
  const { marketPlace: selectedRefMarketPlace } = useSelector(
    (state) => state.location
  );
  const { path, params } = useRouteMatch();
  const [productList, setProductList] = useState([]);
  const [mainCategory, setMainCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(32);
  const [pageCount, setPageCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [searchTotalResult, setSearchTotalResult] = useState();
  const [categoryHirarchy, setCategoryHirarchy] = useState([])
  const [orderByColumnName, setOrderByColumnName] = useState("relevance,true");

  const history = useHistory();
  const dispatch = useDispatch();
  const fetchProductDetails = async () => {
    const userID = userInfo != null && userInfo !== undefined ? userInfo.email : "gust";
    const subCategoryId = params.tabName;
    // const productDetails = await API.productByCategory("gust", subCategoryId, cityId, selectedRefMarketPlace, "all")
    const {
      content,
      totalPages,
      totalElements,
      pageable: { pageNumber },
    } = await API.productByCategoryNew({
      // TODO: provided default user name, can be removed from the backend
      userId: API.encodedUserId("gust"),
      categoryId: subCategoryId,
      marketPlace: selectedRefMarketPlace,
      pageId: 0,
      recordPerPage: perPage,
      orderBy: orderByColumnName.split(",")[0],
      asc: orderByColumnName.split(",")[1] || "true",
    }).catch(err => console.log(err));
    if (content.length > 0) {
      setSearchTotalResult(totalElements);
      setPageCount(totalPages)
    }
    setCurrentPage(pageNumber);
    setProductList(content)
    const subCategoryReponse = await API.getCategoriesById(userID, subCategoryId);
    const catArray = [];
    let isRoot = false,fetchingCAtegoryId = subCategoryReponse.maincategories[0];
    while (!isRoot) {
      const mainCategoryResponse = await API.getCategoriesById(userID, fetchingCAtegoryId)
      catArray.push({name:mainCategoryResponse.name,id:mainCategoryResponse.id})
      if (mainCategoryResponse.root === "N") {
        fetchingCAtegoryId = mainCategoryResponse.maincategories[0]
      } else {
        isRoot = true;
      }
    }
    setCategoryHirarchy(catArray);
    setSubCategory(subCategoryReponse.name)
  }
  useEffect(() => {
    fetchProductDetails();
  }, [params.tabName, orderByColumnName]);
  const handlePageClick = async (e) => {
    const selectedPage = e.selected;
    const subCategoryId = params.tabName;
    // const productDetails = await API.productByCategory("gust", subCategoryId, cityId, selectedRefMarketPlace, "all")
    const {
      content,
      totalPages,
      totalElements,
      pageable: { pageNumber },
    } = await API.productByCategoryNew({
      // TODO: provided default user name, can be removed from the backend
      userId: API.encodedUserId("gust"),
      categoryId: subCategoryId,
      marketPlace: selectedRefMarketPlace,
      pageId: selectedPage,
      recordPerPage: perPage,
      orderBy: orderByColumnName.split(",")[0],
      asc: orderByColumnName.split(",")[1],
    });
    if (content.length > 0) {
      setProductList(content)
      setSearchTotalResult(totalElements);
      setPageCount(totalPages)
    }
    setCurrentPage(pageNumber);
    window.scrollTo(0,0)
  };

  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }

  console.log({ "window.history.state": filterColumns });
  return (
    <>
      {!loading ? (
        <>
          <StoresHeader noHeader={true} />
          <div className="navigator" style={{ marginTop: "10px" }}>
            <i
              style={{ ...style.backButton, position: "relative" }}
              className="fa fa-arrow-circle-left"
              aria-hidden="true"
              title="Back to Home"
              onClick={() => {
                const previous = window.history.state.state.previous;
                history.push(
                  `/${previous ? previous : "offers"}/${
                    categoryHirarchy[categoryHirarchy.length - 1].name
                  }`
                );
              }}
            ></i>
            <Breadcrumb
              separator={""}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {[...categoryHirarchy].reverse().map((item, index) => (
                <>
                  <Breadcrumb.Item>
                    <span className={"cu-breadcrumb-item ml-2"}>
                      {item.name}
                    </span>
                  </Breadcrumb.Item>

                  <Breadcrumb.Item>
                    <i
                      class="fa fa-arrow-right"
                      style={{ position: "relative", marginLeft: "5px" }}
                      aria-hidden="true"
                    ></i>
                  </Breadcrumb.Item>
                </>
              ))}
              <Breadcrumb.Item>
                <span className={"cu-breadcrumb-item ml-2"}>
                  {mainCategory}
                </span>
              </Breadcrumb.Item>

              <Breadcrumb.Item>
                <span className={"cu-breadcrumb-item ml-2"}>{subCategory}</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="container-fluid">
            <div
              className="sort-options"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "row-reverse",
                margin: "10px",
                marginRight: "15px",
              }}
            >
              <CustomSelect
                value={orderByColumnName}
                onChange={(e) => setOrderByColumnName(e.target.value)}
                className="form-control"
                style={{ width: "15%" }}
              >
                {filterColumns.map((item) => (
                  <CustomerSelectOption
                    selected={item.key === orderByColumnName}
                    value={`${item.key}`}
                  >
                    {item.value}
                  </CustomerSelectOption>
                ))}
              </CustomSelect>
              <span className="shopee-sort-bar__label">Sort by</span>
            </div>
            <div className="row" style={{ paddingLeft: "20%" }}>
              {productList.length > 0 ? (
                productList.map((item) => (
                  <div className="col-md-3">
                    <div className="offer-card-main">
                      <ProductCard
                        branchName={item.storeName}
                        storeId={item.storeId}
                        productId={item.id}
                        img={item.images}
                        branchLogo={item.logo}
                        heading={item.heading}
                        avgRating={item.avgRating}
                        discount={item.discountPerc}
                        actualPrice={item.actualPrice}
                        offerPrice={item.offerPrice}
                        totalLikes={item.totalLikes}
                        key={item.id}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <h1 style={{ marginTop: "20px" }}>Coming up soon in future!</h1>
              )}
            </div>
          </div>
        </>
      ) : (
        <strong>Loading ....</strong>
      )}
      {pageCount > 1 && (
        <ReactPaginate
          forcePage={currentPage}
          pageCount={pageCount}
          pageRangeDisplayed={3} // Number of pages to display in the pagination
          marginPagesDisplayed={1} // Number of pages to display on the edges
          previousLabel={leftPaginationArrow}
          nextLabel={rightPaginationArrow}
          breakLabel={"..."}
          containerClassName={"pagination"}
          activeClassName={"active"}
          disabledClassName={"disabled"}
          onPageChange={handlePageClick}
        />
      )}
    </>
  );
}

export default Product;
