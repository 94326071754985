import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRouteMatch, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  setSnipsData,
} from "../../Redux/Snips/SnipsAction";
import API from "../../Utils/API";
import { useAuth } from "../../Utils/AuthContext";
import Checkbox from "@material-ui/core/Checkbox";
import { Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ListAlt } from "@material-ui/icons";
import { EntypoShoppingBag } from "../../Assets/Icons";
import { COLORS } from "../../Utils/context/ThemeContext";

function Snips() {
  const { loading, allSnips } = useSelector((state) => state.snips);
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const { userInfo } = useAuth();
  var selectedSnip = [];

  const [checked, setChecked] = React.useState(false);
  const [active, setActive] = React.useState(true);
  const [allItemsChecked, setAllItemsChecked] = React.useState(false);
  const [checkedItems, setCheckedItems] = React.useState(false);
  const [expiredItems, setExpiredItems] = React.useState(false);
  const [snipByStore, setSnipByStore] = useState([]);
  const [selectedOfferSnip, setSelectedOfferSnip] = useState([]);
  const [allItemsSnips, setAllItemsSnips] = useState([]);
  const [allItemsSnipsBuy, setAllItemsSnipsBuy] = useState([]);
  const [allExpiredSnips, setAllExpiredSnips] = useState([]);
  const [scrolled, setScrolled] = React.useState(false);
  const [data, setData] = React.useState("");
  const [selectedQuantity, setSelectedQuantity] = React.useState(0);

  const groupBy = (key) => (array) => {
    let objectsByKeyValue = {}

    array.filter((elem) => {
      let storename = elem[key];
      let branchName = elem["storeCode"];
      if( branchName == "Primary") {
       return objectsByKeyValue[storename] = (objectsByKeyValue[storename] || []).concat(elem);
      } else {
        return objectsByKeyValue[`${storename} (${branchName})`] = (objectsByKeyValue[`${storename} (${branchName})`] || []).concat(elem);
      }
    });
  return objectsByKeyValue;
};

  const groupByStore = groupBy("storeName");

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const removeSnips = (item) => {
    setActive(true);
    // setChecked(false);
    if (allItemsChecked) {
      setAllItemsChecked(false);
      API.deleteSnips(userInfo?.email, allItemsSnips);
      setSnipByStore([]);
      setAllItemsSnipsBuy([])
      // dispatch(removeSnipsData(allItemsSnips, allSnips));
    } else if (checkedItems) {
      setCheckedItems(false);
      API.deleteSnips(userInfo?.email, allItemsSnips)
        .then((result1) => {
          if (result1 !== undefined && result1.length > 0) {
            setAllItemsSnips([]);
            setAllItemsSnipsBuy([])
            setTimeout(getSnipData(), 500);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // dispatch(removeSnipsData(allItemsChecked, allSnips));
    } else if (expiredItems) {
      setExpiredItems(false);
      API.deleteSnips(userInfo?.email, allItemsSnips)
        .then((result1) => {
          if (result1 !== undefined && result1.length > 0) {
            setAllItemsSnips([]);
            setAllItemsSnipsBuy([])
            setTimeout(getSnipData(), 500);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    var checkboxes = document.querySelectorAll(
      "input[name=catCheckBox]:checked"
    );
    if (checkboxes && checkboxes.length > 0) {
      for (var i = 0; i < checkboxes.length; i++) {
        // And stick the checked ones onto an array...
        if (checkboxes[i].checked) {
          checkboxes[i].checked = false;
        }
      }
    }
  };



 


  const getSnipData = () => {
    API.getUserSnips(userInfo?.email, "snip")
      .then((result1) => {
        if (result1 !== undefined) {
          dispatch(setSnipsData(result1));
          setSnipByStore({
            snipByStoreGroup: groupByStore(result1),
          });
          setData(result1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (userInfo)
      API.getUserSnips(userInfo?.email, "snip")
        .then((result1) => {
          if (result1 !== undefined) {
            dispatch(setSnipsData(result1));
            setSnipByStore({
              snipByStoreGroup: groupByStore(result1),
            });
            console.log(result1);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    window.addEventListener("scroll", handleScroll);
  }, []);

  // console.log("group by store",snipByStore["Branch Location"]);
  //JSON.stringify(groupByStore(allSnips));
  // console.log("snip by store>>>>>>>>>>>>",snipByStore);
  /*console.log("json bvalue>>>>>>>>>>>>>>>>>",
      JSON.stringify({
        carsByBrand: groupByStore(allSnips),
      }, null, 2)
    );
  snipByStore["Branch Location"].map((itemMap, index) => {
console.log("item>>>>>>>>>>>>>>>>>>>>>",itemMap)

  });*/

  //const jsonParsedArray = JSON.parse(snipByStore);
  console.log("snipByStore>>>>>>>>>>>>>>>>>>", snipByStore);
  /*if(snipByStore["snipByStoreGroup"]!=undefined)
{
 snipByStore["snipByStoreGroup"].map((item, index)  => {
    console.log("itemMap>>>>>>>>>>>>>>>>>>",item);

  });
}*/
  if (
    snipByStore["snipByStoreGroup"] != undefined &&
    snipByStore["snipByStoreGroup"] != null
  ) {
    Object.keys(snipByStore["snipByStoreGroup"]).map(function (movie, i) {
      snipByStore["snipByStoreGroup"][movie].map((item, index) => {});
    });
  }

  const handleStoreLevel = (e, id) => {
    if (e.target.checked) {
    }
  };

  const handleOfferLevel = (e, item, index) => {
    if (e.target.checked) {
      // setChecked(true);
      // setId(item.id);
      selectedSnip.push(item, ...selectedOfferSnip);
      console.log("checked selectedSnip>>>>>>>>>>>>>",selectedSnip)
      setSelectedOfferSnip(selectedSnip);
      setAllItemsSnips(selectedSnip);
      setAllItemsSnipsBuy(selectedSnip);
    }
    else
    {
      console.log("unchecked call>>>>>>>>>>>>>",item.id)
     let newSnip = selectedOfferSnip.filter(function( obj ) {
        return obj.id !== item.id;
      });
      console.log("checked newSnip>>>>>>>>>>>>>",newSnip)
      setSelectedOfferSnip(newSnip);
      setAllItemsSnips(newSnip);
      setAllItemsSnipsBuy(newSnip);
    }
  };

  const handleAllItems = (e) => {
    const setAllItemSnips = [];
    if (e.target.checked) {
      setAllItemsChecked(true);
      setActive(false);
      Object.keys(snipByStore["snipByStoreGroup"]).length > 0 &&
        Object.keys(snipByStore["snipByStoreGroup"]).map((itemKey, index) => {
          snipByStore["snipByStoreGroup"][itemKey].length > 0 &&
            snipByStore["snipByStoreGroup"][itemKey].map((item, index) => {
              setAllItemSnips.push(item);
            });
        });
      setAllItemsSnips(setAllItemSnips);
    } else {
      setAllItemsChecked(false);
      setActive(true);
    }
  };

  const handleCheckedItems = (e) => {
    console.log(selectedOfferSnip);
    if (e.target.checked) {
      setCheckedItems(true);
      setActive(false);
      setAllItemsSnips(selectedOfferSnip);
    } else {
      setCheckedItems(false);
      setActive(true);
    }
  };

  const handleExpiredItems = (e) => {
    if (e.target.checked) {
      setExpiredItems(true);
      setActive(false);
      const setExpiredItemSnips = [];
      Object.keys(snipByStore["snipByStoreGroup"]).length > 0 &&
        Object.keys(snipByStore["snipByStoreGroup"]).map((itemKey, index) => {
          snipByStore["snipByStoreGroup"][itemKey].length > 0 &&
            snipByStore["snipByStoreGroup"][itemKey].map((item, index) => {
              if (item.isExpire === "Y") {
                setExpiredItemSnips.push(item);
              }
            });
        });
        debugger;
      setAllItemsSnips(setExpiredItemSnips);
    } else {
      setExpiredItems(false);
      setActive(true);
    }
  };

  let x = ["col-md-10 mx-auto all-checkboxes"];
  if (scrolled) {
    x.push("scrolled");
  }

  const handleBuy = async (productId, vendorUrl,isShopifyProduct,varientId,storeId) => {
    console.log("store storeId>>>>>>>>>>>>",storeId);
    console.log("isShopifyProduct >>>>>>>>>>>>",isShopifyProduct);
    API.productBuyNow(
      userInfo?.email,
      productId
    );
    if(isShopifyProduct=="Y")
      {
      API.getStoreById(storeId) .then(async (storeData) =>  {
      if (storeData != undefined) {
        const shopifyToken= await API.getShopifyShopToken(storeData.websiteUrl);
      let storeFrontToken="";
      if(shopifyToken!=undefined&&shopifyToken!=null&&shopifyToken!="")
      {
        storeFrontToken=shopifyToken.storefrontToken
      }
     console.log("store data>>>>>>>>>>>>",storeData);
        var catUrl="https://"+storeData.websiteUrl+"/cart/"+varientId+":1?access_token="+storeFrontToken+"&checkout[email]="+userInfo?.email+"&attributes[utm_source]=shopiads"
        //window.location.replace(catUrl);
        window.open(catUrl, '_blank');
      }
    })
    .catch((error) => {
      console.log(error);
    });
      }
      else
      {
   // window.location.replace(vendorUrl+"?utm_source=shopiads");
   window.open(vendorUrl+"?utm_source=shopiads", '_blank');
      }
      //window.location.replace(vendorUrl+"?utm_source=shopiads");
      //window.open(vendorUrl+"?utm_source=shopiads", '_blank');
  }


  const buyMoreProduct = () => {
    //setActive(true);
    // setChecked(false);
  
      if(allItemsSnipsBuy!=null&&allItemsSnipsBuy!=undefined&&allItemsSnipsBuy.length>0)
      {
        let offerSnip = allItemsSnipsBuy.filter(function( obj ) {
          return obj.type == "Offer";
        });
      if(offerSnip!=undefined&&offerSnip!=null&&offerSnip.length>0)
      {
        toast.error("Offers are for Ads purposes, to buy select products")
      }
      else
      {
      var storeId=allItemsSnipsBuy[0].product.storeId;
      console.log("allItemsSnipsBuy[0].quantity????????????",allItemsSnipsBuy[0].quantity);
      var qty=allItemsSnipsBuy[0].quantity!=undefined?allItemsSnipsBuy[0].quantity:1;;
      var varientIds=allItemsSnipsBuy[0].product.shopifyVarientId+":"+qty
      var isPass=true;
      for (var i = 1, l = allItemsSnipsBuy.length; i < l; i++) {
        var obj = allItemsSnipsBuy[i];
        console.log("isShopifyProduct>>>>>>>>>>>>>>>>>>>>>>>",obj.isShopifyProduct)
        if(obj.product.isShopifyProduct=="Y")
      {
        if(storeId==obj.storeId)
        {
          
         var qty=obj.quantity!=undefined?obj.quantity:1;
         console.log("obj.quantity????????????",obj.quantity);
          varientIds=varientIds+","+obj.product.shopifyVarientId+":"+qty;
        }
        else
        {
          isPass=false;
          toast.error("You can buy multiple products from the same store at a time")
          break; 
        }
        
      }
      else
      {
        isPass=false;
        toast.error("You can buy multiple products from the same store at a time")
        break;
      }
       
    }

    if(isPass)
    {
    API.getStoreById(storeId) .then(async (storeData) => {
      if (storeData != undefined) {
        const shopifyToken= await API.getShopifyShopToken(storeData.websiteUrl);
        let storeFrontToken="";
        if(shopifyToken!=undefined&&shopifyToken!=null&&shopifyToken!="")
        {
          storeFrontToken=shopifyToken.storefrontToken
        }
     console.log("store data>>>>>>>>>>>>",storeData);
        var catUrl="https://"+storeData.websiteUrl+"/cart/"+varientIds+"?access_token="+storeFrontToken+"&checkout[email]="+userInfo?.email+"&attributes[utm_source]=shopiads"
        //window.location.replace(catUrl);
        // TODO fix this cart, it's not nevigating to checkoutr page
        // const queryParams = API.generateQueryString({
        //   access_token: storeFrontToken ? storeFrontToken : null,
        //   "checkout[email]": userInfo?.email,
        //   "attributes[utm_source]": "shopiads",
        // });
        // var catUrl = `https://${storeData.websiteUrl}/cart/${varientIds}?${queryParams}`;
        
        window.open(catUrl, '_blank');
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }
      }
  }
  else
  {
    toast.error("Please select product to buy")
  }

      //console.log("checkedItems>>>>>>>>>>>>>>>",allItemsSnips);
      // dispatch(removeSnipsData(allItemsChecked, allSnips));
  
  

   /* var checkboxes = document.querySelectorAll(
      "input[name=catCheckBox]:checked"
    );
    if (checkboxes && checkboxes.length > 0) {
      for (var i = 0; i < checkboxes.length; i++) {
        // And stick the checked ones onto an array...
        if (checkboxes[i].checked) {
          checkboxes[i].checked = false;
        }
      }
    }*/
  };

  const handleShopNow = (offerId, vendorUrl) => {
    API.offerBuyNow(userInfo?.email, offerId);
    window.location.replace(vendorUrl);
  }
  console.log(snipByStore, "snipByStoresnipByStore");

  // var checkboxes = document.querySelectorAll('input[name=catCheckBox]:checked');
  //   if(checkboxes && checkboxes.length > 0) {
  //     for (var i=0; i<checkboxes.length; i++) {
  //     // And stick the checked ones onto an array...
  //     if (checkboxes[i].checked) {
  //      checkboxes[i].checked = false;
  //     }
  //     console.log(checkboxes);
  //    }
  //   }

  return (
    <>
      <div className="row">
        <div className="col-md-10 mx-auto my-list-header">
          <h5>
            <strong>My List &nbsp;&nbsp;&nbsp;&nbsp;</strong>(Multiple items can
            be bought from same Store at a time)
            {allItemsSnipsBuy != undefined && allItemsSnipsBuy.length > 0 ? (
              <Button
                variant="contained"
                color="primary"
                startIcon={<EntypoShoppingBag />}
                style={{ marginLeft: "180px", width: "140px" }}
                onClick={() => buyMoreProduct()}
              >
                Buy Now
              </Button>
            ) : (
              <Button
                variant="contained"
                // color="primary"
                disabled
                startIcon={<EntypoShoppingBag />}
                style={{ marginLeft: "180px", width: "140px" }}
                onClick={() => buyMoreProduct()}
              >
                Buy Now
              </Button>
            )}
          </h5>
        </div>

        {snipByStore["snipByStoreGroup"] != undefined &&
        snipByStore["snipByStoreGroup"] != null &&
        Object.keys(snipByStore["snipByStoreGroup"]).length > 0 ? (
          Object.keys(snipByStore["snipByStoreGroup"]).map((itemKey, index) => (
            <div className="col-md-10 mx-auto">
              <div className="store-header bg-white d-flex align-items-center">
                <h4 className="h5 mb-0">
                  <strong>{itemKey}</strong>
                </h4>
              </div>
              {snipByStore["snipByStoreGroup"][itemKey] != undefined &&
              snipByStore["snipByStoreGroup"][itemKey].length > 0
                ? snipByStore["snipByStoreGroup"][itemKey].map((item, index) =>
                    item.type == "Offer" ? (
                      <div className="snipsCard" key={index}>
                      <Checkbox
                          style={{ color: COLORS.primary.main }}
                          id={index}
                          name="catCheckBox"
                          onChange={(e) => {
                            handleOfferLevel(e, item, index);
                          }}
                        />
                        <Link
                          to={`/offers/Fashion/${item?.offer.id}`}
                          className="d-flex"
                        >
                          <img
                            src={
                              item?.offer?.fullImage != undefined &&
                              item?.offer?.fullImage != null
                                ? item?.offer?.fullImage
                                : "https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg"
                            }
                            height="150"
                            width="150"
                          />
                        </Link>
                        <div className="snipsCardDetails">
                          <strong>{item?.offer.heading}</strong>
                          <div className="bg-price1">
                            <div className="price1"></div>
                          </div>
                          {item?.isExpire === "Y" ? (
                            <div className="expired">
                              <strong>Expired</strong>
                            </div>
                          ) : null}
                          <div className="d-flex justify-content-end flex-fill">
                            {/* <a
                                    href={item?.offer?.vendorUrl}
                                    target="_new"
                                  > */}
                            {item?.offer?.isShopifyCollection != "Y" ? (
                              <Button
                                variant="contained"
                                color="primary"
                                startIcon={<ListAlt />}
                                style={{ marginRight: "50px", width: "140px" }}
                                onClick={() =>
                                  handleShopNow(
                                    item?.offer.id,
                                    item?.offer?.vendorUrl
                                  )
                                }
                              >
                                Shop Now
                              </Button>
                            ) : (
                              ""
                            )}
                            {/* </a> */}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="snipsCard" key={index}>
                        <Checkbox
                          style={{ color: COLORS.primary.main }}
                          id={index}
                          name="catCheckBox"
                          onChange={(e) => {
                            handleOfferLevel(e, item, index);
                          }}
                        />
                        <Link
                          to={`/productDetail/${item?.product?.storeId}/${item?.product?.id}`}
                          className="d-flex"
                        >
                          <img
                            src={
                              item?.product != null &&
                              item?.product.images != null &&
                              item?.product.images.length > 0
                                ? item?.product.images[0]
                                : "https://shopiadsimages.blob.core.windows.net/storeimages/imagesProd/No-image-found.jpg"
                            }
                            height="150"
                            width="150"
                          />
                        </Link>
                        <div className="snipsCardDetails">
                          <strong>
                            {item?.product != null ? item?.product.heading : ""}
                          </strong>

                          <div className="bg-price1">
                            <div className="price1">
                              $
                              {item?.product != null
                                ? item?.product.offerPrice
                                : ""}
                            </div>
                          </div>
                          <div className="row">
                            <div className="mt-1 ">Quantity :</div>
                            <div className="row pl-2">
                              <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                startIcon={
                                  <i
                                    className="fa fa-minus"
                                    aria-hidden="true"
                                    style={{ marginLeft: 5 }}
                                  ></i>
                                }
                                style={{ height: "30px", width: "30px" }}
                                className="ml-3"
                                onClick={() => {
                                  item["quantity"] = item.quantity
                                    ? item.quantity - 1
                                    : 1;
                                  setSelectedQuantity(item.quantity);
                                }}
                              ></Button>
                              <h5 className="ml-2 mt-1">
                                {" "}
                                {item.quantity ? item.quantity : 1}{" "}
                              </h5>
                              <Button
                                variant="outlined"
                                startIcon={
                                  <i
                                    className="fa fa-plus"
                                    aria-hidden="true"
                                    style={{ marginLeft: 5 }}
                                  ></i>
                                }
                                color="primary"
                                size="small"
                                style={{ height: "30px" }}
                                className="ml-2 p-0"
                                onClick={() => {
                                  item["quantity"] = item.quantity
                                    ? item.quantity + 1
                                    : 2;
                                  setSelectedQuantity(item.quantity);
                                }}
                              ></Button>
                            </div>
                          </div>
                          {item?.isExpire === "Y" ? (
                            <div className="expired">
                              <strong>Expired</strong>
                            </div>
                          ) : null}
                          <div className="d-flex justify-content-end flex-fill">
                            {/* <a
                                    href={item?.product?.vendorUrl}
                                    target="_new"
                                  > */}
                            {item?.product?.isShopifyProduct != "Y" ? (
                              <Button
                                variant="contained"
                                color="primary"
                                startIcon={<ListAlt />}
                                style={{ marginRight: "50px", width: "140px" }}
                                onClick={() =>
                                  handleBuy(
                                    item?.product?.id,
                                    item?.product?.vendorUrl,
                                    item?.product?.isShopifyProduct,
                                    item?.product?.shopifyVarientId,
                                    item?.product?.storeId
                                  )
                                }
                              >
                                Buy Now
                              </Button>
                            ) : (
                              ""
                            )}
                            {/* </a> */}
                          </div>
                        </div>
                      </div>
                    )
                  )
                : ""}
              {/* <div className="text-right bg-white py-3 pr-3">
          <Button
                    variant="contained"
                    color="default"
                    startIcon={<DeleteIcon />}
                    style={{ width: "250px" }}
                    onClick={() => removeSnips(itemKey)}
                  >
                    Delete
                  </Button>
             </div>*/}
            </div>
          ))
        ) : (
          <p style={{ textAlign: "center", width: "100%", padding: "10px" }}>
            Shop 'til you drop! Your cart is waiting to be filled.
          </p>
        )}

        {snipByStore["snipByStoreGroup"] != undefined &&
        snipByStore["snipByStoreGroup"] != null &&
        Object.keys(snipByStore["snipByStoreGroup"]).length > 0 ? (
          <div className={x.join(" ")}>
            <div className="all-checkboxes bg-white d-flex align-items-center">
              <Checkbox
                style={{ color: COLORS.primary.main }}
                checked={allItemsChecked}
                onChange={(e) => {
                  handleAllItems(e);
                }}
              />{" "}
              <div className="h5 mb-0">
                <strong>All Item</strong>
              </div>
              <Checkbox
                style={{ color: COLORS.primary.main }}
                checked={checkedItems}
                onChange={(e) => {
                  handleCheckedItems(e);
                }}
              />{" "}
              <div className="h5 mb-0">
                <strong>Checked Item</strong>
              </div>
              <Checkbox
                style={{ color: COLORS.primary.main }}
                checked={expiredItems}
                onChange={(e) => {
                  handleExpiredItems(e);
                }}
              />{" "}
              <div className="h5 mb-0">
                <strong>Expired Item</strong>
              </div>
              {active ? (
                <Button
                  variant="contained"
                  disabled
                  startIcon={<DeleteIcon />}
                  style={{ width: "250px" }}
                  className="ml-4"
                  onClick={() => removeSnips()}
                >
                  Delete
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DeleteIcon />}
                  style={{ width: "250px" }}
                  className="ml-4"
                  onClick={() => removeSnips()}
                >
                  Delete
                </Button>
              )}
            </div>
          </div>
        ) : (
          ""
        )}
        <ToastContainer />
      </div>
    </>
  );
}

export default Snips;
